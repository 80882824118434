import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../loading/LoadingComponent";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForPressureChart, tickFormatter} from "../../utils/ChartsUtils";

export class PressureChart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const currentDay = data.find(item => item.AggTi === +date);
        const {pressure, lastTime} = currentDay ? calculateDataForPressureChart(currentDay) : {
            pressure: [],
            lastTime: 0
        };
        return {
            data: pressure,
            lastTime
        }
    }

    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const def = [
            {
                color: "blue",
                dataKey: "pressure",
                name: t("chamber.chart.pressure"),
                unit: "bar",
                opacity: 0.4
            }
        ];
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("chamber.chart.pressure")
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [date.startOf("day").toDate().getTime(), "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }} saveAsExcell={"pressure"} tooltipLabelFormatter={tickFormatter} showDomainInput/>
            </React.Fragment>
        )
    }
}

PressureChart.defaultProps = {
    date: moment().subtract(1, "days")
};

PressureChart.propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired
};

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

PressureChart = connect(
    makeMapStateToProps
)(PressureChart);


export default withTranslation()(PressureChart);
