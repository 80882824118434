import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getClimateData} from "../../../selectors/device-rows/climateSelector";
import NewIOT from "../../../IOT/NewIOT";
import {groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";
import sinon from "sinon";
import {
    fakeCreateAndSendMessageObject,
    fakeStartSendingDeviceState,
    refresh
} from "../../../demo/climate/fakeFunctions";
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import ClimateRow from "./ClimateRow";
import ClimateSK3Row from "../climateSK3/ClimateRow";
import VirtualizedList from "../../basics/virtualized-list/VirtualizedList";
import ClimateNRFRow from "../climate-nrf-sensor/ClimateNRFRow";
import NoDataFoundLoading from "../../no-data-found-farm-view/NoDataFoundLoading";
import {withTranslation} from "react-i18next";
import {Col} from "react-bootstrap";

function mapStateToProps(state) {
    return {
        data: getClimateData(state),
        loading: state.dataLoader.loading
    };
}

class ClimateList extends Component {

    messageIDs = [];

    constructor(props) {
        super(props);
        if (isUsingFakeData()) {
            const {data} = this.props;
            sinon.restore();
            sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
            sinon.stub(NewIOT, "sendRequestForAggregatedData").callsFake(() => {
            });
            this.timer = setInterval(() => {
                refresh(data.map(o => o.device))
            }, 7 * 1000)
        }
    }

    componentDidMount() {
        this.startPolling();
    }

    startPolling() {
        NewIOT.removeFromInterval(this.messageIDs);
        const {data} = this.props;
        const deviceGroups = groupDevicesByGatewayID(data.map(o => o.device));
        if (deviceGroups) {
            for (let groups of deviceGroups.values()) {
                if (groups[DevType.DISPENSER_NRF].length) {
                    this.messageIDs.push(NewIOT.startSendingDeviceState(groups[DevType.DISPENSER_NRF], undefined, undefined, {keepAlive: true}));
                }
                if (groups[DevType.CLIMATE].length) {
                    this.messageIDs.push(NewIOT.startSendingDeviceState(groups[DevType.CLIMATE], undefined, undefined, {keepAlive: true}));
                }
                if (groups[DevType.CLIMATE_SK3].length) {
                    this.messageIDs.push(NewIOT.startSendingDeviceState(groups[DevType.CLIMATE_SK3], undefined, undefined, {keepAlive: true}));
                }
            }
        }
    }

    componentWillUnmount() {
        NewIOT.removeFromInterval(this.messageIDs)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {data} = this.props;
        const {data: prevData} = prevProps;
        if (data.length !== prevData.length) {
            this.startPolling();
        }
    }

    noRowsRenderer = () => {
        const {loading, data, t} = this.props;
        return (
            <Col xs={12}>
                <NoDataFoundLoading isLoading={loading} dataLength={data.length}
                                    noDataText={t("farmView.climate.climatesNotFound")}/>
            </Col>

        )
    }


    render() {
        const {data, openMenu, loading} = this.props;
        return (
            <VirtualizedList
                forceRerender={loading}
                noRowsRenderer={this.noRowsRenderer}
                passProps={{openMenu}}
                component={({device} = {}) => {
                    switch (device.DevType) {
                        case DevType.CLIMATE_SK3:
                            return ClimateSK3Row;
                        case DevType.DISPENSER_NRF:
                            return ClimateNRFRow;
                        default:
                            return ClimateRow;
                    }
                }}
                data={data}
            />
        );
    }
}

ClimateList = connect(
    mapStateToProps,
)(ClimateList);

export default withTranslation()(ClimateList)
