import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import moment from "moment";
import Loading from "../loading/LoadingComponent"
import Chart from "../basics/chart/Chart";
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {getScaleUnit} from "../../utils/SettingsUtils";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForScaleChart} from "../../utils/ChartsUtils";

export class ScaleChart extends Component {


    getData = () => {
        const {aggregated: {data}, date} = this.props;
        return {data: calculateDataForScaleChart(data, +date)};
    }

    render() {
        const {data} = this.getData();
        const {t, aggregated: {fetching}, date, index} = this.props;
        let unit = getScaleUnit();
        const valueConverter = (value) => convertWeightUnitTo(value, {
            rawValue: true,
            acceptNil: true,
            unit: unit,
            fixed: unit ? 2 : 0
        })
        const def = [
            {
                color: "green",
                dataKey: "weight",
                name: t("weight"),
                valueConverter: valueConverter,
                unit: getUnit("weight", unit),
                opacity: 0.2
            }
        ];

        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("weight"),
                    domain: [0, "dataMax + 1"]
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name"
                }} saveAsExcell={`Silo ${index} (${date.format("DD-MM-YYYY")})`} showDomainInput/>
            </React.Fragment>
        )
    }
}

ScaleChart.defaultProps = {
    date: moment.utc().startOf("day").subtract(1, "days"),
    height: "100%",
};

ScaleChart.propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

function makeMapStateToProps(ownState, ownProps) {
    const getAggregated = makeGetAggregatedData();
    const params = {DevID: ownProps.device.DevID, index: ownProps.index};
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, params)
        }
    }
}

ScaleChart = connect(makeMapStateToProps)(ScaleChart);

export default withTranslation()(ScaleChart);
