import React, {Component} from 'react';
import moment from "moment";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

class CageMiddleFullWidth extends Component {

    getWorkTypeName(type) {
        const {t} = this.props;
        switch (type) {
            case 1:
                return t("modals.cageWorkMode.sepMode").toLowerCase();
            case 2:
                return (t("left") + " " + t("modals.cageWorkMode.exitOpen")).toLowerCase();
            case 3:
                return (t("right") + " " + t("modals.cageWorkMode.exitOpen")).toLowerCase();
            case 4:
                return t("modals.cageWorkMode.altOpen").toLowerCase();
            default:
                return "";
        }
    }

    render() {
        const {object, t} = this.props;
        if (object.T) {
            const {T, AnimalAmount, MinWeight, MaxWeight, ExpeditionWorktype} = object;
            const weightParams = {unit: UnitTypes.MEDIUM, fixed: 0, showUnit: true};
            return (
                <h6 className="full-width-row-middle" style={{whiteSpace: "normal"}}>
                    {i18next.t("expeditionFullWidthRow.text", {
                        type1: moment(T).format("HH:mm"),
                        type2: `${convertWeightUnitTo(MinWeight, weightParams)} - ${convertWeightUnitTo(MaxWeight, weightParams)}`
                    })}, {AnimalAmount > 0 ? i18next.t("expeditionFullWidthRow.animals", {count: AnimalAmount}) : t("expeditionFullWidthRow.noLimitAnimals")}, {this.getWorkTypeName(ExpeditionWorktype)}
                </h6>
            )
        }
        const {date} = object;
        return (
            <h6 className="full-width-row-middle">
                {moment.utc(date).format("DD.MM.YYYY")}
            </h6>
        );
    }
}

export default withTranslation()(CageMiddleFullWidth);