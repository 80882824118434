import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import NotFound from "../NotFound";
import Loading from "../loading/LoadingComponent";
import Chart from "../basics/chart/Chart";
import {convertWeightUnitTo} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {getPassageAccuracy} from "../../utils/SettingsUtils";
import Worker from "../../workers/charts/passageChart.worker";
import {withTranslation} from "react-i18next";

@connect((store) => {
    return {
        cageData: store.aggregatedData.data
    };
})
export class PassageChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            averageWeight: 0,
            averageWeightShadow: 0,
            loading: true
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props.container, nextProps.container)) return true;
        if (!_.isEqual(this.props.t("chamber"), nextProps.t("chamber"))) return true;
        return !_.isEqual(this.state, nextState);
    }

    valueFormatter(number) {
        return number + this.props.t("pcs")
    }

    async componentDidMount() {
        let worker = new Worker();
        let cageData = this.props.cageData.get(this.props.cage.DevID);
        worker.postMessage({
            cageData,
            date: this.props.date.toDate().getTime(),
            applyShadow: this.props.applyShadow,
            shadowDate: this.props.applyShadow ? this.props.shadowDate.toDate().getTime() : null,
            accuracy: getPassageAccuracy()
        });
        worker.onmessage = event => {
            console.log(event.data.passage);
            this.setState({
                data: event.data.passage,
                averageWeight: event.data.averageWeight,
                averageWeightShadow: event.data.averageWeightShadow,
                loading: false,
            })
        };
        this.setState({
            worker
        })
    }

    componentWillUnmount() {
        if (this.state.worker) {
            this.state.worker.terminate();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.cageData, nextProps.cageData) || !_.isEqual(this.props.date, nextProps.date) || !_.isEqual(this.props.shadowDate, nextProps.shadowDate)) {
            let cageData = nextProps.cageData.get(nextProps.cage.DevID);
            this.state.worker.postMessage({
                cageData,
                date: nextProps.date.toDate().getTime(),
                applyShadow: nextProps.applyShadow,
                shadowDate: nextProps.applyShadow ? nextProps.shadowDate.toDate().getTime() : null,
                accuracy: getPassageAccuracy()
            })
        }
    }

    weightValueFormatter = value => {
        return `${this.props.t("weight")}: ${value}`;
    };

    render() {
        // console.log(this.props);
        const {applyShadow} = this.props;
        const {loading} = this.state;
        let chartDef = [
            {
                color: "green",
                dataKey: "amount",
                name: this.props.t("amount"),
                chartType: "Bar",
                opacity: 1
            }
        ];
        let referenceLines = [
            {
                x: Math.round(this.state.averageWeight / 1000),
                color: "blue",
                name: this.props.t('chamber.chart.averageWeight') + convertWeightUnitTo(this.state.averageWeight, {
                    unit: UnitTypes.MEDIUM,
                    showUnit: true,
                    fixed: 1
                })
            }
        ];
        if (applyShadow) {
            chartDef = [
                ...chartDef,
                {
                    color: "blue",
                    dataKey: "amountShadow",
                    name: this.props.t("amount"),
                    chartType: "Area",
                    type: "step",
                }
            ];
            referenceLines = [
                ...referenceLines,
                {
                    x: Math.round(this.state.averageWeightShadow / 1000),
                    color: "orange",
                    name: this.props.t("chamber.chart.averageWeight") + convertWeightUnitTo(this.state.averageWeightShadow, {
                        unit: UnitTypes.MEDIUM,
                        showUnit: true,
                        fixed: 1
                    }),
                    position: "center"
                }
            ]
        }
        return (
            <React.Fragment>
                <Loading isLoading={loading}/>
                {
                    !this.state.data.length && <NotFound/>
                }
                {
                    this.state.data.length > 0 &&
                    <>
                        <Chart
                            dataDef={chartDef}
                            data={this.state.data}
                            Yaxis={{
                                name: this.props.t('amount')
                            }}
                            Xaxis={{
                                name: this.props.t('weight'),
                                dataKey: "name",
                                offset: 45
                            }}
                            type={applyShadow ? "Composed" : "Bar"}
                            brush={{brushKey: "amount", blockBrush: true}}
                            referenceLines={referenceLines}
                            saveAsExcell={"passesInWeights"}
                            showDomainInput
                            tooltipLabelFormatter={this.weightValueFormatter}
                        />
                    </>
                }
            </React.Fragment>
        )
    }
}

PassageChart.propTypes = {
    cage: PropTypes.object,
    date: PropTypes.instanceOf(moment),
    applyShadow: PropTypes.bool,
    shadowDate: PropTypes.instanceOf(moment)
};

PassageChart.defaultProps = {
    brushHeight: 32,
    height: "100%",
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

export default withTranslation()(PassageChart);