import React from "react";
import PropTypes from "prop-types";
import {DispenserNRFEventTypes} from "../../../../constans/mqttMessages";
import {UnitTypes} from "../../../../constans/unitTypes";
import {getAllDispenserErrors} from "../../../../utils/DevicesUtils";
import IconStatus from "../mini-components/IconStatus";
import {
    CurveDay,
    ForageAmount,
    ForageModificators,
    History,
    IsFeeding,
    SkipDoses,
    StandingNumber
} from "../mini-components/MiniComponents";
import "../_dispenser-item.scss";
import {LicPackageLevel} from "../../../../constans/roles";
import {withTranslation} from "react-i18next";


class Dispenser extends React.Component {

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     return JSON.stringify(this.props) !== JSON.stringify(nextProps);
    // }

    render() {
        const {
            feedingType,
            schedule,
            unit,
            feed,
            curve,
            skipDoses,
            lastWeekHistory,
            running,
            alert,
            errorsParser,
            locked,
            feeding,
            connected,
            loop,
            errors,
            name,
            forage,
            loading,
            animal,
            receiver,
            workType,
            additional,
            dateTime,
            lastSeen,
            curveMap,
            scheduleMap,
            forageMap,
            debugFixedText,
            correctLocationId,
            license,
            mobile,
            water,
            efficiency,
            punishmentOptions,
            temperature,
            t
        } = this.props;
        const itemClassName = "dispenser-item";
        return (
            <>
                <div className={itemClassName}>
                    <IconStatus extended={license === LicPackageLevel.EXTENDED} animal={animal} day={curve.day}
                                curveMap={curveMap} curveId={curve.id}
                                skipDoses={skipDoses} scheduleMap={scheduleMap} scheduleId={schedule.id}
                                dateTime={dateTime} loading={loading} receiver={receiver} workType={workType}
                                feedingType={feedingType} errorsParser={errorsParser} locked={locked} alert={alert}
                                feeding={feeding} connected={connected}
                                errors={errors}/>
                    <StandingNumber extended={license === LicPackageLevel.EXTENDED} t={t}
                                    isDead={!!(animal && animal.DtaDthTime)}
                                    isCorrectPlacement={!correctLocationId || !animal || (animal.PlcmntID === correctLocationId)}
                                    name={name} rfid={animal && animal.RFID} animalName={animal && animal.AnmNo1}/>
                    <ForageAmount water={water} mobile={mobile} forageMap={forageMap} unit={unit} additional={feed.additional}
                                  additionalDetails={additional} planned={feed.plannedUsage}
                                  used={feed.usage}>
                        <ForageModificators feedingType={feedingType} water={water} efficiency={efficiency} punishmentOptions={punishmentOptions} additional={feed.additional}
                                            mobile={mobile} forageMap={forageMap} forageId={forage.id}
                                            forageNr={forage.number}
                                            temperature={temperature}
                                            curveNr={curve.number} punishment={curve.punishment}
                                            correction={curve.correction}/>
                    </ForageAmount>
                    <CurveDay mobile={mobile} curveMap={curveMap} feedingType={feedingType}
                              eventStage={curve.eventStage} curveId={curve.id} curveNr={curve.number}
                              day={curve.day} loop={loop}/>
                    <IsFeeding isFeeding={running} seenTime={lastSeen.time}/>
                    <SkipDoses mobile={mobile} scheduleMap={scheduleMap} skipDoses={skipDoses}
                               scheduleId={schedule.id}/>
                    <History lastWeekHistory={lastWeekHistory}/>
                </div>
                {
                    !!debugFixedText &&
                    <div className={"fixed-debug-text"}>{debugFixedText}</div>
                }
            </>
        );
    }

}

Dispenser.propTypes = {
    minLevel: PropTypes.number,
    onRowClick: PropTypes.func,
    onRowContextMenuClick: PropTypes.func,
    rowIndex: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    style: PropTypes.object,
    selected: PropTypes.object,
    blurText: PropTypes.string,
    animal: PropTypes.object,
    id: PropTypes.string.isRequired,
    feedingType: PropTypes.number.isRequired,
    curve: PropTypes.shape({
        number: PropTypes.number, //numer krzywej (0 - brak krzywej)
        day: PropTypes.number, // dzien, w ktorym dziala dozownik z przesunięciami względem typu krzywej
        punishment: PropTypes.number, // kara
        correction: PropTypes.number, // odchył dawki
        eventStage: PropTypes.oneOf(Object.keys(DispenserNRFEventTypes)), // etap dozownika
        id: PropTypes.string,// id krzywej
    }),
    schedule: PropTypes.shape({
        id: PropTypes.string, // id harmonogramu
        skip: PropTypes.arrayOf(PropTypes.number), // dawki do pominiecia (1 - dawka pominieta)
        number: PropTypes.number, // numer harmonogramu,
        obj: PropTypes.object
    }),
    loop: PropTypes.shape({
        insemination: PropTypes.number,
        parturition: PropTypes.number,
    }),
    alert: PropTypes.bool,
    feeding: PropTypes.bool,
    locked: PropTypes.bool,
    connected: PropTypes.bool,
    feed: PropTypes.shape({
        usage: PropTypes.number,
        plannedUsage: PropTypes.number,
        additional: PropTypes.number,
    }),
    lastSeen: PropTypes.shape({
        time: PropTypes.number
    }),
    lastWeekHistory: PropTypes.arrayOf(PropTypes.shape({
        usage: PropTypes.number,
        plannedUsage: PropTypes.number
    })),
    errors: PropTypes.arrayOf(PropTypes.number),
    running: PropTypes.bool,
    errorsParser: PropTypes.func,
    mobile: PropTypes.bool,
    buttons: PropTypes.oneOfType([PropTypes.node, null]),
    forage: PropTypes.shape({
        id: PropTypes.string
    })
};

Dispenser.defaultProps = {
    onRowClick: () => {
    },
    // onRowContextMenuClick: () => {
    // },
    isLoading: false,
    style: {},
    selected: false,
    unit: UnitTypes.MEDIUM,
    connected: false,
    errorsParser: getAllDispenserErrors
};

export default withTranslation()(Dispenser);
