import React, {Component} from "react";
import _ from "lodash";
import Chart from "../basics/chart/Chart";
import {getTechnologyGroupSize, getTechnologyGroupWeeks} from "../../utils/SettingsUtils";
import {withTranslation} from "react-i18next";

export class HerdStructureChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.prepareData(this.props.data)
        }
    }

    /**
     * funkcja modyfikujaca wyliczony obiekt data dodajac idealna ilosc firm na fermie
     * wzór:
     * calkowita ilosc swin na chelwni = M x 21 + M x 5 x 20% + M x 5 x 30% dla 1 tygodniowego
     * calkowita ilosc swin na chelwni = M x 7 + M x 2 x 20% + M x 2 x 30% dla 3 tygodniowego
     * @param data
     * @returns {Array}
     */
    prepareData(data) {
        const idealPercentages = [17, 16, 15, 14, 12, 10, 8, 7];
        const technoGroupSize = getTechnologyGroupSize();
        const technoGroupWeeks = getTechnologyGroupWeeks();
        const totalSowsCount = Math.floor(technoGroupSize * (technoGroupWeeks === 1 ?
            23.5 : 8));
        let redata = [];
        for (let i = 0; i < 8; i++) {
            redata[i] = {
                lactation: `${i}${i === 7 ? '+' : ''}`,
                sowsCnt: data[i].sowsCnt,
                sowsIdeal: Math.round(totalSowsCount * idealPercentages[i] / 100)
            }
        }
        return redata;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.data, nextProps.data)) {
            this.setState({
                data: this.prepareData(nextProps.data)
            })
        }
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        return (
            <Chart
                height={"30rem"}
                data={data}
                Xaxis={{
                    dataKey: "lactation",
                    name: t("lactation")
                }}
                Yaxis={{
                    name: t("amount")
                }}
                dataDef={[{
                    name: t("amount"),
                    dataKey: "sowsCnt",
                    color: "blue"
                }, {
                    name: t("charts.herdStructureChart.perfectAmount"),
                    dataKey: "sowsIdeal",
                    color: "green"
                }]}
            />
        )

    }
}

export default withTranslation()(HerdStructureChart);