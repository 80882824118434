import React, {Component} from 'react';
import {ChamberSettlement, getSettlement} from "../../../../selectors/settlementSelector";
import Button from "../../../basics/button/Button";
import {connect} from "react-redux";
import ChamberSettlementModal, {ModalName as ChamberSettlementModalName} from "../../../modals-new/chamber-settlement-modal/ChamberSettlementModal";
import ChamberSeparationModal, {ModalName as ChamberSeparationModalName} from "../../../modals-new/chamber-separation-modal/ChamberSeparationModal";
import {bindActionCreators} from "redux";
import {show as showModal} from "redux-modal";
import ChamberTransferModal, {ModalName as ChamberTransferModalName} from "../../../modals-new/chamber-transfer-modal/ChamberTransferModal";
import {SectorType} from "../../../../constans/sectorTypes";
import {withTranslation} from "react-i18next";

class SettlementButton extends Component {

    handleOpenSettlement = () => {
        this.openModal(ChamberSettlementModalName);
    };

    handleOpenSeparation = () => {
        this.openModal(ChamberSeparationModalName);
    };

    handleOpenTransfer = () => {
        this.openModal(ChamberTransferModalName);
    };

    openModal = (name) => {
        const {show, chamber} = this.props;
        show(name, {chamberId: chamber.CID})
    };

    render() {
        const {settlement: {state}, chamber, t, sectorType} = this.props;
        return (
            <>
                <>
                    {
                        (state === ChamberSettlement.SHOW_SETTLEMENT || state === ChamberSettlement.NO_SHOW) &&
                        <Button onClick={this.handleOpenSettlement} disabled={state === ChamberSettlement.NO_SHOW}
                                type={"button"}
                                className={"w-100"}>{t("settlement")}</Button>
                    }
                    {
                        (state === ChamberSettlement.SHOW_SEPARATION && [SectorType.DELIVERY].includes(sectorType)) &&
                        <Button onClick={this.handleOpenSeparation} type={"button"}
                                className={"w-100"}>{t("separation")}</Button>
                    }
                    {
                        (state === ChamberSettlement.SHOW_SEPARATION && ![SectorType.DELIVERY].includes(sectorType)) &&
                        <Button onClick={this.handleOpenTransfer} type={"button"}
                                className={"w-100"}>{t("transfer")}</Button>
                    }
                </>
                <ChamberSettlementModal chamberId={chamber.CID}/>
                <ChamberSeparationModal chamberId={chamber.CID}/>
                <ChamberTransferModal chamberId={chamber.CID}/>
            </>
        );
    }
}

SettlementButton = connect((state, props) => ({
    settlement: getSettlement(state, {CID: props.chamber.CID})
}))(SettlementButton);
SettlementButton = connect(
    null,
    dispatch => bindActionCreators({show: showModal}, dispatch)
)(SettlementButton);

export default withTranslation()(SettlementButton);
