import React, {Component} from 'react';
import {Row} from "react-bootstrap";
import ChamberActions from "./ChamberActions";
import RFIDActions from "./RFIDActions";
import List from "../../../basics/list/List";
import SimpleCellMeasurer from "../../../basics/simple-cell-measurer/SimpleCellMeasurer";
import {List as VirtualizedList} from "react-virtualized/dist/commonjs/List";
import DispenserAdapter from "./DispenserAdapter";
import {BreakPoint} from "../../../../constans/breakpointTypes";
import {WindowScroller} from "react-virtualized";
import {connect} from "react-redux";
import {getFeedingSorted, getFilter, getSort, isSelected} from "../../../../selectors/feedingSelector";
import {scrollToID} from "../../../../utils/DOMUtils";
import {withTranslation} from "react-i18next";

const BreakPoints = [{max: BreakPoint.LG, height: "20rem"}];


class AdvancedView extends Component {

    rowRenderer = ({index, style, key, isScrolling}) => {
        const {unit, chamber, mobile, setEvent, data, sectorType, scheduleMap, forageMap, curveMap, isLoading} = this.props;
        const selected = isSelected(data[index]);
        return (
            <DispenserAdapter
                key={key}
                data={data[index]}
                sectorType={sectorType}
                mobile={mobile}
                forageMap={forageMap}
                selected={selected}
                scheduleMap={scheduleMap}
                curveMap={curveMap}
                loading={isLoading}
                style={style}
                index={index}
                unit={unit}
                isScrolling={isScrolling}
                location={chamber}
                setEvent={setEvent}/>
        )
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {filterBy, sortBy, chamber} = this.props;
        if ((prevProps.filterBy !== filterBy) || (sortBy !== prevProps.sortBy)) {
            scrollToID(`chamber_${chamber.CID}`);
        }
    }

    noRowsRenderer = () => {
        const {t, total, chamber: {IndividualFeeding} = {}} = this.props;
        return (<div className={"placeholder"}>{
            total ? (IndividualFeeding ? t("deviceRows.feeding.dispenserChamber.standingsNotFoundFiltered") : t("deviceRows.feeding.dispenserChamber.animalsNotFoundFiltered")) : (IndividualFeeding ? t("deviceRows.feeding.dispenserChamber.noBoxes") : t("deviceRows.feeding.dispenserChamber.noPassages"))
        }</div>);
    };

    render() {
        const {sectorType, chamber, mobile, dispensers, data, filterBy, sortBy, startSendingDeviceState, isOpen, toggleOpen} = this.props;
        return (
            <WindowScroller>
                {({height, isScrolling, scrollTop, onChildScroll, registerChild}) =>
                    <Row className={"position-relative background-inherit"}>
                        <ChamberActions sectorType={sectorType} scrollTop={scrollTop}
                                        chamber={chamber} isOpen={isOpen} toggleOpen={toggleOpen}/>
                        <RFIDActions startSendingDeviceState={startSendingDeviceState}
                                     dispensers={dispensers} mobile={mobile} chamber={chamber}/>
                        <List itemRef={registerChild} showNumber={true} id={`list_${chamber.CID}`}
                              useFlexLayout={false}>
                            <SimpleCellMeasurer defaultValue={"10rem"} breakPoints={BreakPoints}>
                                {({rowHeight}) => (
                                    <VirtualizedList
                                        rowCount={data.length}
                                        sortBy={sortBy}
                                        filterBy={filterBy}
                                        data={data}
                                        rowHeight={rowHeight}
                                        width={window.innerWidth}
                                        rowRenderer={this.rowRenderer}
                                        scrollTop={scrollTop}
                                        autoHeight
                                        onScroll={onChildScroll}
                                        height={height}
                                        isScrolling={isScrolling}
                                        overscanRowCount={3}
                                        className={"w-100 overflow-auto"}
                                        noRowsRenderer={this.noRowsRenderer}
                                    />
                                )}
                            </SimpleCellMeasurer>
                        </List>
                    </Row>
                }
            </WindowScroller>

        );
    }
}

AdvancedView = connect((state, props) => {
    const {chamber: {CID}} = props;
    return {
        data: getFeedingSorted(state, {CID}),
        sortBy: getSort(state, {CID}),
        total: !!state.feeding.feeding[CID] ? Object.keys(state.feeding.feeding[CID].data).length : 0,
        filterBy: getFilter(state, {CID})
    }
})(AdvancedView);
export default withTranslation()(AdvancedView);
