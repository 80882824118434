import React from "react";
import PropTypes from "prop-types"
import {getLatestMetadata} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import moment from "moment";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import MinAlarm from "../../../resources/images/climate/alarm_min_icon.svg";
import {get, isNil} from "lodash";
import SiloCharts from "./SiloCharts";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import {toggleExpandByID, toggleSelectByID} from "../../../actions/mainTabActions";
import {onElementPainted} from "../../../utils/DOMUtils";
import {makeIsExpanded, makeIsSelected} from "../../../selectors/device-rows/rowSelector";
import DeviceRow from "../DeviceRow";
import Tooltip from "../../Tooltip";
import {withTranslation} from "react-i18next";
import Card from "../../basics/card/Card";
import {Col} from "react-bootstrap";
import Forage from "../../../resources/images/silo/percentage_fill_icon.svg";
import AverageUse from "../../../resources/images/silo/average_forage_consumption.svg";
import ForageDays from "../../../resources/images/silo/forage_supply_icon.svg";
import Filled from "../../../resources/images/silo/fill_day_icon.svg";
import Wheat from "../../../resources/images/silo/wheat.svg";
import {getScaleUnit} from "../../../utils/SettingsUtils";
import {getFeedingForages} from "../../../selectors/feedingSelector";

// import Weight from "../../../resources/images/silo/weight.svg";


function makeMapStateToProps(ownState, ownProps) {
    const getShadow = makeGetShadowByDevice()
    const isSelected = makeIsSelected();
    const isExpanded = makeIsExpanded();
    const params = {type: ownProps.type};
    return function mapStateToProps(state, props) {
        params.id = props.id
        return {
            ...getShadow(state, props.device),
            selected: isSelected(state, params),
            expanded: isExpanded(state, params),
            forages: getFeedingForages(state)
        }
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onSelect: (e) => {
            e.stopPropagation();
            dispatch(toggleSelectByID(ownProps.type, ownProps.id))
            return false;
        },
        onExpand: () => dispatch(toggleExpandByID(ownProps.type, ownProps.id))
    }
}

export class SiloRow extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.expanded !== this.props.expanded) {
            if (!this.props.expanded) {
                onElementPainted(this.props.measure)
            }
        }
    }

    getValues = () => {
        const {shadow, name, t, device, fetching, index, forages} = this.props;
        const errors = this.getErrors();
        const unit = getScaleUnit();
        const weightUnit = getUnit("weight", unit);
        const weightFormatter = (value, rawValue = true) => convertWeightUnitTo(value, {
            unit: unit,
            showUnit: true,
            fixed: unit,
            acceptNil: true,
            rawValue: !!rawValue
        })
        const maxWeight = +get(device, `Siloses[${index}].Capacity`, 0);
        const forageID = get(device, `Siloses[${index}].Forage`);
        const forage = forages.find(item => item.SetID === forageID);
        return {
            upperTitle: name,
            title: weightFormatter(get(shadow, `weights[${index}].value`), false),
            lowerTitle: shadow ? `${t("capacity")}: ${weightFormatter(maxWeight, false)}` : "-",
            number: device.Siloses[index].Number || "-",
            icon: {
                errors,
                isLoading: fetching,
                isConnected: !!shadow,
                dateTime: getLatestMetadata(get(shadow, "metadata")),
                isDisabled: false,
                isAlerted: !!errors.length
            },
            params: [
                // --- duplikat ---
                // {
                //     value: get(shadow, `weights[${index}].value`),
                //     title: t("weight"),
                //     icon: Weight,
                //     unit: weightUnit,
                //     valueFormatter: weightFormatter,
                //     show: true
                // },
                {
                    value: maxWeight ? Math.round(100 * get(shadow, `weights[${index}].value`, 0) / maxWeight) : undefined,
                    title: t("forageAmount"),
                    icon: Forage,
                    unit: "%",
                    show: true
                },
                {
                    value: get(shadow, `addInfo[${index}].averageUsage`),
                    title: t("deviceRows.siloses.siloRow.averageUsage"),
                    icon: AverageUse,
                    unit: weightUnit,
                    valueFormatter: weightFormatter,
                    show: true
                },
                {
                    value: get(shadow, `addInfo[${index}].daysToEmptySilo`),
                    title: t("deviceRows.siloses.siloRow.supplyLast"),
                    icon: ForageDays,
                    valueFormatter: (value) => value > 0 ? t("Xdays", {count: value}) : "-",
                    show: true
                },
                {
                    value: get(shadow, `addInfo[${index}].fillTime`),
                    title: t("deviceRows.siloses.siloRow.latestFillUp"),
                    icon: Filled,
                    valueFormatter: (value) => isNil(value) ? undefined : moment.utc(value).local().format("DD.MM.YY"),
                    show: true,
                    small: true
                },
                {
                    value: get(shadow, `configuration.minWeights[${index}].value`),
                    title: t("deviceRows.siloses.siloRow.minimumAlarm"),
                    icon: MinAlarm,
                    unit: weightUnit,
                    valueFormatter: (value) => value > 0 ? weightFormatter(value) : undefined
                },
                {
                    value: get(forage, "SetData.Name"),
                    title: t("forage"),
                    icon: Wheat,
                    color: get(forage, "SetData.Color"),
                    unit: "",
                    small: true
                }
            ]
        }
    }

    getErrors = () => {
        const {t, shadow, index} = this.props;
        const unit = getScaleUnit();
        const weightFormatter = (value) => convertWeightUnitTo(value, {
            unit: unit,
            showUnit: true,
            fixed: unit,
            acceptNil: true,
            rawValue: false
        })
        try {
            if (shadow /*&& shadow.configuration.flashDisplay*/) {
                if (shadow.configuration.minWeights[index].value > shadow.weights[index].value) {
                    return [
                        t("deviceRows.siloses.siloRow.weightBelowMinimum", {
                            amount: weightFormatter(shadow.configuration.minWeights[index].value)
                        })
                    ]
                }
            }
        } catch (e) {
        }
        return [];
    }


    icon = ({color, header, icon, dateTime}) => {
        const errors = this.getErrors();
        return (
            <Tooltip tooltipContent={
                <div>
                    <div>{header}</div>
                    {
                        !!dateTime &&
                        <div>
                            <i className="fas fa-sync mr-1"/><b>{moment(dateTime).format("HH:mm:ss DD.MM.YY")}</b>
                        </div>
                    }
                    <div><small>{errors.join("\n")}</small></div>
                </div>
            }
                     placement={"left"}
                     type={color}>
                <div>
                    <i className={icon}/>
                </div>
            </Tooltip>
        )
    }

    onContextMenu = (e) => {
        const {openMenu, device} = this.props;
        e.stopPropagation();
        e.preventDefault();
        e.persist();
        openMenu(e, device);
        return false;
    }

    render() {
        const {onExpand, selected, onSelect, expanded, measure, device, index} = this.props;
        const {upperTitle, title, lowerTitle, params, icon, number} = this.getValues();
        return (
            <Col xs={12}>
                <Card type={"container"} className={"pointer"} selected={selected}>
                    <DeviceRow
                        onClick={onSelect}
                        icon={icon}
                        upperTitle={upperTitle}
                        title={title}
                        lowerTitle={lowerTitle}
                        params={params}
                        expanded={expanded}
                        selected={selected}
                        onExpand={onExpand}
                        number={number}
                        showingCallback={measure}
                        onContextMenu={this.onContextMenu}
                    />
                    {
                        !!expanded &&
                        <SiloCharts measure={measure} device={device} index={index}/>
                    }
                </Card>
            </Col>


        );
    }

}

SiloRow.propTypes = {
    device: PropTypes.object.isRequired,
    id: PropTypes.string,
    placements: PropTypes.array,
    name: PropTypes.string
};

SiloRow = connect(makeMapStateToProps, mapDispatchToProps)(SiloRow);

SiloRow.defaultProps = {
    type: "siloses"
}
// export default ClimateRow;
export default withTranslation()(SiloRow);
