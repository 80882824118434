import React from "react";
import PropTypes from "prop-types"
import withRoles from "../../withRoles";
import {Roles} from "../../../constans/roles";
import Button from "../../basics/button/Button";
import {extractDevPlcmnts} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import TemperatureChart from "../../charts/TemperatureChart";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import moment from "moment";
import VentilationChart from "../../charts/VentilationChart";
import HumidityChart from "../../charts/HumidityChart";
import SlurryChart from "../../charts/SlurryChart";
import H2SChart from "../../charts/H2SChart";
import NH3Chart from "../../charts/NH3Chart";
import CO2Chart from "../../charts/CO2Chart";
import PressureChart from "../../charts/PressureChart";
import Input from "../../basics/input/Input";
import Select from "../../basics/select/Select";
import {withTranslation} from "react-i18next";
import ClimateSettingsChart from "../../charts/ClimateSettingsChart";
import {makeGetAggregatedData} from "../../../selectors/aggregatedSelector";
import NewIOT from "../../../IOT/NewIOT";
import {Col, Row} from "react-bootstrap";

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

export class ClimateCharts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chart: "temperature",
            date: moment.utc().startOf("day"),
        }
    }

    componentDidMount() {
        const {device} = this.props;
        const {date} = this.state;
        NewIOT.sendRequestForAggregatedData(device);
        this.checkIfHaveInAggregatedData(date.toDate().getTime());
        this.props.measure();
    }

    componentWillUnmount() {
    }


    checkIfHaveInAggregatedData(timestamp) {
        const {aggregated: {data}, dispatch, device} = this.props;
        const hasData = data.find(item => item.AggTi === timestamp);
        if (!hasData) {
            dispatch(getAggregatedData(device, {
                AggDataTime: timestamp
            }))
        }
    }

    onChartChange = value => {
        this.setState({
            chart: value
        })
    };


    setDate(date) {
        if (date.diff(moment.utc().startOf("day"), "days") <= 0) {
            this.setState({
                date
            });
            this.checkIfHaveInAggregatedData(date.toDate().getTime());
        }
    }

    onDateChange = value => {
        let date = moment.utc(value);
        this.setDate(date);
    };

    onLowerDateClick = () => {
        let date = this.state.date.clone().subtract(1, "day");
        this.setDate(date);
    };

    onAddDateClick = () => {
        let date = this.state.date.clone().add(1, "day");
        this.setDate(date);
    };

    getOptions = () => {
        const {t} = this.props;
        return [
            {
                name: t("chamber.chart.temperature"),
                value: "temperature"
            },
            {
                name: t("chamber.chart.ventilation"),
                value: "ventilation"
            },
            {
                name: t("chamber.chart.humidity"),
                value: "humidity"
            },
            {
                name: t("chamber.chart.slurry"),
                value: "slurry"
            },
            {
                name: t("chamber.chart.h2s"),
                value: "h2s"
            },
            {
                name: t("chamber.chart.nh3"),
                value: "nh3"
            },
            {
                name: t("chamber.chart.co2"),
                value: "co2"
            },
            {
                name: t("chamber.chart.pressure"),
                value: "pressure"
            },
            {
                name: t("chamber.chart.climateSettings"),
                value: "settings"
            }
        ];
    }

    render() {
        const {device} = this.props;
        const {chart, date} = this.state;
        const options = this.getOptions();
        return (
            <div className="device-charts">
                <Col xs={12} className={"mt-2"}>
                    <Row className={"device-chart-select"}>
                        <Col xs={12} xl={8} className={"chart-type mb-2"}>
                            <Select value={chart} options={options} onChange={this.onChartChange} clearButton={false}/>
                        </Col>
                        <Col xs={12} xl={4} className={"chart-date mb-2"}>
                            <Button icon={<i className="fas fa-chevron-left"/>} onClick={this.onLowerDateClick}/>
                            <Input type="date" value={this.state.date.format("YYYY-MM-DD")} onChange={this.onDateChange}/>
                            <Button icon={<i className="fas fa-chevron-right"/>} onClick={this.onAddDateClick}/>
                        </Col>
                    </Row>
                </Col>
                <div className="device-chart">
                    {
                        chart === "temperature" &&
                        <>
                            <div className="chart-main-container">
                                <TemperatureChart device={device}
                                                  date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "ventilation" &&
                        <>
                            <div className="chart-main-container">
                                <VentilationChart device={device}
                                                  date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "humidity" &&
                        <>
                            <div className="chart-main-container">
                                <HumidityChart device={device} date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "slurry" &&
                        <>
                            <div className="chart-main-container">
                                <SlurryChart device={device} date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "h2s" &&
                        <>
                            <div className="chart-main-container">
                                <H2SChart device={device} date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "nh3" &&
                        <>
                            <div className="chart-main-container">
                                <NH3Chart device={device} date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "co2" &&
                        <>
                            <div className="chart-main-container">
                                <CO2Chart device={device} date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "pressure" &&
                        <>
                            <div className="chart-main-container">
                                <PressureChart device={device} date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "settings" &&
                        <>
                            <div className="chart-main-container">
                                <ClimateSettingsChart device={device} date={date}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }

}

ClimateCharts.propTypes = {
    device: PropTypes.object.isRequired,
    id: PropTypes.string,
    placements: PropTypes.array,
    name: PropTypes.string
};

ClimateCharts = withRoles(props => ({
    roles: [Roles._DEVICE_CLIMATE],
    devPlcmnts: extractDevPlcmnts(props.device)
}))(ClimateCharts);

ClimateCharts = connect(makeMapStateToProps)(ClimateCharts);

export default withTranslation()(ClimateCharts);
