import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import LoadingComponent from "../loading/LoadingComponent";
import _ from "lodash";
import {convertVolumeUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {withTranslation} from "react-i18next";

@connect(state => ({
    aggregatedData: state.aggregatedData.data,
    fetchingMap: state.aggregatedData.fetchingMap,
}))


export class WaterDailyChart extends Component {

    getUsage = (AggDt = []) => {
        if (AggDt.length > 1) {
            return AggDt[AggDt.length - 1].MR - AggDt[0].MR
        }
        return null;
    };

    getData = _.memoize(({dateStart, dateEnd, data = []}) => {
        const result = [];
        const start = moment.utc(+dateStart).startOf("day");
        const diff = Math.abs((moment.utc(dateStart)).diff(moment.utc(dateEnd), "days")) + 1;
        for (let i = 0; i < diff; i++) {
            result[i] = {
                name: start.clone().add(i, "days").format("DD.MM"),
                consumption: this.getUsage(_.get(data.find(d => d.AggTi === +start.clone().add(i, "days")), "AggDt"))
            }
        }
        return result;
    });

    formatter = (value) => {
        return _.isFinite(value) ? convertVolumeUnitTo(value, {
            showUnit: false,
            unit: UnitTypes.BIG,
            rawValue: true,
            fixed: 1
        }) : null
    };

    render() {
        const {fetchingMap, device, aggregatedData, dateStart, dateEnd, t} = this.props;
        const hasDate = _.isFinite(dateStart) && _.isFinite(dateEnd);
        const data = hasDate ? this.getData({
            data: aggregatedData.get(device.DevID),
            dateStart: dateStart,
            dateEnd: dateEnd
        }) : [];
        const loading = hasDate ? !!fetchingMap.get(device.DevID) && !data.length : false;
        const def = [
            {
                color: "blue",
                dataKey: "consumption",
                name: t("charts.waterHourlyChart.consumption"),
                valueConverter: this.formatter,
                unit: getUnit("volume", UnitTypes.BIG),
                opacity: 0.2,
            }
        ];
        return (
            <React.Fragment>
                <LoadingComponent isLoading={loading}/>
                <Chart type={"Bar"} dataDef={def} data={data} Yaxis={{
                    name: t("charts.waterHourlyChart.consumption"),
                }} Xaxis={{
                    name: t("day"),
                    dataKey: "name"
                }} saveAsExcell={"water"}
                />
            </React.Fragment>
        )
    }
}

WaterDailyChart.defaultProps = {
    colors: {
        water: "#4a9bd4"
    },
    connectNulls: true,
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};


WaterDailyChart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    dateEnd: PropTypes.number.isRequired,
    dateStart: PropTypes.number.isRequired,
    device: PropTypes.object.isRequired,
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

export default withTranslation()(WaterDailyChart);

