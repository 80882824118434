import React, {Component} from 'react';
import {connect} from 'react-redux';
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import Menu from "../../basics/menu/Menu";
import MenuItem from "../../basics/menu/MenuItem";
import InfoBox from "../../basics/info-box/InfoBox";
import Button from "../../basics/button/Button";
import {getSelected} from "../../../selectors/device-rows/siloSelector";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import SiloSettingsModal, {ModalName as SiloSettingsModalModalName} from "../../modals-new/silo-settings-modal/SiloSettingsModal";

const params = {type: "siloses"}

function mapStateToProps(state) {
    return {
        selected: getSelected(state, params)
    };
}

class SiloButtons extends Component {

    onConfigurationClick = () => {
        const {selected} = this.props;
        this.props.show(SiloSettingsModalModalName, {siloses: selected});
    };

    render() {
        const {selected, event, openMenu, t} = this.props;
        return (
            <>
                <ButtonGroup fixed>
                    <Menu event={event}>
                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>} onClick={this.onConfigurationClick}>
                            {t("configuration")}
                        </MenuItem>
                    </Menu>
                    <InfoBox boxColor={"info"}>
                        {t("deviceRows.siloses.siloRow.selected", {count: selected.length})}
                    </InfoBox>
                    <Button buttonStyle={"round"} buttonColor={"info"}
                            icon={<i className="fas fa-ellipsis-v"/>} disabled={selected.length === 0}
                            onClick={openMenu}/>
                </ButtonGroup>
                <SiloSettingsModal/>
            </>
        );
    }
}

SiloButtons = withTranslation()(SiloButtons);

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({show}, dispatch)
)(SiloButtons);
