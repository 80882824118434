import React from "react";
import PropTypes from "prop-types"
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {get} from "lodash";
import {convertTemperatureUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";

export class ClimateCurveChartSK3 extends React.Component {

    getData = () => {
        const {stages} = this.props;
        if (stages.length !== 8) return [];
        const result = [];
        const _getParam = (index, paramName1, paramName2) => get(stages, `[${index}].${paramName1}`, get(stages, `[${index}].${paramName2}`, 0))
        const getDays = (index) => _getParam(index, "Days", "days");
        const getFloor = (index) => _getParam(index, "Floor", "floor");
        const getRequestedTemperature = (index) => _getParam(index, "RequestedTemperature", "requestedTemperature");
        const getMinimumVentilation = (index) => _getParam(index, "MinimumVentilation", "minimumVentilation");
        let currentDay = 1;
        for (let i = 0; i < 8; i++) {
            currentDay = currentDay + (i ? getDays(i) : 0)
            result[i] = {
                days: currentDay,
                expectedTemperature: getRequestedTemperature(i),
                ventilationMin: getMinimumVentilation(i),
                floor: getFloor(i)
            }
        }
        return result;
    }

    render() {
        const {t, className, curveDay} = this.props;
        const data = this.getData();
        const unit = getUnit("temperature");
        const temperatureFormatter = value => convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            fixed: 1,
            rawValue: true,
            acceptNil: true
        })
        const chartDef = [
            {
                color: "green",
                dataKey: "expectedTemperature",
                type: "line",
                name: t("requestedTemperature"),
                valueConverter: temperatureFormatter,
                unit: unit
            },
            {
                color: "orange",
                dataKey: "ventilationMin",
                type: "line",
                name: t("minimumVentilation"),
                unit: "%"
            },
            {
                color: "blue",
                dataKey: "floor",
                type: "line",
                name: t("settings.floor"),
                unit: "%"
            }
        ];
        const referenceLines = curveDay ? [{
            x: curveDay,
            name: t("curveDay"),
            color: "blue"
        }] : undefined;
        const cName = ["mh-20rem h-0", className].filter(o => !!o).join(" ");
        return (
            <div className={cName}>
                <Chart dataDef={chartDef} data={data} Xaxis={{
                    dataKey: "days",
                    name: t("day"),
                    type: "number"
                }} Yaxis={{
                    name: t("value")
                }}
                       referenceLines={referenceLines}
                />
            </div>
        );
    }

}

ClimateCurveChartSK3.propTypes = {
    stages: PropTypes.array.isRequired,
    curveDay: PropTypes.number
};

export default withTranslation()(ClimateCurveChartSK3);
