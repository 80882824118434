import {Component} from 'react';
import PropTypes from "prop-types"
import {IntervalTimes} from "../../constans/intervals";
import _ from "lodash";
import {withTranslation} from "react-i18next";


class IconStatus extends Component {

    componentDidMount() {
        this.interval = setInterval(() => {
           this.forceUpdate();
        }, IntervalTimes.DEVICE_OUTDATED_DATA_CHECK)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getValues = ({t, isLoading, dateTime, isConnected, isAlerted, successClassName, isDisabled}) => {
        let overrideIcon;
        if (isLoading) {
            if (isConnected) {
                overrideIcon = "fas fa-fw fa-circle-notch fa-spin";
            } else {
                return {
                    icon: "fas fa-fw fa-circle-notch fa-spin",
                    color: isConnected ? successClassName : "warning",
                    header: t("deviceRows.iconStatus.loading")
                }
            }

        }
        if (isConnected) {
            const outdated = _.isFinite(dateTime) ? Math.abs(dateTime - (+new Date())) > IntervalTimes.DEVICE_OUTDATED_DATA : false;
            if (outdated) {
                return {
                    icon: overrideIcon || "fas fa-fw fa-sync",
                    color: "warning",
                    header: t("outOfSync")
                };
            }
            if (isAlerted) {
                return {
                    icon: overrideIcon || "fas fa-fw fa-exclamation",
                    color: "error",
                    header: t("alertedDevice")
                };
            }
            if(isDisabled){
                return {
                    icon: overrideIcon || "fas fa-fw fa-pause",
                    color: "disabled",
                    header: t("deviceRows.iconStatus.disabled")
                };
            }
            return {
                icon: overrideIcon || "fas fa-fw fa-cog fa-spin",
                color: successClassName,
                header: t("deviceRows.iconStatus.active")
            };

        }
        return {
            icon: "fas fa-fw fa-wifi-slash",
            color: "warning",
            header: t("notConnectedDevice")
        };


    };

    render() {
        const {children} = this.props;
        const {icon, color, header} = this.getValues(this.props);
        return children({icon, color, header})
    }
}

IconStatus.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    responseTime: PropTypes.oneOf([PropTypes.number, null]),
    isConnected: PropTypes.bool.isRequired,
    isAlerted: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
    successClassName: PropTypes.string
};

IconStatus.defaultProps = {
    successClassName: "success"
};
export default withTranslation()(IconStatus);
