import React from "react";
import DeviceListItem from "../mini-components/DeviceListItem";
import "../_rfid-actions.scss"
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {getFeedingRFIDDevices} from "../../../../selectors/feedingSelector";

export class RFIDActions extends React.Component {

    render() {
        const {chamber: {IndividualFeeding}} = this.props;
        if (IndividualFeeding) return null;
        const {chamber, data, dispensers, startSendingDeviceState} = this.props;
        return (
            <Col xs={12} className={"rfid-actions"}>
                <Row>
                    {
                        data.map(d => (
                            <DeviceListItem startSendingDeviceState={startSendingDeviceState} chamberId={chamber.CID}
                                            key={d.id}
                                            device={dispensers.find(o => o.DevID === d.id)} data={d}/>
                        ))
                    }
                </Row>
            </Col>

        )
    }
}

let _RFIDActions = connect((state, props) => {
    const {chamber: {CID}} = props;
    return {
        data: getFeedingRFIDDevices(state, {CID}),
    }
})(RFIDActions);
export default _RFIDActions;