import React from "react";
import {Col, Row} from "react-bootstrap";
import NewIOT from "../../../IOT/NewIOT";
import Card from "../../basics/card/Card";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "./_dispenser.scss"
import {getColorByIndex} from "../../../utils/ColorUtils";
import {feedingDestroy, feedingInit, feedingUpdateAnimals} from "../../../actions/feedingActions";
import {getFeedingUnit} from "../../../utils/SettingsUtils";
import AdvancedView from "./advanced/AdvancedView";
import SimpleView from "./simple/SimpleView";
import {scrollToID} from "../../../utils/DOMUtils";
import moment from "moment";
import {checkCondition} from "../../../utils/DispenserAdapterUtils";
import {getCurveDayShowingType} from "../../../utils/FeedingUtils";
import {CurveDayShowingType} from "../../../constans/feedingTypes";
import {getLicenseByName} from "../../../selectors/roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import {withTranslation} from "react-i18next";
import {isMobile} from "../../../utils/MobileUtils";
import i18next from "i18next";
import {isEqual, isNil, memoize} from "lodash"

export class DispenserChamber extends React.PureComponent {

    getConditionCount = memoize(({animals, curveMap, chamber}) => {
        const animalCount = animals.length;
        let conditionCount = 0;
        animals.forEach(animal => {
            const {feedParam} = animal;
            if (feedParam) {
                //nie brac pod uwage zwierzat na komorze jesli sa stanowiska
                if (chamber.IndividualFeeding && (animal.PlcmntID === chamber.CID)) return;
                const {startTime, stage, curveNr} = feedParam;
                if (!isNil(startTime) && curveNr) {
                    const curve = [...curveMap.values()].find(c => (c.SetData.Index + 1 === curveNr));
                    if (curve) {
                        let day = moment().startOf("day").diff(moment(startTime).startOf("day"), "day") + 1;
                        //min dzien to 1, max to ilosc dni krzywej
                        day = Math.min(Math.max(day, 1), curve.SetData.Days.length);
                        //jesli jest laczona
                        if (getCurveDayShowingType(curve) === CurveDayShowingType.BOTH) {
                            //max dzien bez staga to dzien ins/por
                            if (stage !== 1) {
                                day = Math.min(day, curve.SetData.InseminationJumpTo);
                            } else {
                                day += curve.SetData.InseminationJumpTo;
                                //trzeba doliczyc dzien porodu bo starTime wskazuje na dzien porodu a nie na start krzywej
                            }
                        }
                        console.log(curve, day, animal);
                        if (checkCondition({curveMap, curveId: curve.SetID, day, animal})) {
                            conditionCount++
                        }
                    }
                }
            }

        });
        return {
            animalCount,
            conditionCount
        }
    }, (...args) => JSON.stringify(args));

    constructor(props) {
        super(props);
        this.sentMessages = [];
        this.state = {
            loading: true,
            sectorTypes: i18next.t("SType", {returnObjects: true})
        }
    }

    startLoading = () => {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 10 * 1000);
        this.setState({
            loading: true
        })
    };

    stopLoading = () => {
        clearTimeout(this.loadingTimer);
        this.setState({
            loading: false
        })
    };

    getData = () => {
        NewIOT.removeFromInterval(this.sentMessages);
        this.sentMessages = [];
        const {dispatch, chamber, opened, dispensers, animals} = this.props;
        if (!opened) {
            if (dispensers.length) {
                let gatewayWithDevices = new Map();
                for (let device of dispensers) {
                    let gatewayId = device.GatewayID;
                    if (gatewayId) {
                        let deviceIds = gatewayWithDevices.get(gatewayId) || [];
                        deviceIds.push(device.DevID);
                        gatewayWithDevices.set(gatewayId, deviceIds);
                    }
                }
                for (let [gatewayId, deviceIds] of gatewayWithDevices.entries()) {
                    this.startSendingDeviceState(deviceIds, gatewayId, chamber, {animals, keepAlive: true});
                }

            }
            dispatch(feedingInit(chamber, {dispensers, animals}));
            this.startLoading();
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {lang} = this.props;
        return !isEqual(this.props, nextProps) || !isEqual(lang, nextProps.lang);
    }

    componentWillUnmount() {
        this.clearData();
    }

    clearData = () => {
        const {chamber, dispatch, opened} = this.props;
        if (opened) {
            dispatch(feedingDestroy(chamber));
        }
        //usunac dane
        NewIOT.removeFromInterval(this.sentMessages);
        this.sentMessages = [];
    };

    toggleOpen = () => {
        const {opened} = this.props;
        if (!opened) {
            this.getData();
        } else {
            this.clearData();
            this.stopLoading();
            this.updatePosition();
        }
    };

    startSendingDeviceState = (deviceIds, gatewayId, chamber, {onSuccess, onFailure, keepAlive = false, animals = []} = {}) => {
        console.log(deviceIds, gatewayId, animals, "DSDADSSD")
        NewIOT.removeFromInterval(this.sentMessages);
        const {CID, IndividualFeeding} = chamber;
        this.sentMessages.push(NewIOT.startSendingFeedingState(deviceIds, {onSuccess, onFailure, keepAlive}));
        if (!IndividualFeeding) {
            this.sentMessages.push(NewIOT.startSendingFeedingStateRFID(gatewayId, CID, {keepAlive: true}))
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch, opened, dispensers, chamber, animals} = this.props;
        if (opened && !isEqual(animals, prevProps.animals)) {
            dispatch(feedingUpdateAnimals(chamber, {dispensers, animals}));
        }
        if (opened !== prevProps.opened) {
            this.updatePosition();
            if (opened) {
                scrollToID(`chamber_${chamber.CID}`);
            }
        }
        if (prevProps !== this.props) {
            this.setState({
                sectorTypes: i18next.t("SType", {returnObjects: true})
            })
        }
    }

    /**
     * WindowScroller bez tego nie bedzie wiedzial na jakies wysokosci zaczyna sie lista
     */
    updatePosition = () => {
        window.dispatchEvent(new Event('resize'));
        window.dispatchEvent(new Event('scroll'));
    };

    render() {
        const {license, animals, sectorType, index, chamber, dispensers, mobile, forageMap, curveMap, scheduleMap, viewType, unit, setEvent, opened: isOpen} = this.props;
        const {loading, sectorTypes} = this.state;
        const {conditionCount = 0} = license === LicPackageLevel.EXTENDED ? this.getConditionCount({
            animals,
            curveMap,
            chamber
        }) : {};
        const showAdvancedComponent = !chamber.IndividualFeeding || !viewType;
        const color = getColorByIndex(index);
        return (
            <>
                <Card style={{borderLeft: `4px solid ${color}`}}
                      className={"dispenser-chamber overflow-visible"}
                      fixedWidth>
                    <Row
                        onClick={this.toggleOpen}
                        className={'pointer'}>
                        <Col md={12}>
                            <h5 className="chamber-menu justify-content-between" id={`chamber_${chamber.CID}`}>
                                    <span>
                                        {(isMobile() && !isOpen) ? chamber.CName : !isMobile() && chamber.CName}
                                        {
                                            (isMobile() && !isOpen) ?
                                                <small
                                                    className={"ml-1 d-none d-md-inline-block opacity-75"}>{sectorTypes[sectorType ? sectorType - 1 : 999]}</small> :
                                                !isMobile() && <small
                                                    className={"ml-1 d-none d-md-inline-block opacity-75"}>{sectorTypes[sectorType ? sectorType - 1 : 999]}</small>
                                        }
                                        {(isMobile() && !isOpen) ? <i className={`ml-1 mr-1 fas fa-arrow-${isOpen ? "up" : "down"}`}/> : !isMobile() && <i className={`ml-1 mr-1 fas fa-arrow-${isOpen ? "up" : "down"}`}/>}
                                    </span>
                                {
                                    !!conditionCount &&
                                    <span>{conditionCount}<i className="ml-1 fas fa-pig info"/></span>
                                }
                            </h5>
                        </Col>
                    </Row>
                    {
                        !!isOpen && !showAdvancedComponent && <SimpleView
                            chamber={chamber}
                            forageMap={forageMap}
                            unit={unit}
                            mobile={mobile}
                            curveMap={curveMap}
                            sectorType={sectorType}
                            isLoading={loading}
                            isOpen={isOpen}
                            toggleOpen={this.toggleOpen}
                            scheduleMap={scheduleMap}
                        />
                    }
                    {
                        !!isOpen && showAdvancedComponent && <AdvancedView
                            chamber={chamber}
                            forageMap={forageMap}
                            unit={unit}
                            sectorType={sectorType}
                            setEvent={setEvent}
                            startSendingDeviceState={this.startSendingDeviceState}
                            curveMap={curveMap}
                            scheduleMap={scheduleMap}
                            isLoading={loading}
                            isOpen={isOpen}
                            toggleOpen={this.toggleOpen}
                            dispensers={dispensers}
                            mobile={mobile}
                        />
                    }
                </Card>
            </>
        );
    }

}

DispenserChamber.propTypes = {
    chamber: PropTypes.object.isRequired,
    sectorType: PropTypes.object.isRequired,
    dispensers: PropTypes.array.isRequired,
    animals: PropTypes.array.isRequired,
    unit: PropTypes.number
};

DispenserChamber.defaultProps = {
    unit: getFeedingUnit()
};

DispenserChamber = connect((state, props) => {
    const {chamber: {CID}} = props;
    return {
        opened: !!state.feeding.feeding[CID],
        farm: state.location.farm,
        license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER}),
        lang: state.language.lang.lang
    }
})(DispenserChamber);
export default withTranslation()(DispenserChamber);
