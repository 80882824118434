import React from "react";
import PropTypes from "prop-types";
import IconValue from "../../basics/icon-value/IconValue";
import AverageWeight from "../../../resources/images/cage/average_weight.svg"
import Passes from "../../../resources/images/cage/pig_walk.svg"
import {withTranslation} from "react-i18next";

export class CageSideData extends React.Component {

    render() {
        const {name, weight, passes} = this.props;
        return (
            <div className="cage-side-data">
                <div className="side-name">{name}</div>
                <div className="side-data">
                    <IconValue icon={AverageWeight} name={this.props.t("chamber.separationCageInfo.averageWeightShort")}
                               value={weight}/>
                    <IconValue icon={Passes} name={this.props.t("chamber.separationCageInfo.passes")} value={passes}/>
                </div>
            </div>
        );
    }

}

CageSideData.propTypes = {
    name: PropTypes.string.isRequired,
    weight: PropTypes.node.isRequired,
    passes: PropTypes.node.isRequired
}

export default withTranslation()(CageSideData);