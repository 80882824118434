import React, {Component} from 'react';
import {connect} from 'react-redux';
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import Menu from "../../basics/menu/Menu";
import MenuItem from "../../basics/menu/MenuItem";
import InfoBox from "../../basics/info-box/InfoBox";
import Button from "../../basics/button/Button";
import ClimateConfigurationModal, {ModalName as ClimateConfigurationModalName} from "../../modals-new/climate-configuration/ClimateConfigurationModal";
import {getSelected} from "../../../selectors/device-rows/climateSelector";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {DevType} from "../../../constans/devices";
import ClimateMatsModal, {ModalName as ClimateMatsModalName} from "../../modals-new/climate-mats-modal/ClimateMatsModal";
import ClimateBandwidthModal, {ModalName as ClimateBandwidthModalName} from "../../modals-new/climate-bandwidth-modal/ClimateBandwidthModal";
import ClimateAlarmsModal, {ModalName as ClimateAlarmsModalName} from "../../modals-new/climate-alarms-modal/ClimateAlarmsModal";
import ClimateWorkTypeModal, {ModalName as ClimateWorkTypeModalName} from "../../modals-new/climate-work-type-modal/ClimateWorkTypeModal";

const params = {type: "climates"}

function mapStateToProps(state) {
    return {
        selected: getSelected(state, params)
    };
}

class ClimateButtons extends Component {

    onConfigurationClick = () => {
        const {selected} = this.props;
        const climates = selected.map(o => o.device);
        this.props.show(ClimateConfigurationModalName, {climates: climates});
    };

    onWorkTypeClick = () => {
        const {selected} = this.props;
        const climates = selected.map(o => o.device);
        this.props.show(ClimateWorkTypeModalName, {climates: climates});
    };

    onAlarmsClick = () => {
        const {selected} = this.props;
        const climates = selected.map(o => o.device);
        this.props.show(ClimateAlarmsModalName, {climates: climates});
    };

    onBandwidthClick = () => {
        const {selected} = this.props;
        const climates = selected.map(o => o.device);
        this.props.show(ClimateBandwidthModalName, {climates: climates});
    };

    onMatsClick = () => {
        const {selected} = this.props;
        const climates = selected.map(o => o.device);
        this.props.show(ClimateMatsModalName, {climates: climates});
    };

    hasDevType = (devType) => {
        const {selected} = this.props;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].device.DevType === devType) {
                return true;
            }
        }
        return false;
    }

    render() {
        const {selected, event, openMenu, t} = this.props;
        const hasSK3 = this.hasDevType(DevType.CLIMATE_SK3);
        const hasClimate = this.hasDevType(DevType.CLIMATE);
        //jesli ma zaznaczone rozne typy klimatow to nie wyswietlaj nic
        const showOptions = (+hasSK3 + +hasClimate) === 1;
        return (
            <>
                <ButtonGroup fixed>
                    <Menu event={event}>
                        {
                            showOptions &&
                            <>
                                {
                                    hasClimate &&
                                    <>
                                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>}
                                                  onClick={this.onConfigurationClick}>
                                            {t("configuration")}
                                        </MenuItem>
                                    </>
                                }
                                {
                                    hasSK3 &&
                                    <>
                                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>}
                                                  onClick={this.onWorkTypeClick}>
                                            {t("worktype")}
                                        </MenuItem>
                                    </>
                                }
                                {
                                    hasSK3 &&
                                    <>
                                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>}
                                                  onClick={this.onAlarmsClick}>
                                            {t("deviceRows.waterFlowMeter.waterTab.alarms")}
                                        </MenuItem>
                                    </>
                                }
                                {
                                    hasSK3 &&
                                    <>
                                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>}
                                                  onClick={this.onBandwidthClick}>
                                            {t("settings.bandwithVenting")}
                                        </MenuItem>
                                    </>
                                }
                                {
                                    hasSK3 &&
                                    <>
                                        <MenuItem icon={<i className="fas fa-cog fa-fw"/>}
                                                  onClick={this.onMatsClick}>
                                            {t("farmView.climate.matSettings")}
                                        </MenuItem>
                                    </>
                                }
                            </>
                        }

                    </Menu>
                    <InfoBox boxColor={"info"}>
                        {t("farmView.climate.selectedClimates", {count: selected.length})}
                    </InfoBox>
                    <Button buttonStyle={"round"} buttonColor={"info"}
                            icon={<i className="fas fa-ellipsis-v"/>} disabled={selected.length === 0}
                            onClick={openMenu}/>
                </ButtonGroup>
                <ClimateConfigurationModal/>
                <ClimateWorkTypeModal/>
                <ClimateAlarmsModal/>
                <ClimateBandwidthModal/>
                <ClimateMatsModal/>
            </>
        );
    }
}

ClimateButtons = withTranslation()(ClimateButtons);

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({show}, dispatch)
)(ClimateButtons);
