import React from "react";
import SortMenu from "../mini-components/SortMenu";
import Button from "../../../basics/button/Button";
import SearchBox from "../mini-components/SearchBox";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {feedingSelectAll, feedingUnselectAll} from "../../../../actions/feedingActions";
import {getHeaderTabsHeight} from "../../../../utils/DOMUtils";
import SettlementButton from "../mini-components/SettlementButton";
import {withTranslation} from "react-i18next";
import {isMobile} from "../../../../utils/MobileUtils";
// import _ from "lodash";

const _actionsRenderer = ({t, chamber, sectorType, selectAll, unselectAll}) => (
    <Row>
        <Col xs={12} lg={8} xl={6}>
            <SearchBox chamber={chamber}/>
        </Col>
        {/*wyłączam zasiedlenie dla wszystkich ze względu na niedziałające API*/}
        {
            !!0 && <Col xs={12} lg={4} xl={3}>
                <SettlementButton sectorType={sectorType} chamber={chamber}/>
            </Col>
        }

        <Col xs={4} lg={4} xl={2}>
            <SortMenu chamber={chamber}/>
        </Col>
        <Col xs={4} lg={6} xl={2}>
            <Button title={t("deviceRows.feeding.dispenserChamber.select")} type={"button"} className={"w-100"}
                    onClick={selectAll}><i
                className="far fa-check-square d-lg-none"/><span
                className={"d-none d-lg-inline"}>{t("deviceRows.feeding.dispenserChamber.select")}</span></Button>
        </Col>
        <Col xs={4} lg={6} xl={2}>
            <Button title={t("deviceRows.feeding.dispenserChamber.unselect")} type={"button"} className={"w-100"}
                    onClick={unselectAll}><i
                className="far fa-square d-lg-none"/><span
                className={"d-none d-lg-inline"}>{t("deviceRows.feeding.dispenserChamber.unselect")}</span></Button>
        </Col>
    </Row>
);

const Actions = React.memo(_actionsRenderer);

class ChamberActions extends React.Component {

    selectAll = () => {
        const {dispatch, chamber} = this.props;
        dispatch(feedingSelectAll(chamber));
    };

    unselectAll = () => {
        const {dispatch, chamber} = this.props;
        dispatch(feedingUnselectAll(chamber));
    };

    render() {
        const {chamber, sectorType, t, isOpen, toggleOpen} = this.props;
        const top = getHeaderTabsHeight();
        const mobile = isMobile();
        return (
            <Col className={`pt-1rem ${!mobile ? 'sticky-item' : ''}`} style={{top: top, zIndex: 2}} md={12}>
                <Row onClick={toggleOpen} className={'pointer justify-content-center'}>
                    {
                        <h5 className="chamber-menu justify-content-between" id={`chamber_${chamber.CID}`}>
                            <span>
                                {!!isOpen && mobile && chamber.CName}
                                {mobile && <i className={`ml-1 mr-1 fas fa-arrow-${isOpen ? "up" : "down"}`}/>}
                            </span>
                        </h5>
                    }
                </Row>
                <Actions sectorType={sectorType} t={t} chamber={chamber} setEvent={this.setEvent}
                         unselectAll={this.unselectAll}
                         selectAll={this.selectAll}/>
            </Col>
        )
    }
}

ChamberActions = withTranslation()(ChamberActions);

export default ChamberActions = connect()(ChamberActions)
