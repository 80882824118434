import React, {Component} from 'react';
import {connect} from 'react-redux';
import Chart from "../basics/chart/Chart";
import Loading from "../loading/LoadingComponent";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types"
import Climate from "../../beans/devices/Climate";
import moment from "moment";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForClimateSettingsChart, tickFormatter} from "../../utils/ChartsUtils";

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

class ClimateSettingsChart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const currentDay = data.find(item => item.AggTi === +date);
        const {data: settingData, lastTime} = currentDay ? calculateDataForClimateSettingsChart(currentDay) : {
            data: [],
            lastTime: 0
        };
        return {
            data: settingData,
            lastTime
        }
    }

    worktypeFormatter = value => {
        const {t} = this.props;
        switch (value) {
            case 0:
                return t("chamber.chart.climateStandard");
            case 1:
                return t("chamber.chart.climateManual");
            case 2:
                return t("chamber.chart.climateSoaking");
            case 3:
                return t("chamber.chart.climateDrying");
            default:
                return value;
        }
    }

    curveActiveFormatter = value => {
        const {t} = this.props;
        if (value) return t("active");
        return t("chamber.chart.curveActiveFalse");
    }

    tooltipFormatter = (value, name, props) => {
        if (props.dataKey === "worktype") return this.worktypeFormatter(value);
        return this.curveActiveFormatter(value);
    }

    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        const dataDef = [
            {
                color: "green",
                dataKey: "worktype",
                name: t("worktype"),
                opacity: 0.2,
                yAxisId: "worktype",
                type: "stepAfter"
            },
            {
                color: "blue",
                dataKey: "curveActive",
                name: t("climateCurve"),
                opacity: 0.2,
                yAxisId: "curveActive",
                type: "stepAfter"
            }
        ]
        return (
            <React.Fragment>
                <Loading isLoading={fetching}/>
                <Chart dataDef={dataDef}
                       data={data}
                       Yaxis={{
                           formatter: this.worktypeFormatter,
                           ticks: [0, 1, 2, 3],
                           width: 100,
                           yAxisId: "worktype"
                       }}
                       secondYaxis={{
                           yAxisId: "curveActive",
                           ticks: [0, 1],
                           formatter: this.curveActiveFormatter,
                           width: 100
                       }}
                       Xaxis={{
                           name: t("time"),
                           dataKey: "name",
                           ticks: ticks,
                           type: "number",
                           formatter: tickFormatter,
                           domain: [date.clone().startOf("day").toDate().getTime(), "dataMax"],
                       }}
                       saveAsExcell={"climateSettings"}
                       tooltipFormatter={this.tooltipFormatter}
                       tooltipLabelFormatter={tickFormatter}
                />
            </React.Fragment>
        );
    }
}

ClimateSettingsChart.propTypes = {
    climate: PropTypes.instanceOf(Climate).isRequired,
    date: PropTypes.instanceOf(moment).isRequired,
}

export default compose(
    connect(makeMapStateToProps),
    withTranslation()
)(ClimateSettingsChart);
