import React from "react";
import "./_compare-insertion-tooltip.scss"
import {withTranslation} from "react-i18next";

export class CompareInsertionsTooltip extends React.Component {

    render() {
        const {label, payload, t} = this.props;
        return (
            <div className="compare-insertion-tooltip">
                <div>{label}</div>
                <ul>
                    <li>{t("compareInsertionsTooltip.insertion")} 1</li>
                    {
                        payload.map((item, index) => {
                            let itemBefore = payload[index - 1];
                            let isFirstShadow = false;
                            if (itemBefore && !itemBefore.dataKey.includes("Shadow") && item.dataKey.includes("Shadow")) {
                                isFirstShadow = true;
                            }
                            return (
                                <>
                                    {
                                        isFirstShadow && <li>{t("compareInsertionsTooltip.insertion")} 2</li>
                                    }
                                    <li style={{color: item.fill}}>{item.name}: {item.value}{item.unit}</li>
                                </>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }

}

export default withTranslation()(CompareInsertionsTooltip);