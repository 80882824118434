import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../loading/LoadingComponent";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {calculateDataForCo2Chart, tickFormatter} from "../../utils/ChartsUtils";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";

export class CO2Chart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const currentDay = data.find(item => item.AggTi === +date);
        const {co2, lastTime} = currentDay ? calculateDataForCo2Chart(currentDay) : {
            co2: [],
            lastTime: 0
        };
        return {
            data: co2,
            lastTime
        }
    }

    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const def = [
            {
                color: "black",
                dataKey: "co2",
                name: t("chamber.chart.co2"),
                unit: "ppm",
                opacity: 0.2
            }
        ];
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("chamber.chart.co2")
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [date.startOf("day").toDate().getTime(), "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }} saveAsExcell={"co2"} tooltipLabelFormatter={tickFormatter} showDomainInput/>
            </React.Fragment>
        )
    }
}

CO2Chart.defaultProps = {
    date: moment().subtract(1, "days"),
};

CO2Chart.propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired
};

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

CO2Chart = connect(
    makeMapStateToProps
)(CO2Chart);

export default withTranslation()(CO2Chart);
