import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_dispenser-simple.scss";
import "../_dispenser.scss";
import IconStatus from "../mini-components/IconStatus";
import {ForageAmount} from "../mini-components/MiniComponents";
import {get, isNil, memoize} from "lodash";
import {FeedingType} from "../../../../constans/feedingTypes";
import {feedingSelect} from "../../../../actions/feedingActions";
import LoadingListItem from "../../../loading/LoadingListItem";
import settingsDB from "../../../../database/settingsDB";
import {formatCurveDay} from "../../../../utils/FeedingUtils";
import Highlight from "../../../basics/highlight/Highlight";
import {getStageName} from "../../../../utils/DispenserAdapterUtils";
import {getLicenseByName} from "../../../../selectors/roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../../../../constans/roles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

@connect(state=>({
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))
class DispenserSimple extends Component {

    parseErrors = memoize((name, errors) => (errors || []).map((code) => {
        switch (code) {
            case 5101:
            case 5103:
            case 5104:
            case 5202:
            case 5204:
                return this.props.t(`notificationsCenter.errorCodes.${code}`, {number: get(this.props.data, 'curve.number', '-')});
            default:
                return this.props.t(`notificationsCenter.errorCodes.${code}`); // TODO dodac obsluge innych bledow zgodnie z dokumentacja i18next!
        }
    }), (...args) => JSON.stringify(args));

    iconStatus = () => {
        const {license, scheduleMap, curveMap, data: {animal, dateTime, name, curve = {}, schedule = {}, skipDoses, connected, receiver, workType, feeding, alert, locked, errors: errorsProps} = {}, loading: loadingProps} = this.props;
        const loading = !!receiver && !connected && !!loadingProps;
        const errors = this.parseErrors(name, errorsProps);
        return (
            <IconStatus extended={LicPackageLevel.EXTENDED === license} animal={animal} curveMap={curveMap}
                        curveId={curve.id} day={curve.day} dateTime={dateTime}
                        scheduleId={schedule.id} scheduleMap={scheduleMap} skipDoses={skipDoses}
                        loading={loading} receiver={receiver} workType={workType}
                        feedingType={FeedingType.INDIVIDUAL}
                        locked={locked} alert={alert}
                        feeding={feeding} connected={connected}
                        errors={errors}/>
        )
    };

    forageAmount = () => {
        const {forageMap, unit, data: {feed = {}, additionalFeeding = []} = {}} = this.props;
        const additional = additionalFeeding.reduce((a, b) => a + (b.success ? b.dose : 0), 0);
        return (
            <ForageAmount forageMap={forageMap}
                          unit={unit}
                          additional={additional}
                          planned={feed.plannedUsage}
                          used={feed.usage}/>
        )
    };

    curveDay = () => {
        const {curveMap, data: {curve: {id, number = 0, day = 0} = {}} = {}} = this.props;
        let curveDay = null;
        if (number) {
            let curve = id ? ((curveMap && curveMap.get(id)) || settingsDB.getSettingByID(id)) : null;
            curveDay = (!isNil(day) && curve) ? formatCurveDay(day, curve) : null;
        }
        const stageName = getStageName({curveMap, curveId: id, day});
        return (
            <div className={"curve-day"}>
                <i className="opacity-25 fas fa-calendar-day mr-1"/>{isNil(curveDay) ? "-" : curveDay}{!!stageName &&
            <small
                className={"opacity-50 ml-1"}>({stageName})</small>}
            </div>
        )
    };

    forageModificators = () => {
        const {data: {curve: {correction = 0} = {}} = {}} = this.props;
        return <div className={"feeding-status"}>{(correction ? <small><Highlight
            highlightColor={correction > 0 ? "success" : "info"}>{`${correction > 0 ? "+" : ""}${correction}%`}</Highlight></small> : null)}</div>;
    };

    handleOnClick = () => {
        const {dispatch, location, data: {id, feed}} = this.props;
        if (feed) {//enable select on shadow
            dispatch(feedingSelect(location, id));
        }
    };

    digitify = (str = "") => {
        return str.replace(/[^0-9]/g, "");
    };

    render() {
        const {license, data: {name, receiver, connected, animal: {AnmNo1} = {}} = {}, style, className, selected, isLoading} = this.props;
        const loading = !connected && isLoading && !!receiver;
        const cName = ["dispenser-simple fetura-list-item", className, selected ? "selected" : ""].filter(o => !!o).join(" ");
        return (
            <div style={style} onClick={this.handleOnClick}
                 className={cName}>
                <LoadingListItem useTransition={true} isLoading={loading}/>
                <div className={"name"}>{this.digitify(name) || "-"}</div>
                {
                    (license === LicPackageLevel.EXTENDED) &&
                    <div className={"number"}>{AnmNo1 || "-"}</div>
                }
                {this.iconStatus()}
                {
                    !!connected &&
                    <>
                        {this.curveDay()}
                        {this.forageModificators()}
                        {this.forageAmount()}
                    </>
                }
            </div>
        );
    }
}

DispenserSimple.propTypes = {
    data: PropTypes.object.isRequired
};

export default withTranslation()(DispenserSimple);
