import React from "react";
import PropTypes from "prop-types";
import Loading from "../loading/LoadingComponent";
import NotFound from "../NotFound";
import _ from "lodash";
import {connect} from "react-redux";
import Chart from "../basics/chart/Chart";
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import Worker from "../../workers/charts/rfidChart.worker";
import {withTranslation} from "react-i18next";

export class RFIDChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props.t('chamber'), nextProps.t('chamber'))) return true;
        return !_.isEqual(this.state, nextState);
    }

    componentDidMount() {
        let worker = new Worker();
        let cageData = this.props.cageData.get(this.props.cage.DevID);
        worker.postMessage({
            cageData,
            rfid: this.props.RFID,
            settlement: this.props.settlement,
        });
        worker.onmessage = event => {
            this.setState({
                data: event.data.passes,
                loading: false,
            })
        };
        this.setState({
            worker
        })
    }

    componentWillUnmount() {
        if (this.state.worker) {
            this.state.worker.terminate();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.cageData, nextProps.cageData) || this.props.RFID !== nextProps.RFID || this.props.insertion !== nextProps.insertion) {
            let cageData = nextProps.cageData.get(nextProps.cage.DevID);
            this.state.worker.postMessage({
                cageData,
                rfid: nextProps.RFID,
                settlement: nextProps.settlement,
            })
        }
    }

    weightValueConverter = value => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, fixed: 1, rawValue: true});
    };

    render() {
        const {loading, data} = this.state;
        let chartDef = [
            {
                color: "green",
                dataKey: "weight",
                name: this.props.t('weight'),
                valueConverter: this.weightValueConverter,
                unit: getUnit("weight", UnitTypes.MEDIUM)
            }
        ];
        return (
            <React.Fragment>
                <Loading isLoading={loading}/>
                {
                    data.length === 0 && <NotFound/>
                }
                {
                    data.length > 0 &&
                    <>
                        <Chart dataDef={chartDef} data={this.state.data} Yaxis={{
                            name: this.props.t('weight')
                        }} Xaxis={{
                            name: this.props.t("date"),
                            dataKey: "name"
                        }} saveAsExcell={`rfid_${this.props.RFID}`}/>
                    </>
                }
            </React.Fragment>
        );
    }

}

RFIDChart.propTypes = {
    cage: PropTypes.object,
    RFID: PropTypes.string.isRequired,
    settlement: PropTypes.shape({
        DtaStartTime: PropTypes.number.isRequired,
        DtaEndTime: PropTypes.number,
    }),
};

RFIDChart = connect(state => ({
    cageData: state.aggregatedData.data,
}))(RFIDChart)

export default withTranslation()(RFIDChart);
