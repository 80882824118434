import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {FeedingType} from "../../../../constans/feedingTypes";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import Dispenser from "./Dispenser";
import Button from "../../../basics/button/Button";
import {feedingSelect, feedingUnselectAll} from "../../../../actions/feedingActions";
import {getSelectedDispensers} from "../../../../utils/FeedingUtils";
import {ModalName as FeedingManageFeedingModal} from "../../../modals-new/feeding-manage-feeding/FeedingManageFeedingModal";
import {ModalName as FeedingSetAlarmModal} from "../../../modals-new/feeding-set-alarm/FeedingSetAlarmModal";
import {SetLock} from "../mini-components/FeedingConfirmModals";
import {ReportErrorType} from "../../../../constans/reportErrorTypes";
import {sendReport} from "../../../../utils/ReportErrorUtils";
import {ErrorFallback} from "../../../basics/error-fallback/ErrorFallback";
import inseminationSvg from "../../../../resources/images/events/insemination.svg"
import parturitionSvg from "../../../../resources/images/events/parturition.svg"
import {SectorType} from "../../../../constans/sectorTypes";
import {get, isEqual, isFinite, isFunction, isNil, omit} from "lodash";
import ListItem from "../../../basics/list/list-item/ListItem";
import {ExtraButtons} from "../mini-components/MiniComponents";
import {checkIfUserIsService} from "../../../../utils/NewRolesUtils";
import DebugDispenser from "../mini-components/DebugDispenser";
import Hammer from "hammerjs"
import {getStyleValue} from "../../../../utils/DOMUtils";
import {ModalName as FeedingReportInseminationModal} from "../../../modals-new/feeding-report-insemination/FeedingReportInseminationModal";
import {ModalName as FeedingReportParturitionModal} from "../../../modals-new/feeding-report-parturition/FeedingReportParturitionModal";
import {ModalName as FeedingSowInfoModal} from "../../../modals-new/feeding-sow-info/FeedingSowInfo";
import {ModalName as AssignAnimalToStandingModal} from "../../../modals-new/assign-animal-to-standing-modal/AssignAnimalToStandingModal";
import {ModalName as FeedingHistoryModal} from "../../../modals-new/feeding-history/FeedingHistoryModal";
import {withTranslation} from "react-i18next";
import {getLicenseByName} from "../../../../selectors/roleSelector";
import {LicPackageKeys} from "../../../../constans/roles";
import {getFeedingLevels, getFeedingPunishmentSettings, isUsingFakeData} from "../../../../utils/SettingsUtils";
import i18next from "i18next";
import DispenserNRF from "../../../../beans/devices/DispenserNRF";
import {fakeTransfer} from "../../../../demo/feeding/fakeFunctions";
import {getColorByName} from "../../../../utils/ColorUtils";


// const mobileButtonProps = {
//     buttonColor: "info",
//     className: "w-100 h-100"
// };

const Issue = {
    LOADING: 0,
    NOT_ASSIGNED: 1,
    NOT_CONFIGURED: 2,
    NOT_CONNECTED: 3
};

export class DispenserAdapter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorCode: false,
            showButtons: !this.isScrolling
        };
        this.itemRef = React.createRef();
        this.currentVal = 0;
        this.lastEvent = "";
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.showButtons && !nextProps.isScrolling) {
            return {
                showButtons: true
            }
        }
        return null;
    }

    handleSwipe = (event) => {
        console.log(event.type, this.lastEvent, event.angle, event.deltaX);
        if (this.props.mobile) {
            const maxLeft = -get(this.itemRef.current.getElementsByClassName("extra-buttons"), "[0].clientWidth", 0);
            const maxRight = get(this.itemRef.current.getElementsByClassName("extra-debug"), "[0].clientWidth", 0);
            const angle = Math.abs(event.angle);
            const inRange = angle > 160 || angle < 20;
            let newValue;
            switch (event.type) {
                case "panstart": {
                    this.itemRef.current.classList.remove("animate-transform-x");
                    this.currentVal = parseInt(getStyleValue(this.itemRef.current.style.transform) || 0, 10);
                    this.lastEvent = event.type;
                    break;
                }
                case "panright":
                case "panleft": {
                    if (inRange) {
                        newValue = this.currentVal;
                        this.lastEvent = event.type;
                        newValue += event.deltaX;
                    }
                    break;
                }
                case "panend": {
                    if (["panleft", "panright"].includes(this.lastEvent)) {
                        newValue = parseInt(getStyleValue(this.itemRef.current.style.transform) || 0, 10);
                        this.itemRef.current.classList.add("animate-transform-x");
                        const swipeToTrigger = (-maxLeft > maxRight ? maxRight : -maxLeft) / 5;
                        if (this.lastEvent === "panleft") {
                            newValue = newValue < -swipeToTrigger ? maxLeft : 0;
                        }
                        if (this.lastEvent === "panright") {
                            newValue = newValue > swipeToTrigger ? maxRight : 0;
                        }
                    }
                    this.lastEvent = event.type;
                    break;
                }
                default:
                    break;
            }
            if (!isNil(newValue)) {
                newValue = Math.max(newValue, maxLeft);
                newValue = Math.min(newValue, maxRight);
                this.itemRef.current.style.transform = `translateX(${newValue}px)`;
            }
        } else {
            this.itemRef.current.style.transform = '';
        }


    };

    componentDidMount() {
        if (this.props.mobile) {
            this.initHammer();
        }
    }

    initHammer = () => {
        this.destroyHammer();
        if (!this.hammer && this.itemRef.current) {
            this.hammer = new Hammer(this.itemRef.current);
            this.hammer.on("panstart panright panleft panend", this.handleSwipe)
        }
    };

    destroyHammer = () => {
        if (this.hammer) {
            this.hammer.destroy();
            this.hammer = null;
            this.itemRef.current.style.transform = '';
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mobile !== prevProps.mobile) {
            if (this.props.mobile) {
                this.initHammer();
            } else {
                this.destroyHammer();
            }
        }
        if (isUsingFakeData()) {
            try {
                const {data: {id, animal: prevAnimal = {}} = {}} = prevProps;
                const {data: {animal = {}} = {}} = this.props;
                if (prevAnimal.AnmID !== animal.AnmID) {
                    fakeTransfer(id);
                }
            } catch (e) {
                console.error(e)
            }

        }
    }

    componentWillUnmount() {
        this.destroyHammer();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!isEqual(this.state, nextState)) return true;
        if (nextProps.index !== this.props.index) return true;
        if (nextProps.sectorType !== this.props.sectorType) return true;
        if (nextProps.mobile !== this.props.mobile) return true;
        if (nextProps.selected !== this.props.selected) return true;
        if (nextProps.loading !== this.props.loading) return true;
        if (nextProps.location !== this.props.location) return true;
        if (nextProps.scheduleMap !== this.props.scheduleMap) return true;
        if (nextProps.curveMap !== this.props.curveMap) return true;
        if (nextProps.forageMap !== this.props.forageMap) return true;
        if (nextProps.data.modTime !== this.props.data.modTime) return true;
        if (nextProps.data.id !== this.props.data.id) return true;
        if (JSON.stringify(nextProps.style) !== JSON.stringify(this.props.style)) return true;
        return false;
    }


    getBlurComponent = (type = Issue.LOADING) => {
        const {t} = this.props;
        const buttonProps = {type: "button", buttonStyle: "text", stopPropagation: true, buttonColor: "info"};

        switch (type) {
            case Issue.NOT_ASSIGNED:
                return (
                    <span>
                        <strong>{t("deviceRows.feeding.dispenserRow.notAssigned")}</strong>
                    </span>
                );
            case Issue.NOT_CONFIGURED:
                return (
                    <span>
                        <strong className={"mr-1"}>{t("configureCurveFeeding")}</strong>
                        <Button
                            {...buttonProps}
                            onClick={() => this.openModal(FeedingManageFeedingModal)}
                            icon={<i className="fas fa-cog"/>}
                        />
                    </span>
                );
            case Issue.NOT_CONNECTED:
                return (
                    <span>
                        <strong>{t("notConnectedDevice")}</strong>
                    </span>
                );
            case Issue.LOADING:
                return (
                    <span>
                        <strong className={"loading-animation"}>{t("deviceRows.feeding.dispenserRow.loading")}</strong>
                    </span>
                );
            default:
                return null
        }
    };

    componentDidCatch(error, info) {
        const errCode = sendReport(ReportErrorType.FEEDING, error);
        this.setState({
            isError: true,
            errCode: errCode
        })
    }

    openModal = (modalName) => {
        const {show, data, location: {CID}} = this.props;
        const values = getSelectedDispensers(CID, [data]);
        show(modalName, {
            type: values.feedingType,
            selectedNodes: values.selectedNodes,
            receivers: values.receivers,
            locationID: values.chamberID,
            sectorType: values.sectorType
        })
    };

    clearSelection = () => {
        const {dispatch, location: {CID}} = this.props;
        dispatch(feedingUnselectAll(CID));
    };


    /**
     * Metoda do wołania modala z potwierdzeniem zgloszenia porodu
     */
    handleReportParturition = () => {
        this.openModal(FeedingReportParturitionModal);
    };

    /**
     * Metoda do wołania modala z potwierdzeniem zgloszenia inseminacji
     */
    handleReportInsemination = () => {
        this.openModal(FeedingReportInseminationModal);
    };

    handleToggleLock = () => {
        const {t, show, data, data: {locked}, location: {CID}} = this.props;
        const {feedingType, receivers, selectedNodes} = getSelectedDispensers(CID, [data]);
        SetLock({
            show,
            t,
            feedingType,
            status: !locked,
            receivers,
            selectedNodes,
            onConfirmed: this.clearSelection
        })
    };

    handleManageFeeding = () => {
        this.openModal(FeedingManageFeedingModal)
    };

    handleAlarmModal = () => {
        this.openModal(FeedingSetAlarmModal)
    };

    handleInfo = () => {
        this.openModal(FeedingSowInfoModal)
    };

    handleHistory = () => {
        this.openModal(FeedingHistoryModal);
    };

    handleAssign = () => {
        const {show, data, data: {animal, box, receiver}, location} = this.props;
        const {sectorType} = getSelectedDispensers(location.CID, [data]);
        show(AssignAnimalToStandingModal, {
            standing: {
                id: box.BID,
                animal: animal,
                device: receiver ? {
                    DevID: receiver.deviceId,
                    GatewayID: receiver.gatewayId,
                    Index: receiver.index
                } : undefined,
                feedParam: {
                    curveNr: get(data, "curve.number", 0),
                    stage: get(data, "curve.eventStage", 0),
                    startTime: get(data, "curve.start", +new Date()),
                    isConfigured: !!get(data, "curve.number", 0)
                }
            },
            chamber: location,
            sectorType
        });
    };

    getButtons = () => {
        const {sectorType, location: {IndividualFeeding}, data: {animal, connected, locked}} = this.props;
        if (!connected) {
            return [];
        }
        const buttons = [
            {
                action: this.handleManageFeeding,
                icon: "fas fa-cog"
            }
        ];
        if (IndividualFeeding) {
            buttons.push(...[
                {
                    action: this.handleToggleLock,
                    icon: locked ? "fas fa-play" : "fas fa-pause"
                },
                {
                    action: this.handleAlarmModal,
                    icon: "fas fa-bell"
                }
            ]);
            // od jakiegos czasu zgloszenie porodu to nie jest zgloszenie porodu wiec trzeba odblokowac zawsze mozliwosc zmiany
            // if (!!insemination) {
                if (sectorType === SectorType.MATING) {
                    buttons.push({
                        action: this.handleReportInsemination,
                        iconSvg: inseminationSvg
                    })
                }
                if (sectorType === SectorType.DELIVERY) {
                    buttons.push({
                        action: this.handleReportParturition,
                        iconSvg: parturitionSvg
                    })
                }
            // }
        }
        if (!IndividualFeeding || !!animal) {
            buttons.push({
                action: this.handleInfo,
                icon: "fas fa-info"
            });

        }
        if (IndividualFeeding) {
            buttons.push({
                action: this.handleAssign,
                icon: !!animal ? "fas fa-exchange" : "fas fa-plus"
            });
        }
        buttons.push({
            action: this.handleHistory,
            icon: "fas fa-chart-bar"
        });
        return buttons;
    };

    handleOnClick = () => {
        const {dispatch, location, data: {id, feed}} = this.props;
        if (feed) {//enable select on shadow
            dispatch(feedingSelect(location, id));
        }
    };

    handleContextMenu = (e) => {
        const {dispatch, location, data: {id, feed}, selected, setEvent} = this.props;
        if (feed && !selected) {//enable select on shadow
            dispatch(feedingSelect(location, id));
        }
        if (feed && isFunction(setEvent)) {
            setEvent(e);
        }
    };

    render() {
        const {isError, errCode, showButtons} = this.state;
        const {style} = this.props;
        if (isError) {
            return (
                <ErrorFallback style={style} errCode={errCode}/>
            )
        }
        const values = this.getValues(this.props);
        const {selected, index, location, data = {}, mobile} = this.props;
        const {blur, blurText} = values;
        const id = location.IndividualFeeding ? `stand_${data.id}` : `rfid_${data.id}`;
        const cName = ["device-item", blur ? "inactive" : "", mobile ? "mobile" : ""].filter(o => !!o).join(" ");
        const ateEnough = blur ? true : getFeedingLevels().FeedingMinimumLevel < ((values.feed.usage || 0) / (values.feed.plannedUsage || 1))
        let newStyle = style;
        if (!ateEnough) {
            newStyle = {...newStyle, background: `${getColorByName("error")}10`}
        }
        return (
            <ListItem lp={index + 1} index={index}
                      style={newStyle} selected={selected}
                      className={cName}
                      id={id}
                      itemRef={this.itemRef}
                      onClick={this.handleOnClick} onContextMenu={this.handleContextMenu}>
                {
                    !!blur && !!blurText &&
                    <div className={"text-absolute override-inactive"}>
                        {blurText}
                    </div>
                }
                <Dispenser {...omit(values, ["blur", "blurText"])}/>
                {
                    !!showButtons &&
                    <>
                        <ExtraButtons mobile={mobile} buttons={this.getButtons()}/>
                        {
                            !!checkIfUserIsService() && !!data.connected && !!location.IndividualFeeding &&
                            <DebugDispenser chamberId={location.CID} data={data} mobile={mobile}/>
                        }
                    </>
                }
            </ListItem>
        )

    }

    getValues = ({license, curveMap, forageMap, scheduleMap, index, unit, location = {}, data = {}, data: {dateTime, loop = {}, forage = {}, errors = [], efficiency, waterEfficiency, waterDoseType, waterHistory, additionalFeeding = [], feed = {}, curve = {}, schedule = {}, lastSeen = {}} = {}, mobile, loading}) => {
        const notConfigured = !!(data.curve && data.curve.number === 0 && data.connected && location.IndividualFeeding);
        const notConnected = !data.connected;
        const isLoading = !data.connected && loading;
        let punishmentOptions = {};
        if (curve.punishment > 0) {
            punishmentOptions = getFeedingPunishmentSettings()[curve.punishment - 1] || {};
        }
        const debugText = () => {
            let text = "";
            if (location.IndividualFeeding) {
                if (data.device) {
                    try {
                        text = data.device.getDebugName(data.receiver ? data.receiver.index : null)
                    } catch (e) {
                        text = data.device.DevID
                    }
                }
                return text;
            } else {
                return null;
            }
        };
        const now = +new Date();
        const hasWater = data.device instanceof DispenserNRF ? data.device.hasWater() : false;
        const hasTemperature = data.device instanceof DispenserNRF ? data.device.hasTemperatureSensor() && data.device.isIndividualTemperature() : false;
        const hasFlowMeter = data.device instanceof DispenserNRF ? data.device.hasFlowMeter() : false;
        return {
            hasWater: hasWater,
            hasFlowMeter: hasFlowMeter,
            temperature: {
                temperature: data.temperature,
                hasTemperature,
            },
            water: {
                hasWater,
                hasFlowMeter,
                history: waterHistory || [],
                efficiency: waterEfficiency || 0,
                type: waterDoseType || 0
            },
            modTime: data.modTime,
            efficiency,
            curveMap: curveMap,
            forageMap: forageMap,
            license,
            scheduleMap: scheduleMap,
            mobile: !!mobile,
            // buttons: buttons,
            debugFixedText: debugText(),
            receiver: data.receiver,
            animal: data.animal,
            dateTime: dateTime,
            rowIndex: index,
            connected: data.connected,
            punishmentOptions,
            loading: !!((!location.IndividualFeeding || data.receiver) && isLoading),
            curve: {
                number: curve.number,
                day: curve.day,
                punishment: curve.punishment,
                correction: curve.correction,
                eventStage: curve.eventStage,
                id: curve.id,
            },
            name: data.name,
            schedule: {
                id: schedule.id
            },
            running: !!data.running,//czy dozownik jest wlaczony
            skipDoses: data.skipDoses || [],
            lastWeekHistory: data.lastWeekHistory || [],
            lastSeen: {
                time: isFinite(lastSeen.time) ? lastSeen.time > now ? now : lastSeen.time : undefined, //zabezpieczenie zeby nie wyswietlalo sie za 1 min bedzie karmiona
                deviceId: isNil(lastSeen.deviceId) ? undefined : lastSeen.deviceId
            },
            feed: {
                usage: feed.usage,
                plannedUsage: feed.plannedUsage,
                additional: additionalFeeding.reduce((a, b) => a + (b.success ? b.dose : 0), 0)
            },
            additional: additionalFeeding,
            blur: isLoading || notConnected || notConfigured,
            blurText: this.getBlurComponent(location.IndividualFeeding ? ((isLoading && data.receiver) ? Issue.LOADING : data.receiver ? (notConnected ? Issue.NOT_CONNECTED : Issue.NOT_CONFIGURED) : Issue.NOT_ASSIGNED) : isLoading ? Issue.LOADING : Issue.NOT_CONFIGURED),
            errors: (errors || []).map(code => i18next.t([`notificationsCenter.errorCodes.S${code}`, `notificationsCenter.errorCodes.${code}`], {name: data.name}) || code),
            feedingType: location.IndividualFeeding ? FeedingType.INDIVIDUAL : FeedingType.GROUP,
            forage,
            workType: data.workType,
            loop: {
                insemination: loop.insemination || 0,
                parturition: 0, //przerzucamy sie na ustawienia eventow tylko w jednym miejscu
                endDay: loop.endDay || 0
            },
            id: location.IndividualFeeding ? `stand_${data.id}` : `rfid_${data.id}`,
            correctLocationId: location.IndividualFeeding ? get(data, 'box.BID') : location.CID,
            feeding: data.feeding,
            locked: data.locked,
            alert: data.alert,
            unit: unit
        };
    }


}


DispenserAdapter.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    style: PropTypes.object,
    index: PropTypes.number.isRequired,
    setEvent: PropTypes.func
};

DispenserAdapter = connect((state, props) => ({
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))(DispenserAdapter);

DispenserAdapter = connect(
    null,
    dispatch => ({
        ...bindActionCreators({show}, dispatch),
        dispatch
    })
)(DispenserAdapter);
export default withTranslation()(DispenserAdapter);


