import React from "react";
import Card from "../basics/card/Card";
import PropTypes from "prop-types"
import Button from "../basics/button/Button";
import {withTranslation} from "react-i18next";

export class DeviceSettingsCard extends React.Component {

    onClick = () => {
        this.props.onButtonClick();
    };

    render() {
        const {title, children, disabled} = this.props;
        return (
            <Card className="device-settings-card">
                <h5 className="flex-lg-row justify-content-between align-content-center">
                    {title}
                    <Button disabled={disabled} buttonStyle={"round"} className={"text"} buttonColor={"success"} icon={<i className="fas fa-save"/>}
                            onClick={this.onClick}/>
                </h5>
                {children}
            </Card>
        );
    }

}

DeviceSettingsCard.propTypes = {
    title: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default withTranslation()(DeviceSettingsCard);