import React, {Component} from 'react';
import {connect} from 'react-redux';
import NewIOT from "../../../IOT/NewIOT";
import {groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";
import sinon from "sinon";
import {fakeCreateAndSendMessageObject, fakeStartSendingDeviceState,} from "../../../demo/silo/fakeFunctions";
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import SiloRow from "./SiloRow";
import VirtualizedList from "../../basics/virtualized-list/VirtualizedList";
import NoDataFoundLoading from "../../no-data-found-farm-view/NoDataFoundLoading";
import {withTranslation} from "react-i18next";
import {Col} from "react-bootstrap";
import {getScaleData} from "../../../selectors/device-rows/siloSelector";

function mapStateToProps(state) {
    return {
        data: getScaleData(state),
        loading: state.dataLoader.loading
    };
}

class SiloList extends Component {

    messageIDs = [];

    constructor(props) {
        super(props);
        if (isUsingFakeData()) {
            sinon.restore();
            sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);
            sinon.stub(NewIOT, "sendRequestForAggregatedData").callsFake(() => {
            });
        }
    }

    componentDidMount() {
        this.startPolling();
    }

    startPolling() {
        NewIOT.removeFromInterval(this.messageIDs);
        const {data} = this.props;
        const deviceGroups = groupDevicesByGatewayID(data.map(o => o.device));
        if (deviceGroups) {
            for (let groups of deviceGroups.values()) {
                if (groups[DevType.SCALE].length) {
                    this.messageIDs.push(NewIOT.startSendingDeviceState(groups[DevType.SCALE], undefined, undefined, {keepAlive: true}));
                }
            }
        }
    }

    componentWillUnmount() {
        NewIOT.removeFromInterval(this.messageIDs)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {data} = this.props;
        const {data: prevData} = prevProps;
        if (data.length !== prevData.length) {
            this.startPolling();
        }
    }

    noRowsRenderer = () => {
        const {loading, data, t} = this.props;
        return (
            <Col xs={12}>
                <NoDataFoundLoading isLoading={loading} dataLength={data.length}
                                    noDataText={t("deviceRows.siloses.siloRow.silosesNotFound")}/>
            </Col>

        )
    }


    render() {
        const {data, openMenu, loading} = this.props;
        return (
            <VirtualizedList
                forceRerender={loading}
                noRowsRenderer={this.noRowsRenderer}
                passProps={{openMenu}}
                component={() => SiloRow}
                data={data}
            />
        );
    }
}

SiloList = connect(
    mapStateToProps,
)(SiloList);

export default withTranslation()(SiloList)
