import React from "react";
import Input from "../../../basics/input/Input";
import {feedingFilter} from "../../../../actions/feedingActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };
        this.timer = null;
    }

    setText = (newValue) => {
        const {dispatch, chamber} = this.props;
        this.setState({
            text: newValue || ""
        }, () => {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                dispatch(feedingFilter(chamber, newValue));
                this.timer = null;
            }, 200);
        })
    };

    render() {
        const {text} = this.state;
        const {t} = this.props;
        return (
            <div>
                <Input placeholder={t("filter")} onChange={this.setText}
                       value={text}/>
            </div>
        )
    }

}

SearchBox = withTranslation()(SearchBox);

export default connect()(SearchBox);