import React, {Component} from 'react';
import {feedingSelectAll, feedingUnselectAll} from "../../../../actions/feedingActions";
import {Col, Row} from "react-bootstrap";
import Button from "../../../basics/button/Button";
// import SettlementButton from "../mini-components/SettlementButton";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

@connect()
class SimpleActions extends Component {

    selectAll = () => {
        const {dispatch, chamber} = this.props;
        dispatch(feedingSelectAll(chamber));
    };

    unselectAll = () => {
        const {dispatch, chamber} = this.props;
        dispatch(feedingUnselectAll(chamber));
    };

    render() {
        const {t} = this.props;
        return (
            <Col md={12}>
                <Row>
                    {/*<Col xs={6} md={4}>*/}
                    {/*    <SettlementButton sectorType={sectorType} chamber={chamber}/>*/}
                    {/*</Col>*/}
                    <Col xs={6} md={6}>
                        <Button title={t("deviceRows.feeding.dispenserChamber.select")} type={"button"}
                                className={"w-100"} onClick={this.selectAll}><i
                            className="far fa-check-square d-lg-none"/><span
                            className={"d-none d-lg-inline"}>{t("deviceRows.feeding.dispenserChamber.select")}</span></Button>
                    </Col>
                    <Col xs={6} md={6}>
                        <Button title={t("deviceRows.feeding.dispenserChamber.unselect")} type={"button"}
                                className={"w-100"} onClick={this.unselectAll}><i
                            className="far fa-square d-lg-none"/><span
                            className={"d-none d-lg-inline"}>{t("deviceRows.feeding.dispenserChamber.unselect")}</span></Button>
                    </Col>

                </Row>
            </Col>

        );
    }
}

export default SimpleActions = withTranslation()(SimpleActions);

