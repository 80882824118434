import React from "react";
import Tooltip from "../../../basics/tooltip/Tooltip";
import {FeedingType, NRFWorkType} from "../../../../constans/feedingTypes";
import {IntervalTimes} from "../../../../constans/intervals";
import moment from "moment";
import {get, isArray} from "lodash";
import settingsDB from "../../../../database/settingsDB";
import {getMinutesOfDay} from "../../../../utils/DateTimeUtils";
import {checkCondition} from "../../../../utils/DispenserAdapterUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";


const _Icon = ({header, extraInfo, color, icon, error, dateTime}) => (
    <Tooltip tooltipContent={
        <div>
            <div>{header}</div>
            {
                !!dateTime &&
                <div>
                    <i className="fas fa-sync mr-1"/><b>{dateTime}</b>
                </div>
            }
            {
                !!extraInfo &&
                <div className={"opacity-75"}>
                    <small><b>{extraInfo}</b></small>
                </div>
            }
            {
                isArray(error) && error.map(er => <div><small>{er}</small></div>)
            }
            {
                !isArray(error) && <div><small>{error}</small></div>
            }

        </div>} placement="auto"
             type={color}>
        <div className={`override-inactive device-main-working ${color}`}>
            <i className={icon}/>
        </div>
    </Tooltip>
);

const Icon = React.memo(_Icon);

class IconStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkTime: +new Date()
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.dateTime !== this.props.dateTime) || (nextState.checkTime !== this.state.checkTime) || (nextProps.loading !== this.props.loading)
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                checkTime: +new Date()
            })
        }, IntervalTimes.DEVICE_OUTDATED_DATA_CHECK)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getStyle({extended, curveMap, curveId, day, animal, locked, loading, alert, feeding, connected, t, receiver, workType, feedingType, dateTime = 0, scheduleMap = [], skipDoses = [], scheduleId}) {
        const chamberWorkType = feedingType === FeedingType.INDIVIDUAL ? [NRFWorkType.SCHEDULE, NRFWorkType.TRIGGER] : [NRFWorkType.RFID];
        const condition = extended ? checkCondition({curveMap, curveId, day, animal}) : false;
        const outdated = (receiver) && connected ? Math.abs(dateTime - (+new Date())) > IntervalTimes.DEVICE_OUTDATED_DATA : false;
        if (loading) {
            return {
                icon: "fas fa-fw fa-circle-notch fa-spin",
                color: "warning",
                header: <b>{t("deviceRows.feeding.dispenserRow.loading")}</b>
            }
        }
        if (connected) {
            if (outdated) {
                return {
                    icon: "fas fa-fw fa-sync",
                    color: "warning",
                    header: <b>{t("outOfSync")}</b>
                }
            }
            if (!chamberWorkType.includes(workType)) {
                return {
                    icon: "fas fa-fw fa-wrench",
                    color: "error",
                    header: <b>{t("deviceRows.feeding.dispenserRow.wrongWorkType")}</b>,
                    extraInfo: i18next.t("deviceRows.feeding.dispenserRow.isXshouldBeY", {
                        type1: t(`deviceRows.feeding.dispenserRow.workType.${workType}`),
                        type2: chamberWorkType.map(o => t(`deviceRows.feeding.dispenserRow.workType.${o}`)).join("/")
                    })
                }
            }
            if (!feeding) {
                return {
                    icon: "fas fa-fw fa-stop",
                    color: "error",
                    header: <b>{t("deviceRows.feeding.dispenserRow.stop")}</b>
                }
            }
            if (locked) {
                return {
                    icon: "fas fa-fw fa-pause",
                    color: condition ? "info" : "",
                    header: condition ?
                        <b>{t("deviceRows.feeding.dispenserRow.pause")}<br/>{t("deviceRows.feeding.dispenserRow.condition")}
                        </b> : <b>{t("deviceRows.feeding.dispenserRow.pause")}</b>
                }
            }
            if (alert) {
                return {
                    icon: "fas fa-fw fa-exclamation",
                    color: "error",
                    header: <b>{t("alertedDevice")}</b>
                };
            }
            //sprawdzenie czy jestesmy w czasie jakiejs pominietej dawki
            if (scheduleId && skipDoses.reduce((a, b) => a + b, 0)) {
                const schedule = scheduleMap.get(scheduleId) || settingsDB.getSettingByID(scheduleId);
                if (schedule) {
                    for (let i = 0; i < 6; i++) {
                        if (skipDoses[i]) {
                            const doses = get(schedule, "SetData.Doses", []);
                            if (doses[i]) {
                                const startMinutes = getMinutesOfDay(get(doses[i], "Start", 0));
                                const stopMinutes = getMinutesOfDay(get(doses[i], "Stop", 0));
                                const currentMinutes = getMinutesOfDay(+new Date());
                                if (currentMinutes >= startMinutes && currentMinutes <= stopMinutes) {
                                    return {
                                        icon: "fas fa-fw fas fa-step-forward",
                                        color: condition ? "info" : "",
                                        header: condition ?
                                            <b>{t("deviceRows.feeding.dispenserRow.skippingTime")}<br/>{t("deviceRows.feeding.dispenserRow.condition")}
                                            </b> : <b>{t("deviceRows.feeding.dispenserRow.skippingTime")}</b>
                                    }
                                }
                            }
                        }
                    }
                }
            }
            const text = feedingType === FeedingType.GROUP ? t("deviceRows.iconStatus.pigConfigured") : t("deviceRows.feeding.dispenserRow.active");
            return {
                icon: "fas fa-fw fa-cog fa-spin",
                color: condition ? "info" : "success",
                header: condition ?
                    <b>{text}<br/>{t("deviceRows.feeding.dispenserRow.condition")}
                    </b> :
                    <b>{text}</b>
            };

        }
        if (feedingType === FeedingType.INDIVIDUAL || receiver) {
            return {
                icon: receiver ? "fas fa-fw fa-wifi-slash" : feedingType === FeedingType.INDIVIDUAL ? "fas fa-fw fa-plug" : "fas fa-fw fa-wifi-slash",
                color: "warning",
                header: receiver ? <b>{t("notConnectedDevice")}</b> :
                    <b>{t("deviceRows.feeding.dispenserRow.notAssigned")}</b>
            };
        } else if (feedingType === FeedingType.GROUP) {
            return {
                icon: "fas fa-fw fa-question",
                color: "warning",
                header: <b>{t("deviceRows.feeding.dispenserRow.notYetFeed")}</b>
            };
        }

    };

    render() {
        const style = this.getStyle(this.props);
        const {errors = [], loading, dateTime, receiver, t} = this.props;
        const date = (!loading && !!receiver) ?
            dateTime ? moment(dateTime).format("DD.MM.YY HH:mm:ss") : t("noConnection")
            :
            undefined
        return (
            <>
                <Icon {...style} error={errors} dateTime={date}/>
            </>

        )
    }
}

export default withTranslation()(IconStatus);
