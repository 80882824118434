import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import WaterFlowMeterBean from "../../../beans/devices/WaterFlowMeter";
import RowComponent from "../../basics/row-component/RowComponent";
import Tooltip from "../../basics/tooltip/Tooltip";
import Checkbox from "../../basics/checkbox/Checkbox";
import IconValue from "../../basics/icon-value/IconValue";
import AvgDailyConsumption from "../../../resources/images/water-flow-meter/average_day_consumption.svg";
import DailyConsumption from "../../../resources/images/water-flow-meter/day_consumption.svg";
import TotalConsumption from "../../../resources/images/electricity-flow-meter/total_consumption.svg";
import L1 from "../../../resources/images/electricity-flow-meter/l1.svg";
import L2 from "../../../resources/images/electricity-flow-meter/l2.svg";
import L3 from "../../../resources/images/electricity-flow-meter/l3.svg";
import _ from "lodash";
import {Collapse} from "react-bootstrap";
import Select from "../../basics/select/Select";
import {ChamberSettlement, getSettlement, getSettlementList} from "../../../selectors/settlementSelector";
import {getInterfName, getLatestMetadata} from "../../../utils/DevicesUtils";
import moment from "moment";
import Button from "../../basics/button/Button";
import Input from "../../basics/input/Input";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import {stringBuilder} from "../../../utils/TextUtils";
import IconStatus from "../IconStatus";
import ElectricityHourlyChart from "../../charts/ElectricityHourlyChart";
import ElectricityDailyChart from "../../charts/ElectricityDailyChart";
import {getElectricityAggregatedDependantValues} from "../../../utils/device-rows/ElectricityFlowMeterUtils";
import {convertEnergyUnitTo, convertPowerUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {withTranslation} from "react-i18next";
import buildingsDB from "../../../database/buildingsDB";

function makeMapStateToProps() {
    const getShadowByDevice = makeGetShadowByDevice();
    return function mapStateToProps(state, props) {
        const {shadow, fetching, fetched} = getShadowByDevice(state, props.device);
        return {
            shadow,
            fetching,
            fetched,
            aggData: state.aggregatedData.data,
            settlementList: getSettlementList(state, {CID: props.CID}),
            settlement: getSettlement(state, {CID: props.CID}),
        }
    }
}

export class ElectricityFlowMeter extends React.Component {

    fetchData = _.debounce(({start = +moment.utc().startOf("day"), end} = {}) => {
        const {aggData = new Map(), device, dispatch} = this.props;
        //w sumie to nie oplaca sie pobierac pojedynczo wiec troche dni w stecz bede pobieral aby nie wysylac co chwile
        const agStart = end ? +start : +(moment.utc(start).subtract(7, "days").startOf("day"));
        const agEnd = end ? +end : +moment.utc().startOf("day");
        const diff = Math.abs(moment(agStart).diff(moment(agEnd), "days")) + 1;
        const data = aggData.has(device.DevID) ? aggData.get(device.DevID).filter(o => (o.AggTi >= agStart) && (o.AggTi <= agEnd)) : [];
        if (data.length !== diff) {
            dispatch(getAggregatedData(device, {
                AggDataTimeFrom: agStart,
                AggDataTimeTo: agEnd
            }));
        }
    }, 250);

    constructor(props) {
        super(props);
        const options = this.settlementOptions();
        this.state = {
            chart: "hourly",
            collapse: false,
            date: +moment().utc().startOf("day"),
            dateStart: options.length ? +moment.utc(options[options.length - 1].value.dateStart).startOf("day") : undefined,
            dateEnd: options.length ? +moment.utc(options[options.length - 1].value.dateEnd).startOf("day") : undefined
        }
    }

    getOptions = () => [
        {
            name: this.props.t("hourly"),
            value: "hourly"
        },
        {
            name: this.props.t("daily"),
            value: "daily"
        }
    ];

    initialFetch = () => {
        const {settlementList} = this.props;
        const range = {
            start: settlementList.length ? +moment(settlementList[settlementList.length - 1].DtaStartTime).utc().startOf("day") : +moment().utc().startOf("day").subtract(7, "days"),
            end: +moment().utc().startOf("day")
        };
        this.fetchData({...range});
    };

    componentDidMount() {
        this.initialFetch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.dataFetcher(prevState);
        const options = this.settlementOptions(this.props);
        const oldOptions = this.settlementOptions(prevProps);
        //ktos zmienil zasiedlenie
        if (options.length !== oldOptions.length) {
            this.initialFetch();
            this.setState({
                dateStart: options.length ? +moment.utc(options[options.length - 1].value.dateStart).startOf("day") : undefined,
                dateEnd: options.length ? +moment.utc(options[options.length - 1].value.dateEnd).startOf("day") : undefined
            })
        }
    }

    dataFetcher = (prevState = {}) => {
        const {chart, date, dateStart, dateEnd} = this.state;
        if (["hourly"].includes(chart)) {
            if (date && !_.isEqual(prevState.date, date)) {
                this.fetchData({start: date});
            }
        } else if (["daily"].includes(chart)) {
            if ((dateStart && dateEnd) && (!_.isEqual(prevState.dateStart, dateStart) || !_.isEqual(prevState.dateEnd, dateEnd))) {
                this.fetchData({start: dateStart, end: dateEnd});
            }
        }
    };

    getErrors = ({shadow = {}}) => {
        const errors = shadow.errors || [];
        const {t} = this.props;
        return errors.map(code => t(`notificationsCenter.errorCodes.${code}`) || code);
    };

    getValues = ({t, aggData, settlementList, shadow = {}, fetching, fetched, device: {DevID, Settings: {FlowValueAlertLimit}}}) => {
        const errors = this.getErrors({shadow});
        const range = {
            start: settlementList.length ? +moment(settlementList[settlementList.length - 1].DtaStartTime).utc().startOf("day") : +moment().utc().startOf("day").subtract(7, "days"),
            end: settlementList.length && _.isFinite(settlementList[settlementList.length - 1].DtaEndTime) ? +moment(settlementList[settlementList.length - 1].DtaEndTime).utc().startOf("day") : undefined
        };
        const aggValues = getElectricityAggregatedDependantValues({
            aggData: aggData.get(DevID),
            shadow,
            range
        });
        return {
            ...aggValues,
            errors: errors,
            settlementConsumption: this.energyFormatter(aggValues.settlementConsumption),
            averageDailyConsumption: t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.usageXday", {
                count: convertEnergyUnitTo(aggValues.averageDailyConsumption ? aggValues.averageDailyConsumption : 0, {
                    showUnit: true,
                    unit: UnitTypes.MEDIUM,
                    fixed: 2
                })
            }),
            dailyConsumption: this.energyFormatter(aggValues.dailyConsumption),
            totalConsumption: this.energyFormatter(shadow.totalActiveEnergyConsumption),
            l1: this.powerFormatter(shadow.activePowerConsumptionL1),
            l2: this.powerFormatter(shadow.activePowerConsumptionL2),
            l3: this.powerFormatter(shadow.activePowerConsumptionL3),
            isAlerted: !!errors.length,
            isConnected: !!fetched,
            isLoading: !!fetching,
            dateTime: getLatestMetadata(shadow.metadata),

        }
    };

    handleSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {device, onSelect} = this.props;
        if (_.isFunction(onSelect)) {
            onSelect(device);
        }
    };

    handleChartChange = (newValue) => {
        this.setState({
            chart: newValue
        })
    };

    handleCollapseToggle = () => {
        this.setState(state => ({
            collapse: !state.collapse
        }))
    };

    getName = () => {
        const {name, device: {Address, Alias, Name, Interface}, showAlias} = this.props;
        if (showAlias && Alias) {
            return Alias;
        }
        if (name) {
            return name;
        }
        if (_.isNil(Address)) {
            return Name
        }
        return `${Address}/0x${Address.toString(16)} ${getInterfName(Interface)}`
    };

    getSubtitle = () => {
        const {CID, settlement, t} = this.props;
        let placement = buildingsDB.getLocationByID(CID);
        if (!!placement && placement.BgID) {
            return "-";
        }
        if (_.isNil(CID)) {
            return t("noChamber");
        }
        if (settlement.state === ChamberSettlement.NO_SHOW) {
            return "-";
        }
        if (settlement.state === ChamberSettlement.SHOW_SETTLEMENT) {
            return t("waitingForSettlement")
        }
        if (settlement.state === ChamberSettlement.SHOW_SEPARATION) {
            return t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.dayX", {count: 1 + moment().startOf("day").diff(moment(settlement.data.DtaStartTime).startOf("day"), "days")});
        }
    };

    handleSettlementChange = (settlement = {}) => {
        this.setState({
            ...settlement
        })
    };

    handleDayChange = (newValue) => {
        if (newValue) {
            const value = moment.utc(newValue, moment.HTML5_FMT.DATETIME_LOCAL).startOf("day");
            if (value.isBefore(moment.utc())) {
                this.setState({
                    date: value
                });
            }
        }
    };

    renderDaySelect = (name) => {
        const {date} = this.state;
        const nextDate = moment.utc(date).clone().add(1, "day").format(moment.HTML5_FMT.DATETIME_LOCAL);
        const prevDate = moment.utc(date).clone().subtract(1, "day").format(moment.HTML5_FMT.DATETIME_LOCAL);
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    <div className="chart-controls-date">
                        <Button icon={<i className="fas fa-chevron-left"/>}
                                onClick={() => this.handleDayChange(prevDate)}/>
                        <Input type="date" value={moment.utc(date).format("YYYY-MM-DD")}
                               onChange={this.handleDayChange}/>
                        <Button icon={<i className="fas fa-chevron-right"/>}
                                onClick={() => this.handleDayChange(nextDate)}/>
                    </div>
                </div>
            </div>
        )
    };

    settlementOptions = (props = this.props) => {
        const {settlementList} = props;
        return settlementList.map((insertion, index) => ({
            name: insertion.DtaEndTime ? `${moment.utc(insertion.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(insertion.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(insertion.DtaStartTime).format("DD.MM.YYYY")} - ...`,
            value: {
                dateStart: +moment(insertion.DtaStartTime).utc().startOf("day"),
                dateEnd: +moment(insertion.DtaEndTime).utc().startOf("day")
            }
        }));
    };

    renderSettlementSelect = (name) => {
        const {dateStart, dateEnd} = this.state;
        const options = this.settlementOptions();
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    <Select value={{dateStart, dateEnd}} options={options} onChange={this.handleSettlementChange}/>
                </div>
            </div>
        )
    };

    energyFormatter = (value, fixed = 0, _string = "{1}") => {
        return _.isFinite(value) ? stringBuilder(_string, convertEnergyUnitTo(value, {
            showUnit: true,
            unit: UnitTypes.MEDIUM,
            fixed: fixed
        }), 1) : "-"
    };

    powerFormatter = (value, fixed = 1, _string = "{1}") => {
        return _.isFinite(value) ? stringBuilder(_string, convertPowerUnitTo(value, {
            showUnit: true,
            unit: UnitTypes.MEDIUM,
            fixed: fixed
        }), 1) : "-"
    };

    render() {
        const {fetched, t, device, device: {DevID, Number, Settings: {Phases = 1}}, selected} = this.props;
        const {l1, l2, l3, settlementConsumption, dateTime, errors, isConnected, isLoading, isAlerted, averageDailyConsumption, dailyConsumption, totalConsumption} = this.getValues(this.props);
        const {chart, collapse, date, dateStart, dateEnd} = this.state;
        const options = this.getOptions();
        const name = this.getName();
        const subtitle = this.getSubtitle();
        return (
            <RowComponent className={collapse ? "device-item opened" : "device-item"}

                          showWorking isError={!fetched}>
                <div className="device-info" onClick={this.handleCollapseToggle}>
                    <IconStatus dateTime={dateTime} isConnected={isConnected} isAlerted={isAlerted}
                                isLoading={isLoading}>
                        {({icon, color, header}) => (
                            <div className={`device-main dev-cl ${color}`}>
                                <div className="device-main-location" title={name}>{name}</div>
                                <div className="device-main-item">{settlementConsumption}</div>
                                <div className="device-main-item-alternate" title={subtitle}>{subtitle}</div>
                                <Tooltip tooltipContent={<div>
                                    <b>{header}</b>
                                    {
                                        !!dateTime &&
                                        <div><i
                                            className="fas fa-sync mr-1"/><b>{moment(dateTime).format("DD.MM.YY HH:mm")}</b>
                                        </div>
                                    }
                                    <div>
                                        {errors.join(", ")}
                                    </div>
                                </div>}
                                         placement={"auto"}
                                         type={color}>
                                    <div className="device-main-working">
                                        <i className={icon}/>
                                    </div>
                                </Tooltip>
                                <div onClick={this.handleSelect} className="device-main-checkbox show-on-small-device sm">
                                    <Checkbox label={""} id={DevID} readOnly checked={selected}/>
                                </div>
                            </div>
                        )}
                    </IconStatus>

                    <div className="device-info-main">
                        <div className="device-info-main-second">
                            <div className="device-info-main-second-name">{Number || "-"}</div>
                            <IconValue icon={AvgDailyConsumption}
                                       name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.averageConsumptionPerDay")}
                                       value={averageDailyConsumption}/>
                            <IconValue icon={DailyConsumption}
                                       name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.electricityConsumedToday")}
                                       value={dailyConsumption}/>
                            <IconValue icon={TotalConsumption}
                                       name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.currentMeterState")}
                                       value={totalConsumption}/>
                            <IconValue icon={L1}
                                       name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.currentVoltageX", {type: "L1"})}
                                       value={l1}/>
                            {
                                (Phases === 3) &&
                                <>
                                    <IconValue icon={L2}
                                               name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.currentVoltageX", {type: "L2"})}
                                               value={l2}/>
                                    <IconValue icon={L3}
                                               name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.currentVoltageX", {type: "L3"})}
                                               value={l3}/>
                                </>
                            }
                            {
                                (Phases !== 3) &&
                                <>
                                    <div className="fetura-icon-value"/>
                                    <div className="fetura-icon-value"/>
                                </>
                            }


                        </div>
                    </div>
                    <div onClick={this.handleSelect} className="device-info-checkbox hide-on-small-device sm">
                        <Checkbox label={""} id={DevID} readOnly checked={selected}/>
                    </div>
                </div>
                <Collapse in={collapse} unmountOnExit onEntered={this.dataFetcher}>
                    <div className="chart-container">
                        <Select value={chart} options={options} onChange={this.handleChartChange} clearButton={false}/>
                        <div className="device-chart">
                            {
                                chart === "hourly" &&
                                <>
                                    {this.renderDaySelect(t("hourly"))}
                                    <div className="chart-main-container">
                                        <ElectricityHourlyChart device={device} date={date} showExcelButton/>
                                    </div>
                                </>
                            }
                            {
                                chart === "daily" &&
                                <>
                                    {this.renderSettlementSelect(t("daily"))}
                                    <div className="chart-main-container">
                                        <ElectricityDailyChart device={device} dateStart={dateStart} dateEnd={dateEnd}
                                                               showExcelButton/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Collapse>
            </RowComponent>
        );
    }

}

ElectricityFlowMeter.propTypes = {
    device: PropTypes.instanceOf(WaterFlowMeterBean).isRequired
};

ElectricityFlowMeter = connect(
    makeMapStateToProps
)(ElectricityFlowMeter);

export default withTranslation()(ElectricityFlowMeter);
