import React from "react";
import {submit as extraFeedingSubmit} from "../../../modals-new/feeding-extra-feeding/FeedingExtraFeedingSubmit";
import {submit as alarmSubmit} from "../../../modals-new/feeding-set-alarm/FeedingSetAlarmSubmit";
import PropTypes from "prop-types"
import Button from "../../../basics/button/Button";
import {getSelectedDispensers} from "../../../../utils/FeedingUtils";
import _ from "lodash";
import {_setLock} from "./FeedingConfirmModals";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const coreButtonProps = {
    type: "button",
    stopPropagation: true
};

const coreMobileButtonProps = {
    stopPropagation: true,
    type: "button",
    buttonColor: "info"
};

@connect()
class DebugDispenser extends React.Component {

    callSubmit = (fn, values = {}, extraProps = {}) => {
        const {chamberId, data} = this.props;
        const tmp = getSelectedDispensers(chamberId, [data]);
        const defaultProps = {
            cookies: new Map(),
            handleHide: () => {
            },
            clearSelection: () => {
            },
            ...tmp
        };
        fn(values, {}, {...defaultProps, ...extraProps})
    };

    handleExtraFeeding = () => {
        this.callSubmit(extraFeedingSubmit, {forageDose: 500}, {})
    };

    handleLockFeeding = () => {
        this.callSubmit(_setLock, {status: true});
    };

    handleUnlockFeeding = () => {
        this.callSubmit(_setLock, {status: false});
    };

    handleAlarmSet = () => {
        this.callSubmit(alarmSubmit, {alert: true})
    };

    handleAlarmClear = () => {
        this.callSubmit(alarmSubmit, {alert: false})
    };

    render() {
        const {mobile, data: {receiver: {index} = {}} = {}} = this.props;
        const options = [
            {
                onClick: this.handleExtraFeeding,
                icon: "fas fa-fw fa-utensils"
            },
            {
                onClick: this.handleLockFeeding,
                icon: "fas fa-fw fa-pause"
            },
            {
                onClick: this.handleUnlockFeeding,
                icon: "fas fa-fw fa-play"
            },
            _.isNil(index) ? {
                onClick: this.handleAlarmSet,
                icon: "fas fa-fw fa-bell"
            } : null,
            {
                onClick: this.handleAlarmClear,
                icon: "fas fa-fw fa-bell-slash"
            }
        ].filter(o => o);
        const buttonProps = mobile ? coreMobileButtonProps : coreButtonProps;
        return (
            <div className={"extra-debug"}>
                {
                    React.createElement(mobile ? React.Fragment : ButtonGroup, {
                        children: options.map((o, i) => (
                            <Button {...buttonProps}
                                    onClick={o.onClick} icon={<i className={o.icon}/>}/>
                        ))
                    })
                }
            </div>
        )

    }

}

DebugDispenser.propTypes = {
    data: PropTypes.object.isRequired,
    chamberId: PropTypes.object.isRequired,
};
export default withTranslation()(DebugDispenser);
