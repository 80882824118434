import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import Loading from "../loading/LoadingComponent";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForHumidityChart, temperatureConverter, tickFormatter} from "../../utils/ChartsUtils";
import {getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";

export class HumidityChart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const currentDay = data.find(item => item.AggTi === +date);
        const {humidity, lastTime} = currentDay ? calculateDataForHumidityChart(currentDay) : {
            humidity: [],
            lastTime: 0
        };
        return {
            data: humidity,
            lastTime
        }
    }

    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const unit = getUnit("temperature", UnitTypes.SMALL);
        let def = [
            {
                color: "blue",
                dataKey: "humidity",
                name: t('chamber.chart.humidity'),
                unit: "%",
                opacity: 0.2,
                yAxisId: "humidity"
            },
            {
                color: "green",
                dataKey: "temperature",
                name: t("chamber.chart.temperature"),
                unit: unit,
                opacity: 0.2,
                yAxisId: "temperature",
                valueConverter: temperatureConverter
            }
        ];
        let ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("chamber.chart.humidity"),
                    yAxisId: "humidity"
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [date.startOf("day").toDate().getTime(), "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }} secondYaxis={{
                    name: t("chamber.chart.temperature"),
                    yAxisId: "temperature"
                }} saveAsExcell={"humidity"} tooltipLabelFormatter={tickFormatter} showDomainInput/>
            </React.Fragment>
        )
    }
}

HumidityChart.defaultProps = {
    colors: {
        humidity: "#4a9bd4",
        temperature: "#359d3c",
        inactive: "#999"
    },
    connectNulls: true,
    date: moment().subtract(1, "days"),
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

HumidityChart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired,
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

HumidityChart = connect(
    makeMapStateToProps
)(HumidityChart);

export default withTranslation()(HumidityChart);
