import React from "react";
import {LoadingBull} from "../../../basics/loadings/LoadingBull";
import {isNil, get} from "lodash";
import settingsDB from "../../../../database/settingsDB";
import ProgressChart from "../../../basics/progresschart/ProgressChart";
import moment from "moment";
import Tooltip from "../../../basics/tooltip/Tooltip";
import AgoTime from "../../../basics/ago-time/AgoTime";
import {convertTemperatureUnitTo, convertVolumeUnitTo, convertWeightUnitTo} from "../../../../utils/UnitUtils";
import Highlight from "../../../basics/highlight/Highlight";
import {getColorByName, textContrast} from "../../../../utils/ColorUtils";
import {
    CurveDayShowingType,
    CurveType,
    CurveTypesWithParturition,
    FeedingType
} from "../../../../constans/feedingTypes";
import {formatCurveDay, getCurveDayShowingType} from "../../../../utils/FeedingUtils";
import {getHistoryData, getScheduleDoses} from "../../../../utils/DispenserAdapterUtils";
import Button from "../../../basics/button/Button";
import {FAReactSVG} from "../../../basics/fa-svg-icon/FAReactSVG";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import {withTranslation} from "react-i18next";
import {UnitTypes} from "../../../../constans/unitTypes";

const _IsFeeding = ({isFeeding, seenTime}) => <div className={"is-feeding"}>
    <small
        className={"opacity-50"}>{isFeeding ?
        <LoadingBull/> : seenTime ? <AgoTime minPeriod={30} date={seenTime}/> : null}</small>
</div>;

const _WaterHistory = ({water = {}, t}) => {
    const history = water.history || [];
    const amount = history.reduce((sum, o) => sum + o.amount, 0);
    const formatter = (val) => convertVolumeUnitTo(val, {showUnit: true, unit: UnitTypes.SMALL, fixed: 1})
    return amount ? (
        <>
            <Tooltip tooltipContent={
                <ul>
                    {t("deviceRows.feeding.dispenserRow.waterDisposedToday", {amount: formatter(amount)})}
                    {history.map(item => (<li>
                            {t("deviceRows.feeding.dispenserRow.waterDateAmount", {
                                date: moment(item.time).format("HH:mm"),
                                amount: formatter(item.amount)
                            })}
                        </li>
                    ))}
                </ul>
            }>
                <small><i className={"fas fa-tint mr-1 color-info"}/></small>
            </Tooltip>
        </>
    ) : null;
}

const _ForageAmount = ({water = {}, mobile, t, children, unit = 0, used, planned, additional, additionalDetails = []}) => {
    const conv = (val, showUnit = false) => convertWeightUnitTo(val, {
        unit: unit,
        showUnit: showUnit,
        fixed: unit ? 2 : 0
    });

    return (
        <div className={"forage-amount"}>
            <h3
                className={"align-items-center"}>
                <WaterHistory water={water}/>
                <b className={"given"}>{isNil(used) ? "-" : conv(used)}
                    <div className={"additional"}>            {!!mobile && additional ? `(+${conv(additional)})` : ""}
                    </div>
                </b>
                {!mobile && <small>{additional ? `(+${conv(additional)})` : ""}</small>}
                <small
                    className={"opacity-50"}>/{isNil(planned) ? "-" : conv(planned, true)}<AdditionalDetails
                    conv={conv}
                    additional={additional}
                    mobile={mobile}
                    additionalDetails={additionalDetails}/></small>
            </h3>
            {children}</div>
    )
};

const _AdditionalDetails = ({additionalDetails = [], additional = 0, conv = (o) => o, t}) => {
    if (!additionalDetails.length) return null;
    return (
        <Tooltip tooltipContent={
            <div>
                {`(+${conv(additional, true)})`}{" - "}{t("extraFeeding")}
                <div>
                    {t("deviceRows.feeding.dispenserRow.extraFeedingHours")}
                    {additionalDetails.map(o => (
                        <div>
                            {`${moment(o.time).format("DD-MM-YY HH:mm")} -> ${conv(o.dose, true)}`}<i
                            className={`fas ml-1 fa-fw ${o.success ? "fa-check success" : "fa-times error"}`}/>
                        </div>
                    ))}
                </div>
            </div>
        }
                 placement={"auto"}
                 type={""}>
            <small>
                <i className={`fas fa-question-circle fa-fw ml-1`}/>
            </small>
        </Tooltip>
    )
};
const _CurveDay = ({t, feedingType, eventStage, day, curveId, curveNr, curveMap, loop: {insemination = 0, parturition = 0, endDay = 0} = {}}) => {
    if (curveNr) {
        let curve = curveId ? ((curveMap && curveMap.get(curveId)) || settingsDB.getSettingByID(curveId, {showDeleted: true})) : null;
        const curveName = (get(curve, "SetData.Name", t("deviceRows.feeding.dispenserRow.curveUnknownX", {number: curveNr})));
        const isDeleted = !!get(curve, "DtaDelTime");
        let curveType = get(curve, "SetData.Type");
        curveType = Object.values(CurveType).includes(curveType) ? curveType : CurveType.INDIVIDUAL;
        //todo: usunac warunki gdy krzywe beda normalne
        const showingType = curveType === CurveType.INDIVIDUAL ? CurveDayShowingType.NORMAL : getCurveDayShowingType(curve);
        const looping = Math.max(insemination, /*parturition,*/ endDay);
        const showTooltip = !!looping;
        const loopingComp = <>
            (<i className="fas fa-undo fa-fw fa-xs opacity-50"/>{looping})
        </>;
        let stageName = "";
        const stages = get(curve, "SetData.Stages", []);
        for (let i = stages.length - 1; i >= 0; i--) {
            if (day >= stages[i].StartDay) {
                stageName = stages[i].Name;
                break;
            }
        }
        let labelStage;
        console.log(curveType, showingType);
        if (!curve) {
            labelStage = "-";
        } else if (showingType === CurveDayShowingType.NORMAL) {
            labelStage = t(`newSettings.feeding.curves.curveDescriptions.${CurveType.INDIVIDUAL}.normal.name`);
        } else if (showingType === CurveDayShowingType.BOTH) {
            labelStage = eventStage ? t(`newSettings.feeding.curves.curveDescriptions.${curveType}.after.name`) : t(`newSettings.feeding.curves.curveDescriptions.${curveType}.before.name`);
        } else if (showingType === CurveDayShowingType.BEFORE) {
            labelStage = t(`newSettings.feeding.curves.curveDescriptions.${curveType}.before.name`);
        } else {
            labelStage = t(`newSettings.feeding.curves.curveDescriptions.${curveType}.after.name`);
        }
        return (
            <div className={"curve-day"}>
                <b>{isNil(day) ? '-' : formatCurveDay(day, curve)}</b>
                {
                    !!looping && loopingComp

                }
                <div className={"opacity-50 d-inline"}>
                    {' '}{t("day")}
                    {
                        showTooltip &&
                        <Tooltip tooltipContent={
                            <div>
                                <div>
                                    {loopingComp}{" - "}{t(`deviceRows.feeding.dispenserRow.loop${endDay > 0 ? "CurveEnded" : CurveTypesWithParturition.includes(curveType) ? "Parturition" : "Insemination"}`, {
                                    count: looping
                                })}
                                </div>
                            </div>
                        }
                                 placement={"auto"}
                                 type={""}>
                            <small>
                                <i className={`fas fa-question-circle fa-fw ml-1`}/>
                            </small>
                        </Tooltip>
                    }
                </div>
                <small className={"opacity-75 d-block"}>
                    {labelStage}
                </small>
                <small className={"opacity-50 d-block"}>
                    {stageName ? `${stageName} - ` : ""}{curveName}
                    {
                        isDeleted &&
                        <Tooltip tooltipContent={
                            <div>
                                {t("deviceRows.feeding.dispenserRow.usingDeletedCurve")}
                            </div>
                        }
                                 placement={"auto"}
                                 type={""}>
                            <small>
                                <i className={`fas fa-exclamation-circle fa-fw ml-1`}/>
                            </small>
                        </Tooltip>
                    }
                </small>
            </div>
        )
    }
    return (
        <div className={"curve-day"}>
            <i className={"fas fa-exclamation-triangle warning mr-1"}/>
            <small className={"opacity-50 d-block"}>
                {feedingType === FeedingType.INDIVIDUAL ? t("deviceRows.feeding.dispenserRow.stop") : t("deviceRows.feeding.dispenserRow.defaultCurve")}
            </small>
        </div>
    )
};

const _ForageModificators = ({temperature = {}, feedingType, water = {}, efficiency = 0, punishmentOptions = {}, punishment = 0, correction = 0, t, forageId, forageMap, curveNr}) => {
    const forage = forageId ? ((forageMap && forageMap.get(forageId)) || settingsDB.getSettingByID(forageId, {showDeleted: true})) : null;
    const isDeleted = !!get(forage, "DtaDelTime");
    const efficiencyText = feedingType === FeedingType.INDIVIDUAL ? t("deviceRows.feeding.dispenserRow.efficiencyTime", {
        efficiency: convertWeightUnitTo(efficiency, {
            showUnit: true,
            unit: UnitTypes.SMALL,
            fixed: 0
        })
    }) : null;
    const temperatureComp = temperature.hasTemperature ?
        <Highlight highlightColor={isNil(temperature.temperature) ? "error" : "success"}>{convertTemperatureUnitTo(temperature.temperature, {
            showUnit: true,
            unit: UnitTypes.SMALL,
            fixed: 1
        })}</Highlight>
        : null;
    const formatter = (val) => convertVolumeUnitTo(val, {showUnit: true, unit: UnitTypes.SMALL, fixed: 0})
    const correctionComp = correction ? (correction > 0 ?
        <Highlight highlightColor={"success"}>+{correction}%</Highlight> :
        <Highlight highlightColor={"info"}>{correction}%</Highlight>) : null;
    const punishmentComp = (punishment ?
        <Highlight
            highlightColor={"info"}>{t("punishment")} {punishment}</Highlight> : null);
    const backgroundColor = get(forage, "SetData.Color", getColorByName("primary"));
    const foregroundColor = textContrast(backgroundColor);
    //jesli na bazie nie ma wody i ma ustawiony efficiency na 0 to nie wyswietlamy nic
    const waterDisabledError = !!water.hasWater && !water.efficiency; //na dynamo mamy wode a na dozo nie - skonfiguruj wydajnosc
    const waterEnabledError = !water.hasWater && !!water.efficiency; //na dynamo nie mamy wody a na dozo jest - backend powinien czytac obiekt na dynamo i jesli nie ma wody to wylaczac
    const showWater = !!water.hasWater || waterEnabledError || waterEnabledError;
    const waterComp = showWater ?
        <Highlight highlightColor={(waterDisabledError || waterEnabledError) ? "error" : "info"}>
            <i
                className="fas fa-tint fa-fw mr-1"/>{waterDisabledError ? t("deviceRows.feeding.dispenserRow.waterError") : (
            water.type === 0 ? t("deviceRows.feeding.dispenserRow.efficiencyTime", {efficiency: formatter(((water.efficiency || 0) * 2))}) : t("deviceRows.feeding.dispenserRow.efficiencyImpulsesLiter", {efficiency: water.efficiency})
        )}
        </Highlight> : null
    const forageComp = (forage ?
        <Highlight style={{background: backgroundColor, color: foregroundColor}} highlightColor={"info"}><i
            className={`fas fa-wheat fa-fw${efficiencyText ? " mr-1" : ""}`}/>{efficiencyText}</Highlight> :
        <Highlight highlightColor={"warning"}><i
            className={`fas fa-wheat fa-fw${efficiencyText ? " mr-1" : ""}`}/>{efficiencyText}</Highlight>);
    return (
        <div className={"feeding-status"}>
            <small>
                {waterComp}
                {forageComp}
                {correctionComp}
                {punishmentComp}
                {temperatureComp}
            </small>
            <Tooltip tooltipContent={<div>
                <b>{t("deviceRows.feeding.dispenserRow.doseSettings")}</b>
                <ul>
                    {
                        !!showWater && <li>
                            {waterComp}
                            {(!waterEnabledError && !waterDisabledError) && <>{" - "}{t("deviceRows.feeding.dispenserRow.waterEfficiencyDesc")}</>}
                            {waterDisabledError && <>{" - "}{t("deviceRows.feeding.dispenserRow.missingWaterEfficiencyConfiguration")}</>}
                            {waterEnabledError && <>{" - "}{t("deviceRows.feeding.dispenserRow.errorWaterEfficiency")}</>}
                        </li>
                    }
                    <li>
                        {forageComp}{" - "}{forage ? `${isDeleted ? t("deviceRows.feeding.dispenserRow.usingDeletedForage", {name: forage.SetData.Name}) : forage.SetData.Name}` : curveNr ? t("deviceRows.feeding.dispenserRow.unknownForage") : t("deviceRows.feeding.dispenserRow.defaultForage")}
                    </li>
                    {
                        !!correctionComp && <li>
                            {correctionComp}{" - "}{t("deviceRows.feeding.dispenserRow.correctionDesc", {amount: correction})}
                        </li>
                    }
                    {
                        !!punishmentComp && <li>
                            {punishmentComp}{" - "}{t("deviceRows.feeding.dispenserRow.punishmentDesc", {
                            amount1: punishmentOptions.Percentage,
                            amount2: punishmentOptions.Trigger,
                            amount3: punishmentOptions.Time
                        })}
                        </li>
                    }
                    {
                        !!temperatureComp && <li>
                            {temperatureComp}{" - "}{t("chamber.chart.temperature")}
                        </li>
                    }
                </ul>
                <div>


                </div>
            </div>}
                     placement={"auto"}
                     type={""}>
                <i className={`fas ${isDeleted ? "fa-exclamation-circle" : "fa-question-circle"} fa-fw opacity-50 ml-1`}/>
            </Tooltip>
        </div>
    )
};

export const StandingNumber = ({extended, name, rfid, t, animalName, isDead, isCorrectPlacement}) => (
    <div className={"standing-nr override-inactive"}>
        {
            extended &&
            <h5>{animalName ? animalName : "-"} {
                (isDead || !isCorrectPlacement) &&
                <small>
                    <Tooltip tooltipContent={<div>
                        {isDead ? t("deviceRows.feeding.dispenserRow.animalIsDead") : t("deviceRows.feeding.dispenserRow.animalIsWrongLocation")}
                    </div>}
                             placement={"auto"}
                             type={""}>
                        <i className={"fas fa-exclamation-triangle warning"}/>
                    </Tooltip>
                </small>
            }</h5>
        }
        <div className={"opacity-75"}>
            {name}
        </div>
        {
            (name !== rfid) &&
            <div className={"opacity-50"}>
                {rfid}
            </div>
        }
    </div>
);


const _History = ({lastWeekHistory = [], content = {}}) => {
    const {values, labels} = getHistoryData({lastWeekHistory, content});
    return (
        <div className="feeding-history">
            <ProgressChart
                showLabels
                showValue
                skew
                labels={labels}
                values={values}/>
        </div>
    )
};

const _SkipDoses = ({skipDoses = [], scheduleMap = new Map(), scheduleId, t}) => {
    const {doses, isDeleted} = getScheduleDoses({scheduleMap, scheduleId});
    return (
        <div className={"skip-doses"}>
            <div className={"skip-doses"}>

                {
                    !!doses.length && doses.map((dose, index) => (
                        <small key={index} className={"mr-1 d-inline-block"}>
                            <i className={`${!!skipDoses[index] ? "warning" : "info"}`}>●</i>
                            <div
                                className={!!skipDoses[index] ? "color-warning font-weight-bold d-inline-block" : "d-inline-block opacity-75"}>{dose.start}-{dose.stop}</div>
                        </small>
                    ))
                }
                {
                    !doses.length &&
                    <small className={"mr-1"}>
                        <i className={"fas fa-exclamation-triangle error mr-1"}/><span
                        className={"opacity-50"}>{t("deviceRows.feeding.dispenserRow.scheduleNotFound")}</span>
                    </small>
                }
                {
                    isDeleted &&
                    <Tooltip tooltipContent={<div>
                        {t("deviceRows.feeding.dispenserRow.usingDeletedSchedule")}
                    </div>}
                             placement={"auto"}
                             type={""}>
                        <i className={`fas fa-exclamation-circle fa-fw opacity-50 ml-1`}/>
                    </Tooltip>
                }
            </div>
        </div>
    )
}
const _ExtraButtons = ({buttons = [], mobile}) => {
    if (!buttons.length) return null;
    return (
        <div className={"extra-buttons"}>
            {
                React.createElement(mobile ? React.Fragment : ButtonGroup, {
                    children: buttons.map((btn, index) =>
                        <Button
                            key={index}
                            type={"button"}
                            stopPropagation={true}
                            buttonColor={"info"}
                            icon={btn.iconSvg ? <FAReactSVG src={btn.iconSvg}/> : <i className={btn.icon}/>}
                            disabled={btn.disabled}
                            onClick={btn.action}/>
                    )
                })
            }
        </div>
    );
};

export const CurveDay = React.memo(withTranslation()(_CurveDay));
export const ForageModificators = React.memo(withTranslation()(_ForageModificators));
export const AdditionalDetails = React.memo(withTranslation()(_AdditionalDetails));
export const History = React.memo(withTranslation()(_History));
export const ForageAmount = React.memo(withTranslation()(_ForageAmount));
export const IsFeeding = React.memo(withTranslation()(_IsFeeding));
export const SkipDoses = React.memo(withTranslation()(_SkipDoses));
export const ExtraButtons = React.memo(_ExtraButtons);
export const WaterHistory = React.memo(withTranslation()(_WaterHistory));

// export const clearMemoizeComponents = () => {
//     StandingNumber.cache.clear();
//     CurveDay.cache.clear();
//     ForageModificatiors.cache.clear();
//     AdditionalDetails.cache.clear();
//     History.cache.clear();
//     ForageAmount.cache.clear();
//     IsFeeding.cache.clear();
//     SkipDoses.cache.clear();
//     ExtraButtons.cache.clear();
// };
