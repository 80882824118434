import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import RowComponent from "../../basics/row-component/RowComponent";
import Tooltip from "../../basics/tooltip/Tooltip";
import Checkbox from "../../basics/checkbox/Checkbox";
import IconValue from "../../basics/icon-value/IconValue";
import SiloStatusIcon from "../../../resources/images/chain-feeding/silo.svg";
import ChainStatusIcon from "../../../resources/images/chain-feeding/chain.svg";
import DispenserStatusIcon from "../../../resources/images/chain-feeding/dispenser.svg";
import SensorStatusIcon from "../../../resources/images/chain-feeding/sensor.svg";
import ScheduleStatusIcon from "../../../resources/images/chain-feeding/schedule.svg";
import DelayStatusIcon from "../../../resources/images/chain-feeding/delay.svg";
import {get, isFinite, isFunction, isNil} from "lodash";
import {getInterfName, getLatestMetadata} from "../../../utils/DevicesUtils";
import moment from "moment";
import IconStatus from "../IconStatus";
import {withTranslation} from "react-i18next";
import {default as ChainFeedingBean} from "../../../beans/devices/ChainFeeding";
import {ChainFeedingWorkType} from "../../../constans/devices";


function makeMapStateToProps() {
    const getShadowByDevice = makeGetShadowByDevice();
    return function mapStateToProps(state,props){
        const {shadow, fetching, fetched} = getShadowByDevice(state, props.device);
        return {
            shadow,
            fetching,
            fetched
        }
    }
}

export class ChainFeeding extends React.Component {

    getErrors = ({shadow = {}}) => {
        const errors = shadow.errors || [];
        const {t} = this.props;
        if (!!get(shadow, "currentStatus.chainAlert")) {
            errors.push(7001)
        }
        if (!!get(shadow, "currentStatus.siloAlert")) {
            errors.push(7002)
        }
        if (!!get(shadow, "currentStatus.sensorChangeAlert")) {
            errors.push(7003)
        }
        return errors.map(code => t(`notificationsCenter.errorCodes.${code}`) || code);

    };


    getWorkTypeName = (value) => {
        const {t} = this.props;
        switch (value) {
            case ChainFeedingWorkType.AUTOMATIC:
                return t('deviceRows.chainFeeding.chainFeedingRow.workTypes.automatic');
            case ChainFeedingWorkType.MANUAL:
                return t('deviceRows.chainFeeding.chainFeedingRow.workTypes.manual');
            case ChainFeedingWorkType.INACTIVE:
                return t('deviceRows.chainFeeding.chainFeedingRow.workTypes.inactive');
            case ChainFeedingWorkType.SCHEDULE:
                return t('schedule');
            default:
                return "-"
        }
    }

    getValues = ({shadow = {}, device, fetching, fetched, t}) => {
        const errors = this.getErrors({shadow});
        let schedule = "-";
        let lastSensor = "-";
        if (fetched) {
            if (get(shadow, "workType.workType") === ChainFeedingWorkType.SCHEDULE) {
                schedule = get(shadow, "schedule", []).map(o => moment(o.start).format("HH:mm")).join("/")
            }
            let sensorID = get(shadow, "workType.sensorID");
            if (isFinite(sensorID)) {
                if (get(device, `Settings.Aliases[${sensorID}]`)) {
                    lastSensor = get(device, `Settings.Aliases[${sensorID}]`);
                } else {
                    lastSensor = t("deviceRows.chainFeeding.chainFeedingRow.sensor", {number: sensorID + 1})
                }
            }
        }
        return {
            errors: errors,
            siloStatus: fetched ? !!get(shadow, "currentStatus.sensorSilo") : null,
            chainStatus: fetched ? !!get(shadow, "currentStatus.sensorChain") : null,
            dispenserStatus: fetched ? !!get(shadow, "currentStatus.sensorFeeder") : null,
            lastSensor: lastSensor,
            delay: fetched ? `${get(shadow, "workType.excessWorkTime") / 60000}${t("deviceRows.chainFeeding.chainFeedingRow.minute")}` : "-",
            isAlerted: !!errors.length,
            isConnected: !!fetched,
            isLoading: !!fetching,
            schedule: schedule,
            running: fetched ? t(`deviceRows.chainFeeding.chainFeedingRow.${!!get(shadow, "isRunning") ? "running" : "stopped"}`) : "-",
            workType: fetched ? get(shadow, "workType.workType") : null,
            dateTime: getLatestMetadata(shadow.metadata),

        }
    };

    handleSelect = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {device, onSelect} = this.props;
        if (isFunction(onSelect)) {
            onSelect(device);
        }
    };

    getName = () => {
        const {name, device: {Address, Alias, Name, Interface}, showAlias} = this.props;
        if (showAlias && Alias) {
            return Alias;
        }
        if (name) {
            return name;
        }
        if (isNil(Address)) {
            return Name
        }
        return `${Address}/0x${Address.toString(16)} ${getInterfName(Interface)}`
    };


    getIcon = (value) => isNil(value) ? "-" : <i className={value ? "fas fa-check" : "fas fa-times"}/>

    onContextMenu = e => {
        this.props.onMenuOpen(e, this.props.device);
        return false;
    }

    render() {
        const {fetched, t, device: {DevID, Number}, selected} = this.props;
        const {running, delay, workType, lastSensor, schedule, chainStatus, siloStatus, dispenserStatus, dateTime, errors, isConnected, isLoading, isAlerted} = this.getValues(this.props);
        const name = this.getName();
        return (
            <RowComponent className={"device-item"}
                          onContextMenu={this.onContextMenu}
                          showWorking isError={!fetched}>
                <div className="device-info">
                    <IconStatus dateTime={dateTime} isConnected={isConnected} isAlerted={isAlerted}
                                isLoading={isLoading}>
                        {({icon, color, header}) => (
                            <div className={`device-main dev-cl ${color}`}>
                                <div className="device-main-location" title={name}>{name}</div>
                                <div className="device-main-item">{this.getWorkTypeName(workType)}</div>
                                <div className="device-main-item-alternate" title={running}>{running}</div>
                                <Tooltip tooltipContent={<div>
                                    <b>{header}</b>
                                    {
                                        !!dateTime &&
                                        <div><i
                                            className="fas fa-sync mr-1"/><b>{moment(dateTime).format("DD.MM.YY HH:mm")}</b>
                                        </div>
                                    }
                                    <div>
                                        {errors.join(", ")}
                                    </div>
                                </div>}
                                         placement={"auto"}
                                         type={color}>
                                    <div className="device-main-working">
                                        <i className={icon}/>
                                    </div>
                                </Tooltip>
                                <div onClick={this.handleSelect} className="device-main-checkbox show-on-small-device sm">
                                    <Checkbox label={""} id={DevID} readOnly checked={selected}/>
                                </div>
                            </div>
                        )}
                    </IconStatus>

                    <div className="device-info-main">
                        <div className="device-info-main-second">
                            <div className="device-info-main-second-name">{Number || "-"}</div>
                            <IconValue icon={SiloStatusIcon}
                                       name={t("deviceRows.chainFeeding.chainFeedingRow.siloStatus")}
                                       value={this.getIcon(siloStatus)}/>
                            <IconValue icon={ChainStatusIcon}
                                       name={t("deviceRows.chainFeeding.chainFeedingRow.chainStatus")}
                                       value={this.getIcon(chainStatus)}/>
                            <IconValue icon={DispenserStatusIcon}
                                       name={t("deviceRows.chainFeeding.chainFeedingRow.dispenserStatus")}
                                       value={this.getIcon(dispenserStatus)}/>
                            <IconValue icon={DelayStatusIcon}
                                       name={t("deviceRows.chainFeeding.chainFeedingRow.delay")}
                                       value={delay}/>
                            <IconValue icon={SensorStatusIcon}
                                       name={t("deviceRows.chainFeeding.chainFeedingRow.lastSensor")}
                                       value={lastSensor}/>
                            <IconValue icon={ScheduleStatusIcon}
                                       name={t("deviceRows.chainFeeding.chainFeedingRow.schedule")}
                                       value={schedule}/>

                        </div>
                    </div>
                    <div onClick={this.handleSelect} className="device-info-checkbox hide-on-small-device sm">
                        <Checkbox label={""} id={DevID} readOnly checked={selected}/>
                    </div>
                </div>
            </RowComponent>
        );
    }

}

ChainFeeding.propTypes = {
    device: PropTypes.instanceOf(ChainFeedingBean).isRequired
};

ChainFeeding = connect(
    makeMapStateToProps
)(ChainFeeding);

export default withTranslation()(ChainFeeding);
