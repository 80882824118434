import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import IconValue from "../basics/new-icon-value/IconValue";
import "./_device-row.scss";
import {debounce, isFunction, isNil} from "lodash";
import IconStatus from "./IconStatus";
import Tooltip from "../basics/tooltip/Tooltip";
import moment from "moment";
import {withTranslation} from "react-i18next";


class DeviceRow extends Component {

    showNumber = 0;
    //callback do measure - dopasowanie virtualiza do rowa w zaleznosci od ilosci childow - troche hack bo nie chcialem tworzyc zmiennych w state
    childrenChanged = debounce(() => {
        try {
            this.props.showingCallback();
        } catch (e) {
            //unmounted component - we dont care at this point
        }
    }, 100);

    getParams = () => {
        const {params = []} = this.props;
        return params.filter(param => !!param.show || !isNil(param.value));
    }

    onExpand = (e) => {
        const {onExpand} = this.props;
        e.stopPropagation();
        onExpand()
    }

    checkChildrenChange = (params) => {
        if (this.showNumber && this.showNumber !== params.length) {
            this.childrenChanged();
        }
        this.showNumber = params.length;
    }

    render() {
        const {t, onContextMenu, selected, number, expanded, onExpand, onClick, upperTitle, lowerTitle, title, icon: {isAlerted, isConnected, isDisabled, dateTime, isLoading, errors = []} = {}} = this.props;
        const params = this.getParams();
        const hasExpand = isFunction(onExpand);
        const hasNumber = !isNil(number);
        this.checkChildrenChange(params);
        return (
            <Col xs={12} onClick={onClick} className={"device-row d-block"} onContextMenu={onContextMenu}>
                <Row>
                    <IconStatus dateTime={dateTime} isConnected={isConnected} isLoading={isLoading}
                                isAlerted={isAlerted} isDisabled={isDisabled}>
                        {({icon: iconClassName, color, header}) =>
                            <>
                                <Col sm={12} xl={2}
                                     className={`mh-5rem pt-2 pb-2 position-relative background-${color}${selected ? "-dark" : ""}`}>
                                    <div className={`device-row-icon background-${color}${selected ? "-dark" : ""}`}>
                                        <Tooltip tooltipContent={
                                            <div>
                                                <div>{header}</div>
                                                {
                                                    !!dateTime &&
                                                    <div>
                                                        <i className="fas fa-sync mr-1"/><b>{moment(dateTime).format("HH:mm:ss DD.MM.YY")}</b>
                                                    </div>
                                                }
                                                <div><small>{errors.join("\n")}</small></div>
                                            </div>
                                        }
                                                 placement={"auto"}
                                                 type={color}>
                                            <div>
                                                <i className={iconClassName}/>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Row className={"h-25"}>
                                        <Col className={"opacity-75 no-break-word"}>
                                            {upperTitle}&nbsp;
                                        </Col>
                                    </Row>
                                    <Row className={"h-50 align-items-center"}>
                                        <Col className={"no-break-word"}>
                                            <h4>
                                                {title}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className={"h-25"}>
                                        <Col className={"opacity-75 no-break-word"}>
                                            {lowerTitle}&nbsp;
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        }
                    </IconStatus>
                    {
                        hasNumber &&
                        <Col sm={12} xl={1} key={'number_0'}
                             className={"d-flex align-items-center justify-content-left justify-content-lg-center opacity-75"}>
                            <span className={"d-inline d-xl-none mr-1"}>
                                {t("grid.number")}:
                            </span>
                            <h3>{number}</h3>
                        </Col>
                    }
                    <Col sm={12} xl={10 - (+hasNumber + +hasExpand)} className={"align-items-center mb-1 mt-1"}>
                        <Row className={"align-items-center h-100"}>

                            {
                                params.map((o, index) => (
                                    <Col key={index} xl={(o.size || 1) * 2} lg={(o.size || 1) * 4}
                                         md={(o.size || 1) * 4} sm={(o.size || 1) * 6} xs={(o.size || 1) * 6}>
                                        <IconValue
                                            color={o.color}
                                            value={o.value}
                                            title={o.title}
                                            unit={o.unit}
                                            icon={o.icon}
                                            small={o.small}
                                            show={isNil(o.show) ? !isNil(o.value) : !!o.show}
                                            valueFormatter={o.valueFormatter}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    {
                        hasExpand &&
                        <Col
                            className={"d-flex align-items-center justify-content-center mb-2 mt-2 mt-xl-0 mb-xl-0"}
                            onClick={this.onExpand}
                            xs={12} xl={1}
                        >
                            <i className={`fa-fw fa-2x fas opacity-50 fa-chevron-${expanded ? "up" : "down"}`}/>
                        </Col>
                    }
                </Row>
            </Col>

        );
    }
}

DeviceRow.defaultProps = {
    showingCallback: () => {
    }
}

export default withTranslation()(DeviceRow);
