import React, {Component} from 'react';
import {connect} from 'react-redux';
import BottomDrawer from "../../../basics/bottom-drawer/BottomDrawer";
import {Col, Row} from "react-bootstrap";
import Button from "../../../basics/button/Button";
import {getFeedingSelected} from "../../../../selectors/feedingSelector";
import {isEqual} from "lodash";
import {submit as extraFeedingSubmit} from "../../../modals-new/feeding-extra-feeding/FeedingExtraFeedingSubmit";
import {getSelectedDispensers} from "../../../../utils/FeedingUtils";
import {_setLock, TurnDispenserOff} from "../mini-components/FeedingConfirmModals";
import {changeDispensersDoseCorrection, skipDosesAfterTime} from "../../../../IOT/device-functions/GatewayFunctions";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import {ModalName as FeedingManageFeedingModalName} from "../../../modals-new/feeding-manage-feeding/FeedingManageFeedingModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {submit as conditionSubmit} from "../../../modals-new/feeding-condition-modal/FeedingConditionModalSubmit";
import {SectorType} from "../../../../constans/sectorTypes";
import {ModalName as FeedingReportParturitionModalName} from "../../../modals-new/feeding-report-parturition/FeedingReportParturitionModal";
import {ModalName as FeedingReportInseminationModalName} from "../../../modals-new/feeding-report-insemination/FeedingReportInseminationModal";
import {ModalName as FeedingEmptyForageModalName} from "../../../modals-new/feeding-empty-forage/FeedingEmptyForageModal";
import {ModalName as FeedingSetAlarmModalName} from "../../../modals-new/feeding-set-alarm/FeedingSetAlarmModal";
import {ModalName as AssignAnimalToStandingModal} from "../../../modals-new/assign-animal-to-standing-modal/AssignAnimalToStandingModal";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {feedingUnselectAll} from "../../../../actions/feedingActions";

function mapStateToProps(state) {
    return {
        dispensersSelected: getFeedingSelected(state, {CID: state.feeding.selectedChamber}),
        chamberId: state.feeding.selectedChamber,
    };
}

const Item = ({label, buttons, buttonGroup}) => (
    <Row>
        {
            !!label && <label className={"text-center opacity-75"}>
                {label}
            </label>
        }
        {
            !!buttons && buttons.map((btn, i) => (
                <Col xs={12 / buttons.length}>
                    <Button type={"button"} key={i} onClick={btn.action} className={"w-100 pt-md-3 pb-md-3 pt-2 pb-2"}
                            icon={btn.icon ? <i className={btn.icon}/> : undefined} disabled={btn.disabled}
                            buttonColor={btn.color}>
                        {btn.disabled ? `${btn.text} (${btn.error})` : btn.text}
                    </Button>
                </Col>
            ))
        }
        {
            !!buttonGroup &&
            <Col xs={12}>
                <ButtonGroup className={"justify-content-center"}>
                    {
                        buttonGroup.map((btn, i) => (
                            <Button type={"button"} key={i} onClick={btn.action}
                                    className={(btn.className || "") + " pt-md-3 pb-md-3 pt-2 pb-2"}

                                    icon={btn.icon ? <i className={btn.icon}/> : undefined}
                                    buttonColor={btn.color}>
                                {btn.text}
                            </Button>
                        ))
                    }

                </ButtonGroup>
            </Col>

        }
    </Row>
);

class DispenserButtonsSimple extends Component {

    constructor(props) {
        super(props);
        this.drawer = React.createRef();
        this.state = getSelectedDispensers(this.props.chamberId, this.props.dispensersSelected)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {chamberId, dispensersSelected} = this.props;
        if (!isEqual(chamberId, nextProps.chamberId) || !isEqual(dispensersSelected, nextProps.dispensersSelected)) {
            const result = getSelectedDispensers(nextProps.chamberId, nextProps.dispensersSelected);
            this.setState({...result});
        }
    }

    handleHide = () => {
        try {
            this.drawer.current.handleShrink();
        } catch (e) {
        }
    };

    clearSelection = () => {
        const {dispatch, chamberId} = this.props;
        dispatch(feedingUnselectAll({CID: chamberId}));
    };

    callSubmit = (fn, values = {}, extraProps = {}) => {
        const defaultProps = {
            cookies: new Map(),
            handleHide: () => {
            },
            clearSelection: () => {
            },
            ...this.state
        };
        fn(values, {}, {...defaultProps, ...extraProps});
        this.clearSelection();
        this.handleHide();
    };

    handleExtraFeeding = (dose = 500) => {
        this.callSubmit(extraFeedingSubmit, {forageDose: dose}, {});
    };

    handleLock = (lock = false) => {
        this.callSubmit(_setLock, {status: lock}, {});
    };

    handleDose = (doseOffset = 5) => {
        const {receivers} = this.state;
        for (let [gatewayID, dispensers] of receivers) {
            const data = [];
            if (dispensers.individualDTM.length) {
                dispensers.individualDTM.forEach((dev) => {
                    data.push({
                        DevID: dev.DevID,
                        dispensers: dev.outputs.map(out => ({
                            number: out.number,
                            correctionChange: doseOffset
                        }))
                    });
                })
            }
            if (dispensers.individualNRF.devices.length) {
                dispensers.individualNRF.devices.forEach(deviceId => {
                    data.push({
                        DevID: deviceId,
                        correctionChange: doseOffset
                    })
                });
            }
            if (data.length) {
                changeDispensersDoseCorrection(gatewayID, {devices: data});
            }
        }
        this.handleHide();
    };

    handleDoseSkip = (endDay = false) => {
        const {receivers} = this.state;
        for (let [gatewayID, dispensers] of receivers) {
            const data = [];
            if (dispensers.individualDTM.length) {
                dispensers.individualDTM.forEach((dev) => {
                    data.push({
                        DevID: dev.DevID,
                        dispensers: dev.outputs.map(out => ({
                            number: out.number,
                            doses: endDay ? 6 : 1
                        }))
                    });
                })
            }
            if (dispensers.individualNRF.devices.length) {
                dispensers.individualNRF.devices.forEach(deviceId => {
                    data.push({
                        DevID: deviceId,
                        doses: endDay ? 6 : 1
                    })
                });
            }
            if (data.length) {
                skipDosesAfterTime(gatewayID, {devices: data});
            }
        }
        this.clearSelection();
        this.handleHide();
    };

    handleCondition = (condition) => {
        this.callSubmit(conditionSubmit, {doseCorrection: condition}, {});
    };

    /**
     * Uniwersalana funkcja otwierajaca modale zwiazane z zywieniem
     * @param modalName
     */
    openModal = (modalName) => {
        const {show} = this.props;
        const {feedingType, selectedNodes, receivers, chamberID, sectorType} = this.state;
        show(modalName, {
            type: feedingType,
            selectedNodes: selectedNodes,
            receivers: receivers,
            locationID: chamberID,
            sectorType: sectorType,
            clearSelection: () => {
                this.clearSelection();
                this.handleHide();
            }
        })
    };

    openConfirmModal = (fn, extraProps = {}) => {
        const {show, t} = this.props;
        const {feedingType, receivers, selectedNodes = []} = this.state;
        fn({
            show,
            feedingType,
            receivers,
            t,
            selectedNodes,
            onConfirmed: () => {
                this.clearSelection();
                this.handleHide();
            },
            ...extraProps
        })
    };

    handleDisable = () => {
        this.openConfirmModal(TurnDispenserOff);
    };

    handleAssign = () => {
        const {show, dispensersSelected, chamberId, chambers} = this.props;
        const {sectorType} = this.state;
        const location = chambers.find((c) => c.CID === chamberId);
        show(AssignAnimalToStandingModal, {
            standing: {
                id: dispensersSelected[0].box.BID,
                animal: dispensersSelected[0].animal,
                device: dispensersSelected[0].receiver ? {
                    DevID: dispensersSelected[0].receiver.deviceId,
                    GatewayID: dispensersSelected[0].receiver.gatewayId,
                    Index: dispensersSelected[0].receiver.index
                } : undefined
            },
            chamber: location,
            sectorType
        });
    };


    render() {
        const {isActive, dispensersSelected, t} = this.props;
        const {sectorType} = this.state;
        const items = [
            {
                //disabled:
                buttons: [
                    {
                        text: t("deviceRows.feeding.dispenserSimpleButtons.assign"),
                        action: () => this.handleAssign(),
                        disabled: dispensersSelected.length !== 1,
                        error: t("deviceRows.feeding.dispenserSimpleButtons.moreThanOne")
                    }
                ]
            },
            {
                buttons: [
                    {
                        text: t("alarms"),
                        action: () => this.openModal(FeedingSetAlarmModalName)
                    },
                ]
            },
            {
                buttons: [
                    {
                        text: t("deviceRows.feeding.dispenserSimpleButtons.emptyForage"),
                        action: () => this.openModal(FeedingEmptyForageModalName)
                    },
                    {text: t("deviceRows.feeding.dispenserSimpleButtons.disable"), action: () => this.handleDisable()}
                ]
            },
            {
                label: t("deviceRows.feeding.dispenserSimpleButtons.skipDoses"),
                buttons: [
                    {
                        text: t("deviceRows.feeding.dispenserSimpleButtons.closest"),
                        action: () => this.handleDoseSkip(false)
                    },
                    {
                        text: t("deviceRows.feeding.dispenserSimpleButtons.endOfDay"),
                        action: () => this.handleDoseSkip(true)
                    }
                ]
            },
            {
                label: t("extraFeeding"),
                buttons: [
                    {
                        text: convertWeightUnitTo(500, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1}),
                        action: () => this.handleExtraFeeding(500)
                    },
                    {
                        text: convertWeightUnitTo(1000, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1}),
                        action: () => this.handleExtraFeeding(1000)
                    }
                ]
            },
            [SectorType.DELIVERY].includes(sectorType) ? {
                label: t("doseCorrection"),
                buttons: [
                    {text: "-5%", color: "info", action: () => this.handleDose(-5)},
                    {text: "+5%", color: "success", action: () => this.handleDose(5)}
                ]
            } : {
                label: t("condition"),
                buttonGroup: [
                    {
                        text: "-25%",
                        color: "info",
                        action: () => this.handleCondition(-25),
                        className: "pl-1 pr-1 w-25"
                    },
                    {
                        text: "-10%",
                        color: "info",
                        action: () => this.handleCondition(-10),
                        className: "pl-1 pr-1 w-25"
                    },
                    {text: "0%", action: () => this.handleCondition(0), className: "pl-1 pr-1 w-25"},
                    {
                        text: "+10%",
                        color: "success",
                        action: () => this.handleCondition(10),
                        className: "pl-1 pr-1 w-25"
                    },
                    {
                        text: "+25%",
                        color: "success",
                        action: () => this.handleCondition(25),
                        className: "pl-1 pr-1 w-25"
                    },
                ]
            }, {
                buttons: [
                    {icon: "fas fa-pause", action: () => this.handleLock(true)},
                    {icon: "fas fa-play", action: () => this.handleLock(false)}
                ]
            },
            {
                buttons: [
                    {
                        text: t("manageFeeding"),
                        action: () => this.openModal(FeedingManageFeedingModalName)
                    }
                ]
            }
        ];
        if ([SectorType.DELIVERY].includes(sectorType)) {
            items[items.length - 1].buttons.unshift({
                text: t("birth"),
                action: () => this.openModal(FeedingReportParturitionModalName)
            })
        } else if ([SectorType.MATING].includes(sectorType)) {
            items[items.length - 1].buttons.unshift({
                    text: t("insemination"),
                    action: () => this.openModal(FeedingReportInseminationModalName)
                }
            )
        }
        return (
            <div>
                <BottomDrawer ref={this.drawer}
                              text={i18next.t("deviceRows.feeding.dispenserSimpleButtons.selectedX", {count: dispensersSelected.length})}
                              show={dispensersSelected.length && isActive}>
                    <Col sm={12} md={6}>
                        {items.map((item, index) => <Item key={`itm_${index}`} {...item}/>)}
                    </Col>
                </BottomDrawer>
            </div>
        );
    }
}

DispenserButtonsSimple = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(DispenserButtonsSimple);

DispenserButtonsSimple = connect(
    mapStateToProps,
)(DispenserButtonsSimple);

export default withTranslation()(DispenserButtonsSimple);
