import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import _ from "lodash";
import {FeedingSortType} from "../../../../selectors/feedingSelector";
import MenuItem from "../../../basics/menu/MenuItem";
import Menu from "../../../basics/menu/Menu";
import {feedingSort as feedingSortBy} from "../../../../actions/feedingActions";
import Button from "../../../basics/button/Button";
import {withTranslation} from "react-i18next";


class SortMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            event: null
        }
    }


    setEvent = (event) => {
        this.setState({
            event
        })
    };

    getItems = () => [
        {
            name: this.props.chamber.IndividualFeeding ? this.props.t("deviceRows.feeding.dispenserRow.standingName") : this.props.t("rfid"),
            value: FeedingSortType.BY_DEFAULT_ASC
        },
        {
            name: this.props.t("animalNumber"),
            value: FeedingSortType.BY_ANIMAL_NUMBER_ASC
        },
        {
            name: this.props.t("deviceRows.feeding.dispenserRow.foodAmount"),
            value: FeedingSortType.BY_USAGE_ASC
        },
        {
            name: this.props.chamber.IndividualFeeding ? this.props.t("deviceRows.feeding.dispenserRow.lastFed") : this.props.t("deviceRows.feeding.dispenserRow.lastSeen"),
            value: FeedingSortType.BY_ACTIVITY_ASC
        },
        {
            name: this.props.t("deviceRows.feeding.dispenserRow.percentage"),
            value: FeedingSortType.BY_PERCENTAGE_ASC
        }
    ].filter(o => o);


    handleSort = (newValue) => {
        const {feedingSort, chamber, dispatch} = this.props;
        if (this.chopEnd(newValue) === this.chopEnd(feedingSort)) {
            dispatch(feedingSortBy(chamber, `${this.chopEnd(newValue)}${feedingSort.endsWith("_ASC") ? "_DESC" : "_ASC"}`))
        } else {
            dispatch(feedingSortBy(chamber, newValue))
        }
    };

    chopEnd = (astring) => {
        return astring.split("_").slice(0, -1).join("_");
    };

    render() {
        const {feedingSort, t} = this.props;
        const {event} = this.state;
        let items = this.getItems();
        const name = _.get(items.find(item => this.chopEnd(item.value) === this.chopEnd(feedingSort)), "name", t("deviceRows.feeding.dispenserRow.sort"));
        return (
            <>
                <Button title={name} type={"button"} className={"w-100"} onClick={this.setEvent}><i
                    className={`fas fa-flip-vertical fa-sort-amount-${feedingSort.endsWith("_ASC") ? "up" : "down"}`}/><span
                    className={"d-none d-lg-inline ml-1"}>{name}</span></Button>
                <Menu event={event}>
                    {items.map(item => (
                        <MenuItem stopPropagation={true} onClick={() => this.handleSort(item.value)}
                                  icon={this.chopEnd(item.value) === this.chopEnd(feedingSort) ? (feedingSort.endsWith("_ASC") ?
                                      <i className="fas fa-sort-amount-up fa-flip-vertical"/> :
                                      <i className="fas fa-sort-amount-down fa-flip-vertical"/>) : null}>{item.name}</MenuItem>
                    ))
                    }
                </Menu>
            </>
        )

    }


}


SortMenu.propTypes = {
    chamber: PropTypes.object.isRequired
};

SortMenu = connect((state, props) => ({
    feedingSort: (state.feeding.feeding[props.chamber.CID] || {feedingSort: FeedingSortType.BY_USAGE_ASC}).feedingSort

}))(SortMenu);
export default withTranslation()(SortMenu);


