import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../loading/LoadingComponent";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForVentilationChart, temperatureConverter, tickFormatter} from "../../utils/ChartsUtils";
import {getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

export class VentilationChart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const {vents, lastTime} = calculateDataForVentilationChart(data, +date);
        return {
            data: [...vents.values()],
            lastTime
        }
    }

    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const unit = getUnit("temperature", UnitTypes.SMALL);
        const def = [
            {
                color: "blue",
                dataKey: "ventilation",
                name: t("chamber.chart.ventilation"),
                unit: "%",
                opacity: 0.2,
                yAxisId: "ventilation"
            },
            {
                color: "green",
                dataKey: "temperature",
                name: t('chamber.chart.temperature'),
                unit: unit,
                opacity: 0.2,
                yAxisId: "temperature",
                valueConverter: temperatureConverter
            }
        ];
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("chamber.chart.ventilation"),
                    yAxisId: "ventilation"
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [date.startOf("day").toDate().getTime(), "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }} secondYaxis={{
                    name: t("chamber.chart.temperature"),
                    yAxisId: "temperature"
                }} saveAsExcell={"ventilation"} tooltipLabelFormatter={tickFormatter} showDomainInput/>
            </React.Fragment>
        )
    }
}

VentilationChart.defaultProps = {
    colors: {
        ventilation: "#4a9bd4",
        temperature: "#359d3c",
        masters: "#ff9419",
        inactive: "#999"
    },
    connectNulls: true,
    date: moment().subtract(1, "days"),
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};


VentilationChart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired,
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

VentilationChart = connect(makeMapStateToProps)(VentilationChart);

export default withTranslation()(VentilationChart);
