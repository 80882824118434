import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";

export class DeviceClock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            time: null
        };
        this.setupClock(this.props);
    }


    setupClock = (props = this.props) => {
        clearInterval(this.interval);
        const {variablePath, metaPath, deviceId, shadows} = props;
        let time = _.get(shadows.get(deviceId), variablePath);
        let metaTime = metaPath && _.get(shadows.get(deviceId), metaPath);
        if (_.isNil(time)) {
            this.setState({time: null})
        } else {
            if (!_.isNil(metaTime)) {
                time += Math.max(+moment() - metaTime, 0);
            }
            this.setState({
                time: moment(time)
            }, () => {
                this.interval = setInterval(this.onTick, 1000);
            })
        }

    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {variablePath, metaPath, deviceId, shadows} = this.props;
        let nextTime = _.get(nextProps.shadows.get(nextProps.deviceId), nextProps.variablePath);
        let nextMeta = nextProps.metaPath && _.get(nextProps.shadows.get(nextProps.deviceId), nextProps.metaPath);
        let time = _.get(shadows.get(deviceId), variablePath);
        let meta = metaPath && _.get(shadows.get(deviceId), metaPath);
        if (!_.isEqual(time, nextTime) || !_.isEqual(meta, nextMeta)) {
            this.setupClock(nextProps);
        }
    }

    onTick = () => {
        if (!_.isNil(this.state.time)) {
            this.setState(state => {
                if (_.isNil(state.time)) {
                    return {
                        time: null
                    }
                } else {
                    return {
                        time: state.time.add(1, "second")
                    }
                }
            })
        }

    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {renderComponent} = this.props;
        const {time} = this.state;
        return (
            <>
                {renderComponent(_.isNil(time) ? null : time)}
            </>
        );
    }

}


DeviceClock.propTypes = {
    deviceId: PropTypes.string.isRequired,
    variablePath: PropTypes.string.isRequired,
    metaPath: PropTypes.string,
    renderComponent: PropTypes.func
};

DeviceClock.defaultProps = {
    renderComponent: (timeMoment) => (
        <time>{timeMoment ? timeMoment.format("HH:mm:ss") : "--:--:--"}</time>
    )
};

const mapStateToProps = (state) => ({
    shadows: state.shadows.shadows
});

export default connect(mapStateToProps)(DeviceClock);