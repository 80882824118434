import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import NotFound from "../NotFound";
import Loading from "../loading/LoadingComponent";
import moment from "moment";
import Chart from "../basics/chart/Chart";
import LabeledSlider from "../basics/slider/labeled-slider/LabeledSlider";
import CompareInsertionsTooltip from "./CompareInsertionsTooltip";
import Worker from "../../workers/charts/passageInDaysChart.worker"
import {withTranslation} from "react-i18next";

@connect((store) => {
    return {
        cageData: store.aggregatedData.data,
    };
})
export class PassageInDaysChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            sliderValue: null,
            timeout: null
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props.t("chamber"), nextProps.t("chamber"))) return true;
        return !_.isEqual(this.state, nextState);
    }

    async componentDidMount() {
        let worker = new Worker();
        let cageData = this.props.cageData.get(this.props.cage.DevID);
        worker.postMessage({
            cageData,
            settlement: this.props.settlement,
            shadowInsertion: this.props.applyShadow ? this.props.shadowInsertion : null,
            sliderValue: this.state.sliderValue
        });
        worker.onmessage = event => {
            this.setState({
                data: event.data.passage,
                sliderValue: event.data.closestIndex || this.state.sliderValue,
                loading: false
            })
        };
        this.setState({
            worker
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        let cageData = nextProps.cageData.get(nextProps.cage.DevID);
        this.state.worker.postMessage({
            cageData,
            settlement: nextProps.settlement,
            shadowInsertion: nextProps.applyShadow ? nextProps.shadowInsertion : null,
            sliderValue: this.state.sliderValue
        })
    }

    componentWillUnmount() {
        if (this.state.worker) {
            this.state.worker.terminate();
        }
    }

    formatDate = value => {
        return moment(value, "DD.MM.YYYY").toDate();
    };

    onSliderChange = value => {
        clearTimeout(this.state.timeout);
        let timeout = setTimeout(() => {
            let cageData = this.props.cageData.get(this.props.cage.DevID);
            this.state.worker.postMessage({
                cageData,
                settlement: this.props.settlement,
                shadowInsertion: this.props.applyShadow ? this.props.shadowInsertion : null,
                sliderValue: value
            })
        }, 100);
        this.setState({
            sliderValue: value,
            timeout
        });
    }

    render() {
        const {loading, sliderValue, data} = this.state;
        const {applyShadow} = this.props;
        let chartDef = [
            {
                color: "green",
                dataKey: "all",
                name: this.props.t("all")
            },
            {
                color: "blue",
                dataKey: "left",
                name: this.props.t("left"),
                defaultOff: true
            },
            {
                color: "pink",
                dataKey: "right",
                name: this.props.t('right'),
                defaultOff: true
            },
            {
                color: "orange",
                dataKey: "middle",
                name: this.props.t("middle"),
                defaultOff: true
            },
        ];
        if (applyShadow) {
            chartDef = [
                ...chartDef,
                {
                    color: "green",
                    dataKey: "allShadow",
                    name: this.props.t("all"),
                    strokeOpacity: 0.4
                },
                {
                    color: "blue",
                    dataKey: "leftShadow",
                    name: this.props.t("left"),
                    strokeOpacity: 0.4,
                    defaultOff: true
                },
                {
                    color: "orange",
                    dataKey: "middleShadow",
                    name: this.props.t('middle'),
                    strokeOpacity: 0.4,
                    defaultOff: true
                },
                {
                    color: "pink",
                    dataKey: "rightShadow",
                    name: this.props.t('right'),
                    strokeOpacity: 0.4,
                    defaultOff: true
                }
            ]
        }
        return (
            <>
                <Loading isLoading={loading}/>
                {
                    data.length === 0 && <NotFound/>
                }
                {
                    data.length > 0 &&
                    <>
                        {
                            applyShadow &&
                            <LabeledSlider label={"Porównanie"} valueFormatter={value => data[value].name}
                                           value={sliderValue || 0} onChange={this.onSliderChange}
                                           max={data.length - 1}/>
                        }
                        <Chart dataDef={chartDef} data={data} Yaxis={{
                            name: this.props.t('chamber.chart.passes')
                        }} Xaxis={{
                            name: this.props.t('date'),
                            dataKey: "name"
                        }} saveAsExcell={"passesInDays"} hasShadows={applyShadow} tooltipContent={applyShadow ? <CompareInsertionsTooltip/> : null}/>
                    </>
                }
            </>
        )
    }
}

PassageInDaysChart.propTypes = {
    cage: PropTypes.object,
    settlement: PropTypes.shape({
        DtaStartTime: PropTypes.number.isRequired,
        DtaEndTime: PropTypes.number,
    }),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

PassageInDaysChart.defaultProps = {
    colors: {
        all: "#359d3c",
        left: "#4a9bd4",
        right: "#f44b4b",
        middle: "#ff9419",
        inactive: "#999"
    },
    brushHeight: 36,
    height: "100%",
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

export default withTranslation()(PassageInDaysChart);
