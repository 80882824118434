import React from "react";
import PropTypes from "prop-types";
import withRoles from "../../withRoles";
import {Roles} from "../../../constans/roles";
import {getLocationID, getLocationName} from "../../../utils/BuildingUtils";
import RowComponent from "../../basics/row-component/RowComponent";
import {Collapse} from "react-bootstrap";
import Button from "../../basics/button/Button";
import WeightChart from "../../charts/WeightChart";
import GainChart from "../../charts/GainChart";
import PassageInDaysChart from "../../charts/PassageInDaysChart";
import PassageChart from "../../charts/PassageChart";
import moment from "moment";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import {extractDevPlcmnts, getInterfName} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import HourlyChart from "../../charts/HourlyChart";
import animalsDB from "../../../database/animalsDB";
import {Level} from "../../../constans/levelTypes";
import Tooltip from "../../basics/tooltip/Tooltip";
import CageSVG, {status as cageStatus} from "../../new-chamber/cage/Cage";
import "./_cage.scss";
import TableGrid from "../../basics/table-grid/TableGrid";
import Checkbox from "../../basics/checkbox/Checkbox";
import {findIndex, get, isEqual, uniqBy} from "lodash";
import Select from "../../basics/select/Select";
import Input from "../../basics/input/Input";
import CageCalculator from "../../cage-calculator/CageCalculator";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as CageExpandDataModalName} from "../../modals-new/cage-expand-data/CageExpandDataModal";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import CageMarker from "../../cage-marker/CageMarker";
import IconValue from "../../basics/icon-value/IconValue";
import Weight from "../../../resources/images/cage/weight.svg"
import LastWeight from "../../../resources/images/cage/previous_weight.svg";
import AverageWeight from "../../../resources/images/cage/average_weight.svg";
import Gain from "../../../resources/images/cage/gain.svg";
import CageSideData from "./CageSideData";
import AnimalNumber from "../../../resources/images/cage/animal_number.svg";
import FatteningDay from "../../../resources/images/cage/fattening_day.svg";
import AveragePassesPerAnimal from "../../../resources/images/cage/average_pig_walk.svg";
import {ModalName as CompareInsertionsModalName} from "../../modals-new/compare-insertions/CompareInsertionsModal";
import {ModalName as CompareDayDataModalName} from "../../modals-new/compare-day-data/CompareDayDataModal";
import RFIDChart from "../../charts/RFIDChart";
import {getShowAliases, isUsingFakeData} from "../../../utils/SettingsUtils";
import ScreenSize from "../../basics/screen-size/ScreenSize";
import {BreakPoint} from "../../../constans/breakpointTypes";
import CircularProgressBar from "../../basics/circular-progressbar/CircularProgressBar";
import InfoBox from "../../basics/info-box/InfoBox";
import CageMiddleFullWidth from "./CageMiddleFullWidth";
import {withTranslation} from "react-i18next";
import {ChamberSettlement, getSettlement, getSettlementList} from "../../../selectors/settlementSelector";
import {getFakeExpeditions} from "../../../demo/cage/fakeFunctions";

export class Cage extends React.Component {

    constructor(props) {
        super(props);
        let cageLocations = props.cage.getLocation();
        let count = 0;
        for (let loc of cageLocations) {
            let animals = animalsDB.getAllAnimalsForLocation(loc, this.getLevel(loc));
            for (let animal of animals) {
                count += animal.AnmCnt;
            }
        }
        let location = cageLocations.map(loc => getLocationName(loc)).join(", ");
        this.container = React.createRef();
        let rfids = this.getRFIDs(0);
        let additionalText = `${props.cage.Address}/${props.cage.Address.toString(16)} ${getInterfName(props.cage.Interface)}`;
        const expeditions = this.getExpeditions(props);
        console.log(expeditions);
        this.state = {
            location,
            collapse: false,
            chart: "main",
            count,
            insertion: 0,
            date: moment.utc().startOf("day"),
            rfid: rfids.length > 0 ? rfids[0].value : "",
            additionalText,
            showAlias: getShowAliases(),
            showLoading: !this.props.shadows.get(this.props.cage.DevID),
            expeditions,
            selectedExpedition: expeditions[0] ? expeditions[0].value : null,
            settlement: props.settlement.data || null,
            noInsertionInDevice: false
        }
    }

    getLevel(loc) {
        if (loc.FarmID) return Level.FARM;
        if (loc.BgID) return Level.BUILDING;
        if (loc.SID) return Level.SECTOR;
        if (loc.CID) return Level.CHAMBER;
        if (loc.BID) return Level.BOX;
    }

    checkIfNeedToGetAggregatedData(startTime, stopTime) {
        if (!isUsingFakeData()) {
            let start = moment.utc(startTime);
            let diff = 1;
            if (stopTime) {
                let stop = moment.utc(stopTime);
                diff = stop.diff(start, "days") + 1;
            }
            let aggData = this.props.cageData.get(this.props.cage.DevID);
            let hasData = !!aggData;
            if (hasData) {
                for (let i = 0; i < diff; i++) {
                    let time = start.clone().add(i, "days").toDate().getTime();
                    if (aggData.filter(item => item.AggTi === time).length === 0) {
                        hasData = false;
                        break;
                    }
                }
            }
            if (!hasData) {
                let obj;
                if (stopTime) {
                    obj = {
                        AggDataTimeFrom: startTime,
                        AggDataTimeTo: stopTime
                    };
                } else {
                    obj = {
                        AggDataTime: startTime
                    }
                }
                this.props.dispatch(getAggregatedData(this.props.cage, obj))
            }
        }

    }

    checkIfNoStartedInsertionInDevice(settlement) {
        try {
            if (isUsingFakeData()) return false;
            const {DtaStartTime} = settlement;
            const {cage} = this.props;
            console.log("checkIfNoStartedInsertionInDevice", !cage.Settings.Insertions.find(item => item.StartTime === DtaStartTime))
            return !cage.Settings.Insertions.find(item => item.StartTime === DtaStartTime);
        } catch (e) {
            return false;
        }
    }

    componentDidMount() {
        this.startLoading();
        const {settlement} = this.state;
        if (settlement) {
            this.checkIfNeedToGetAggregatedData(settlement.DtaStartTime, settlement.DtaEndTime || new Date().getTime());
            this.setState({
                noInsertionInDevice: this.checkIfNoStartedInsertionInDevice(settlement)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.settlement.data, prevProps.settlement.data) && !this.state.settlement) {
            let settlement = this.props.settlement.data;
            this.setState({
                settlement,
                noInsertionInDevice: this.checkIfNoStartedInsertionInDevice(settlement)
            });
            this.checkIfNeedToGetAggregatedData(settlement.DtaStartTime, settlement.DtaEndTime || new Date().getTime());
        }
        if (!isEqual(this.props.cage, prevProps.cage)) {
            this.setState({
                noInsertionInDevice: this.checkIfNoStartedInsertionInDevice(this.state.settlement)
            });
        }
    }

    startLoading = () => {
        if (this.state.showLoading) {
            this.timer = setTimeout(() => {
                this.setState({showLoading: false})
            }, 10000)
        }
    };

    stopLoading = () => {
        clearTimeout(this.timer);
        this.setState({showLoading: false})
    };

    getExpeditions(props) {
        return (isUsingFakeData() ? getFakeExpeditions() : props.cage.getExpeditionList()).sort((a, b) => b.startDate - a.startDate).map(row => ({
            name: `${moment(row.startDate).format("DD.MM.YYYY HH:mm")} - ${row.endDate ? moment(row.endDate).format("DD.MM.YYYY HH:mm") : "..."}`,
            value: row
        }));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.showLoading && nextProps.shadows.get(nextProps.cage.DevID)) {
            this.stopLoading();
        }
        if (!isEqual(this.props.cage, nextProps.cage)) {
            const expeditions = this.getExpeditions(nextProps)
            this.setState({
                expeditions,
                selectedExpedition: expeditions[0] ? expeditions[0].value : null
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    onClick = () => {
        this.setState(state => ({
            collapse: !state.collapse
        }))
    };

    onButtonClick = e => {
        e.stopPropagation();
        // TODO przechodzenie do komory
    };

    onChartChange = value => {
        let obj = {
            chart: value
        };
        if (value === "rfid") {
            let rfids = this.getRFIDs(this.state.settlement);
            obj.rfid = rfids.length > 0 ? rfids[0].value : ""
        }
        this.setState(obj)
    };

    getShadow = () => {
        try {
            return this.props.shadows.get(this.props.cage.DevID);
        } catch (e) {
            return undefined;
        }
    };

    getWorkType = (workType) => {
        if (workType !== undefined) {
            return this.props.t(`chamber.separationCageInfo.work.${workType}`);
        }
        return "-"
    };

    getGain = (awh) => {
        try {
            let cageData = this.props.cageData.get(this.props.cage.DevID);
            cageData.sort((a, b) => b.AggTi - a.AggTi);
            let data = cageData[1];
            if (data) {
                let averageWeight = awh || cageData[0].AggDt[0].WeightExAll || 0;
                let weightBefore = data.AggDt[0].WeightExAll || 0;
                let diff = averageWeight - weightBefore;
                let day = averageWeight ? moment.utc().startOf("day") : moment.utc(cageData[0].AggTi);
                let daysBetween = day.diff(data.AggTi, "days");
                if (diff > 0) return (diff / daysBetween).toFixed(1);
                return 0;
            }
            return "-";
        } catch (e) {
            console.error(e);
            return "-";
        }
    };

    getAverageWeight = (awh, place) => {
        if (awh) return awh;
        try {
            let cageData = this.props.cageData.get(this.props.cage.DevID);
            cageData.sort((a, b) => b.AggTi - a.AggTi);
            let data = cageData[0];
            switch (place) {
                case 0:
                    return data.AggDt[0].WeightExAll || 0;
                case 1:
                    return data.AggDt[0].WeightExLeft;
                case 2:
                    return data.AggDt[0].WeightExMid;
                case 3:
                    return data.AggDt[0].WeightExRight;
                default:
                    return 0;
            }
        } catch (e) {
            return "-"
        }
    };

    getSettlementDay = () => {
        try {
            const {settlement: {data: settlement}} = this.props;
            return moment.utc().startOf("day").diff(settlement.DtaStartTime, "days") + 1;
        } catch (e) {
            return "-";
        }
    };

    getAveragePasses = (shadow) => {
        const {count} = this.state;
        let counter = shadow && shadow.counters && shadow.counters.all;
        if (count && counter) {
            return (counter / count).toFixed(1);
        }
        return "-";
    };

    getCurrentWeight = shadow => {
        try {
            return convertWeightUnitTo(shadow.weight, {
                unit: UnitTypes.MEDIUM,
                showUnit: true,
                fixed: 1
            });
        } catch (e) {
            return `- ${getUnit("weight", UnitTypes.MEDIUM)}`;
        }
    };

    getLastClosedWeight = shadow => {
        try {
            return convertWeightUnitTo(shadow.averageWeights.closed, {
                unit: UnitTypes.MEDIUM,
                showUnit: true,
                fixed: 1
            });
        } catch (e) {
            return `- ${getUnit("weight", UnitTypes.MEDIUM)}`;
        }
    };

    getTooltipContent(shadow) {
        if (!shadow) return "Brak połączenia";
        let data = [];
        let max = 0;
        try {
            for (let key in shadow.metadata) {
                if (shadow.metadata[key] > max) max = shadow.metadata[key];
            }
        } catch (e) {
            console.error(e);
        }
        data.push(
            <React.Fragment key={"0.0"}>
                <div>
                    {this.props.t("lastUpdate")}:
                    <div><strong>{moment(shadow.arriveTime).format("DD.MM.YYYY HH:mm")}</strong></div>
                </div>
                <div>
                    {this.props.t("deviceLastAnswer")}:
                    <div><strong>{moment(max).format("DD.MM.YYYY HH:mm")}</strong></div>
                </div>
            </React.Fragment>
        );
        if (this.isError(shadow)) {
            for (let i = 0; i < shadow.errors.length; i++) {
                if (shadow.errors[i]) data.push(<div
                    key={i}>{this.props.t(`chamber.separationCageInfo.errorTexts.${i}`)}</div>);
            }
        }
        return data;
    }

    getDoorStatus = (sensor1, sensor2) => {
        if (sensor1 && !sensor2) return cageStatus.CLOSED;
        if (!sensor1 && sensor2) return cageStatus.OPENED;
        return cageStatus.MIDDLE;
    };

    weightValueFormatter = value => {
        return convertWeightUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 1
        })
    };

    timeValueFormatter = value => {
        return moment(value).format("HH:mm");
    };

    isError(shadow) {
        try {
            return shadow.errors.reduce((a, b) => a + b, 0) > 0;
        } catch (e) {
            return true;
        }
    }

    getMainItem(shadow) {
        if (shadow && shadow.configuration.workType === 5) {
            return (
                <div
                    className={shadow.expeditionData.animalsNumber >= shadow.expeditionData.counter ? "device-main-item expedition success" : "device-main-item expedition"}>
                    <CircularProgressBar
                        monoColor={true}
                        percentage={shadow.expeditionData.animalsNumber ? ((shadow.expeditionData.counter * 100) / shadow.expeditionData.animalsNumber) : 0}
                        text={shadow.expeditionData.animalsNumber ? `${shadow.expeditionData.counter}/${shadow.expeditionData.animalsNumber}` : shadow.expeditionData.counter}>
                    </CircularProgressBar>
                    <small>{convertWeightUnitTo(shadow.expeditionData.minWeight, {
                        unit: UnitTypes.MEDIUM,
                        showUnit: false,
                        fixed: 1
                    })}-{convertWeightUnitTo(shadow.expeditionData.maxWeight, {
                        unit: UnitTypes.MEDIUM,
                        showUnit: true,
                        fixed: 1
                    })}
                    </small>
                </div>
            )
        }
        let averageWeight = this.getAverageWeight(shadow && shadow.averageWeights.all, 0);
        return (
            <div className="device-main-item">
                <small>{this.props.t("avgShort")}</small>
                {averageWeight && averageWeight !== "-" ? convertWeightUnitTo(averageWeight, {
                    unit: UnitTypes.MEDIUM,
                    showUnit: false,
                    fixed: 1
                }) : "-"} <small>{getUnit("weight", UnitTypes.MEDIUM)}</small>
            </div>
        );
    }

    onSelectCage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onSelect(this.props.cage);
    };

    onSettlementChange = value => {
        let obj = {
            settlement: value
        };
        let rfids = this.getRFIDs(value);
        if (rfids.length > 0) {
            obj.rfid = rfids[0].value;
        }
        this.setState(obj);
        this.checkIfNeedToGetAggregatedData(value.DtaStartTime, value.DtaEndTime || new Date().getTime());
    };

    onCompareSettlementsClick = value => {
        this.props.show(CompareInsertionsModalName, {cage: this.props.cage, chart: this.state.chart})
    }

    createSettlementSelect(name) {
        const {settlements, t} = this.props;
        const {settlement} = this.state;
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    {
                        settlements.length > 1 &&
                        <Button buttonStyle={"bordered"}
                                onClick={this.onCompareSettlementsClick}>{t("chamber.separationCageInfo.compareInsertions")}</Button>
                    }
                    <Select clearButton={false} value={settlement} options={settlements.map(s => ({
                        name: s.DtaEndTime ? `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(s.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ...`,
                        value: s
                    }))} onChange={this.onSettlementChange}/>
                </div>
            </div>
        )
    }

    setDate(date) {
        if (date.diff(moment.utc().startOf("day"), "days") <= 0) {
            this.setState({
                date
            });
            this.checkIfNeedToGetAggregatedData(date.toDate().getTime());
        }
    }

    onDateChange = value => {
        let date = moment.utc(value);
        this.setDate(date);
    };

    onLowerDateClick = () => {
        let date = this.state.date.clone().subtract(1, "day");
        this.setDate(date);
    };

    onAddDateClick = () => {
        let date = this.state.date.clone().add(1, "day");
        this.setDate(date);
    };

    onCompareDayClick = () => {
        this.props.show(CompareDayDataModalName, {device: this.props.cage, chart: this.state.chart});
    }

    createDatePicker(name, showCompare) {
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    {
                        showCompare &&
                        <Button buttonStyle={"bordered"}
                                onClick={this.onCompareDayClick}>{this.props.t("chamber.separationCageInfo.compareDays")}</Button>
                    }
                    <div className="chart-controls-date">
                        <Button icon={<i className="fas fa-chevron-left"/>} onClick={this.onLowerDateClick}/>
                        <Input type="date" value={this.state.date.format("YYYY-MM-DD")} onChange={this.onDateChange}
                               max={moment().format("yyyy-MM-dd")}/>
                        <Button icon={<i className="fas fa-chevron-right"/>} onClick={this.onAddDateClick}/>
                    </div>
                </div>
            </div>
        )
    }

    onCollapseExit = () => {
        this.setState({
            chart: "main"
        })
    };

    getExpeditionWorkType(shadow) {
        const {t} = this.props;
        let workType = shadow.expeditionData.workType;
        console.log(workType, shadow);
        switch (workType) {
            case 1:
                return t("modals.cageWorkMode.sepMode");
            case 2:
                return t("left") + " " + t("modals.cageWorkMode.exitOpen");
            case 3:
                return t("right") + " " + t("modals.cageWorkMode.exitOpen");
            case 4:
                return t("modals.cageWorkMode.altOpen");
            default:
                return "-";
        }
    }

    onExpandClick = (type) => {
        this.props.show(CageExpandDataModalName, {
            cage: this.props.cage,
            type
        });
    };

    getRFIDPasses() {
        try {
            let aggData = this.props.cageData.get(this.props.cage.DevID);
            let today = moment.utc().startOf("day").toDate().getTime();
            let dataForToday = aggData.find(item => item.AggTi === today);
            if (dataForToday) {
                let passes = [];
                for (let rfid of dataForToday.AggDt[0].RFIDPass) {
                    for (let pass of rfid.P) {
                        passes.push({
                            weight: pass.W,
                            time: pass.T,
                            RFID: rfid.RFID
                        })
                    }
                }
                passes.sort((a, b) => b.time - a.time);
                return passes;
            }
            return [];
        } catch (e) {
            return [];
        }
    }

    getRFIDs(settlement, props = this.props) {
        try {
            let aggData = props.cageData.get(this.props.cage.DevID);
            if (settlement.DtaEndTime) {
                aggData = aggData.filter(item => item.AggTi >= settlement.DtaStartTime && item.AggTi <= settlement.DtaEndTime);
            } else {
                aggData = aggData.filter(item => item.AggTi >= settlement.DtaStartTime);
            }
            let rfids = [];
            for (let data of aggData) {
                for (let pass of data.AggDt[0].RFIDPass) {
                    rfids.push({
                        name: pass.RFID,
                        value: pass.RFID
                    })
                }
            }
            rfids = uniqBy(rfids, "value");
            rfids.sort((a, b) => +a.value - +b.value);
            return rfids;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    onRFIDChange = value => {
        this.setState({
            rfid: value
        })
    };

    getCurrentRFIDIndex(rfids) {
        return findIndex(rfids, o => o.value === this.state.rfid);
    }

    onLeftRFIDClick = (rfids, index) => {
        if (index >= 0) {
            this.setState({
                rfid: rfids[index - 1].value
            })
        }
    };

    onRightClick = (rfids, index) => {
        if (index >= 0) {
            this.setState({
                rfid: rfids[index + 1].value
            })
        }
    }

    createRFIDPicker(name) {
        const {settlements} = this.props;
        const {settlement} = this.state;
        let rfids = this.getRFIDs(settlement);
        let rfidIndex = this.getCurrentRFIDIndex(rfids);
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    <Select value={settlement} options={settlements.map(s => ({
                        name: s.DtaEndTime ? `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(s.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ...`,
                        value: s
                    }))} onChange={this.onSettlementChange}/>
                    <div className="chart-controls-date">
                        <Button icon={<i className="fas fa-chevron-left"/>}
                                onClick={() => this.onLeftRFIDClick(rfids, rfidIndex)} disabled={rfidIndex === 0}/>
                        <Select value={this.state.rfid} options={rfids} onChange={this.onRFIDChange}/>
                        <Button icon={<i className="fas fa-chevron-right"/>}
                                onClick={() => this.onRightClick(rfids, rfidIndex)}
                                disabled={rfidIndex === rfids.length - 1}/>
                    </div>
                </div>
            </div>
        )
    }

    getName() {
        const {cage: {Alias}} = this.props;
        const {location, additionalText, showAlias} = this.state;
        if (showAlias) {
            if (Alias) return Alias;
        }
        return location || additionalText;
    }

    onContextMenu = e => {
        this.props.onRightClick(e, this.props.cage);
        return false;
    };

    onSelectedExpeditionChange = value => {
        this.setState({
            selectedExpedition: value
        })
    };

    getMiddlePasses() {
        try {
            const {cageData, cage} = this.props;
            const {selectedExpedition} = this.state;
            let aggData = cageData.get(cage.DevID);
            let startDate = moment.utc(selectedExpedition.startDate);
            let endDate = selectedExpedition.endDate ? moment.utc(selectedExpedition.endDate).endOf("day") : null;
            let startDateStartOfDay = startDate.clone().startOf("day");
            let endDateStartOfDay = endDate ? endDate.clone().startOf("day") : null;
            aggData = aggData.filter(item => {
                if (item.AggTi < startDateStartOfDay.toDate().getTime()) return false;
                return !(endDate && item.AggTi > endDateStartOfDay.toDate().getTime());
            });
            let array = [];
            //cisiu mowil ze T jest lokalne a nie utc
            let startFrom0 = moment(0).hour(startDate.hour()).minute(startDate.minute());
            let endFrom0 = endDate ? moment(0).hour(endDate.hour()).minute(endDate.minute()) : null;
            let expeditionsBetween = (isUsingFakeData() ? [] : cage.Settings.Expeditions).filter(item => item.StartDate >=
                selectedExpedition.startDate &&
                (!selectedExpedition.endDate || item.EndDate <= selectedExpedition.endDate)
            );
            for (let [index, row] of aggData.entries()) {
                let data = row.AggDt[0].ExpMidPass.filter(item => {
                    if (item.T < startFrom0.toDate().getTime()) return false;
                    return !(index === aggData.length - 1 && endFrom0 && item.T > endFrom0.toDate().getTime());
                });
                let expeditionsInDay = expeditionsBetween.filter(item => moment(item.StartDate).isSame(row.AggTi, "day"));
                for (let expedition of expeditionsInDay) {
                    let time = moment(expedition.StartDate);
                    let timeFrom0 = moment(0).hour(time.hour()).minute(time.minute());
                    data.push({
                        T: timeFrom0.toDate().getTime(),
                        ...expedition,
                        fullWidth: true,
                    });
                }
                if (data.length > 0) {
                    array.push({
                        fullWidth: true,
                        date: row.AggTi
                    });
                }
                data.sort((a, b) => b.T - a.T);
                array = [...array, ...data];
            }

            return array;
        } catch (e) {
            console.error(e)
            return [];
        }
    }


    render() {
        const {collapse, chart, count, settlement, date, rfid, showLoading, expeditions, selectedExpedition, noInsertionInDevice} = this.state;
        const {cage, selected, settlement: {state}, t} = this.props;
        const hasMarker = !!get(cage, 'Settings.Marker', []).filter(o => o).length
        let shadow = this.getShadow();
        let headers = [
            {
                name: t("currentWeight"),
                field: "weight",
                valueFormatter: this.weightValueFormatter
            },
            {
                name: "RFID",
                field: "RFID",
                rtl: true
            },
            {
                name: t("time"),
                field: "time",
                valueFormatter: this.timeValueFormatter
            }
        ];
        let middleHeaders = [
            {
                name: t("currentWeight"),
                field: "W",
                valueFormatter: this.weightValueFormatter
            },
            {
                name: t("time"),
                field: "T",
                valueFormatter: this.timeValueFormatter
            }
        ];
        let options = [
            {
                name: t("cage"),
                value: "main"
            },
            {
                name: t("chamber.separationCageInfo.middlePasses"),
                value: "worktype"
            },
            {
                name: t("chamber.separationCageInfo.weightChart"),
                value: "weight"
            },
            {
                name: t("chamber.separationCageInfo.gainChart"),
                value: "gain"
            },
            {
                name: t("chamber.separationCageInfo.passesChart"),
                value: "dayPasses"
            },
            {
                name: t("chamber.separationCageInfo.weightPassesChart"),
                value: "passes"
            },
            {
                name: t("chamber.separationCageInfo.hourlyChart"),
                value: "hourly"
            },
            {
                name: "RFID",
                value: "rfid"
            },
            {
                name: t("chamber.separationCageInfo.calculator"),
                value: "calc"
            }
        ];
        if (hasMarker) {
            options.push({
                name: t("marker"),
                value: "marker"
            })
        }
        let averageWeight = this.getAverageWeight(shadow && shadow.averageWeights && shadow.averageWeights.all, 0);
        let averageWeightLeft = this.getAverageWeight(shadow && shadow.averageWeights && shadow.averageWeights.left, 1);
        let averageWeightMiddle = this.getAverageWeight(shadow && shadow.averageWeights && shadow.averageWeights.middle, 2);
        let averageWeightRight = this.getAverageWeight(shadow && shadow.averageWeights && shadow.averageWeights.right, 3);
        let gain = this.getGain(shadow && shadow.averageWeights && shadow.averageWeights.all);
        let name = this.getName();
        let status = !shadow ? "warning" : (this.isError(shadow) ? "error" : "success");
        let icon = showLoading ? "fas fa-spin fa-circle-notch" : (!shadow ? "fas fa-wifi-slash fa-fw" : (this.isError(shadow) ? "fas fa-exclamation fa-fw" : "fas fa-cog fa-spin fa-fw"));
        let isBlurred = state === ChamberSettlement.SHOW_SETTLEMENT || state === ChamberSettlement.NO_SHOW || noInsertionInDevice;
        return (
            <RowComponent className={collapse ? "device-item opened" : "device-item"} showWorking isError={!shadow}
                          tooltipContent={this.getTooltipContent(shadow)} onContextMenu={this.onContextMenu}>
                <div className="device-info" onClick={this.onClick}>
                    <div className={`device-main dev-ca ${status}`}>
                        <div className="device-main-location" title={name}>{name}</div>
                        {this.getMainItem(shadow)}
                        <div
                            className="device-main-item-alternate">{t("worktype")}: {this.getWorkType(shadow && shadow.configuration.workType)}</div>
                        <ScreenSize>
                            {
                                ({windowWidth}) => (
                                    <Tooltip tooltipContent={this.getTooltipContent(shadow)}
                                             placement={windowWidth >= BreakPoint.SM ? "right" : "left"}
                                             type={status}>
                                        <div className="device-main-working">
                                            <i className={icon}/>
                                        </div>
                                    </Tooltip>
                                )
                            }
                        </ScreenSize>

                        <div onClick={this.onSelectCage} className="device-main-checkbox show-on-small-device sm">
                            <Checkbox label={""} readOnly checked={selected} id={cage.DevID}/>
                        </div>
                    </div>
                    <div className="device-info-main">
                        {/*<IconValue icon={WorkType} name={this.props.content.workType}*/}
                        {/*           value={this.getWorkType(shadow && shadow.configuration.workType)}/>*/}
                        <div className={`device-info-main-first${isBlurred ? " blur" : ""}`}>
                            <IconValue icon={Weight} name={t("chamber.separationCageInfo.weight")}
                                       value={this.getCurrentWeight(shadow)}/>
                            <IconValue icon={LastWeight} name={t("chamber.separationCageInfo.lastWeight")}
                                       value={this.getLastClosedWeight(shadow)}/>
                            <IconValue icon={AverageWeight}
                                       name={t("chamber.separationCageInfo.averageWeightShort")}
                                       value={averageWeight && averageWeight !== "-" ? convertWeightUnitTo(averageWeight, {
                                           unit: UnitTypes.MEDIUM,
                                           showUnit: true,
                                           fixed: 1
                                       }) : `- ${getUnit("weight", UnitTypes.MEDIUM)}`}/>
                            <IconValue icon={Gain} name={t("gain")}
                                       value={gain && gain !== "-" ? convertWeightUnitTo(gain, {
                                           unit: UnitTypes.MEDIUM,
                                           showUnit: true,
                                           fixed: 1
                                       }) : `- ${getUnit("weight", UnitTypes.MEDIUM)}`}/>
                        </div>
                        <div className={`device-info-main-second${isBlurred ? " blur" : ""}`}>
                            <IconValue icon={AnimalNumber}
                                       name={t("animalCount")} value={count}/>
                            <IconValue icon={FatteningDay}
                                       name={t("chamber.separationCageInfo.fatteningDay")}
                                       value={this.getSettlementDay()}/>
                            <IconValue icon={AveragePassesPerAnimal}
                                       name={t("chamber.separationCageInfo.averagePasses")}
                                       value={this.getAveragePasses(shadow)}/>
                        </div>
                        <div className={`device-info-main-third${isBlurred ? " blur" : ""}`}>
                            <CageSideData name={t("left")}
                                          weight={averageWeightLeft && averageWeightLeft !== "-" ? convertWeightUnitTo(averageWeightLeft, {
                                              unit: UnitTypes.MEDIUM,
                                              showUnit: true,
                                              fixed: 1
                                          }) : `- ${getUnit("weight", UnitTypes.MEDIUM)}`}
                                          passes={shadow && (shadow.counters.left || shadow.counters.middle === 0) ? shadow.counters.left : "-"}/>
                            <CageSideData name={t("middle")}
                                          weight={averageWeightMiddle && averageWeightMiddle !== "-" ? convertWeightUnitTo(averageWeightMiddle, {
                                              unit: UnitTypes.MEDIUM,
                                              showUnit: true,
                                              fixed: 1
                                          }) : `- ${getUnit("weight", UnitTypes.MEDIUM)}`}
                                          passes={shadow && (shadow.counters.middle || shadow.counters.middle === 0) ? shadow.counters.middle : "-"}/>
                            <CageSideData name={t("right")}
                                          weight={averageWeightRight && averageWeightRight !== "-" ? convertWeightUnitTo(averageWeightRight, {
                                              unit: UnitTypes.MEDIUM,
                                              showUnit: true,
                                              fixed: 1
                                          }) : `- ${getUnit("weight", UnitTypes.MEDIUM)}`}
                                          passes={shadow && (shadow.counters.right || shadow.counters.right === 0) ? shadow.counters.right : "-"}/>
                        </div>
                        {
                            isBlurred &&
                            <div className="device-info-main-not-enough-data">
                                {
                                    (state === ChamberSettlement.SHOW_SETTLEMENT || state === ChamberSettlement.NO_SHOW) &&
                                    t("chamber.separationCageInfo.noSettlement")
                                }
                                {
                                    noInsertionInDevice &&
                                    t("chamber.separationCageInfo.noSettlementWeight")
                                }
                            </div>
                        }
                    </div>
                    <div onClick={this.onSelectCage} className="device-info-checkbox hide-on-small-device sm">
                        <Checkbox label={""} checked={selected} readOnly id={cage.DevID}/>
                    </div>
                </div>
                <Collapse in={collapse} unmountOnExit onExited={this.onCollapseExit}>
                    <div className="chart-container">
                        <Select options={options} value={chart} onChange={this.onChartChange} clearButton={false}/>
                        <div className="device-chart" ref={this.container}>
                            {
                                chart === "main" &&
                                <div className="cage-data">
                                    <div className="chart-main-container">
                                        <CageSVG
                                            entrance={this.getDoorStatus(shadow && shadow.sensors.length > 0 ? shadow.sensors[0].value : 0, shadow && shadow.sensors.length > 0 ? shadow.sensors[1].value : 0)}
                                            middle={this.getDoorStatus(shadow && shadow.sensors.length > 0 ? shadow.sensors[2].value : 0, shadow && shadow.sensors.length > 0 ? shadow.sensors[3].value : 0)}
                                            left={this.getDoorStatus(shadow && shadow.sensors.length > 0 ? shadow.sensors[4].value : 0, shadow && shadow.sensors.length > 0 ? shadow.sensors[5].value : 0)}
                                            right={this.getDoorStatus(shadow && shadow.sensors.length > 0 ? shadow.sensors[6].value : 0, shadow && shadow.sensors.length > 0 ? shadow.sensors[7].value : 0)}
                                            weight={this.getCurrentWeight(shadow)}
                                            passes={shadow ? shadow.counters.all : "-"}
                                            passesLeft={shadow ? shadow.counters.left : "-"}
                                            passesMiddle={shadow ? shadow.counters.middle : "-"}
                                            passesRight={shadow ? shadow.counters.right : "-"}
                                            workType={shadow ? shadow.configuration.workType : -1}
                                            cage={this.props.cage}
                                            valves={shadow ? shadow.valves : []}
                                            weightClosed={this.getLastClosedWeight(shadow)}
                                            errors={shadow ? shadow.errors : []}/>
                                    </div>
                                    <div className="cage-info">
                                        <h4 className="justify-content-between">
                                            {t("chamber.separationCageInfo.rfidPasses")}
                                            <Button buttonStyle={"round"}
                                                    icon={<i className="fas fa-expand-arrows-alt"/>}
                                                    onClick={() => this.onExpandClick("RFID")}/>
                                        </h4>
                                        <TableGrid
                                            scrollOnPageChange={false}
                                            data={this.getRFIDPasses().slice(0, 7)}
                                            headers={headers} shouldIndex/>
                                    </div>
                                </div>
                            }
                            {
                                chart === "worktype" &&
                                <>
                                    <h4 className="justify-content-between">
                                        {t("chamber.separationCageInfo.middlePasses")}
                                        <Button buttonStyle={"round"} icon={<i className="fas fa-expand-arrows-alt"/>}
                                                onClick={() => this.onExpandClick("Middle")}/>
                                    </h4>
                                    {
                                        expeditions.length > 0 &&
                                        <div className="expedition-list">
                                            <Select value={selectedExpedition} options={expeditions}
                                                    clearButton={false} onChange={this.onSelectedExpeditionChange}/>
                                            <TableGrid
                                                scrollOnPageChange={false}
                                                data={this.getMiddlePasses()} headers={middleHeaders} shouldIndex
                                                showPagination paginationItems={10}
                                                isFullWidthRow={row => row.fullWidth}
                                                fullWidthRow={<CageMiddleFullWidth/>}/>
                                        </div>
                                    }
                                    {
                                        expeditions.length === 0 &&
                                        <>
                                            <InfoBox
                                                boxColor={"error"}>{t("chamber.separationCageInfo.noExpeditions")}</InfoBox>
                                        </>
                                    }
                                </>
                            }
                            {
                                chart === "weight" &&
                                <>
                                    {this.createSettlementSelect(t("chamber.separationCageInfo.weightChart"))}
                                    <div className="chart-main-container">
                                        <WeightChart cage={cage} settlement={settlement} showExcelButton/>
                                    </div>
                                </>
                            }
                            {
                                chart === "gain" &&
                                <>
                                    {this.createSettlementSelect(t("chamber.separationCageInfo.gainChart"))}
                                    <div className="chart-main-container">
                                        <GainChart cage={cage} settlement={settlement} showExcelButton/>
                                    </div>
                                </>
                            }
                            {
                                chart === "dayPasses" &&
                                <>
                                    {this.createSettlementSelect(t("chamber.separationCageInfo.passesChart"))}
                                    <div className="chart-main-container">
                                        <PassageInDaysChart cage={cage} settlement={settlement} showExcelButton/>
                                    </div>
                                </>
                            }
                            {
                                chart === "passes" &&
                                <>
                                    {this.createDatePicker(t("chamber.separationCageInfo.weightPassesChart"), true)}
                                    <div className="chart-main-container">
                                        <PassageChart cage={cage} date={date}
                                                      container={this.container} showExcelButton/>
                                    </div>
                                </>
                            }
                            {
                                chart === "hourly" &&
                                <>
                                    {this.createDatePicker("Wykres ilości przejść w godzinach")}
                                    <div className="chart-main-container">
                                        <HourlyChart cage={cage} date={date} showExcelButton/>
                                    </div>
                                </>
                            }
                            {
                                chart === "calc" &&
                                <CageCalculator cage={cage}
                                                gain={gain}
                                                weight={this.getAverageWeight(shadow && shadow.averageWeights && shadow.averageWeights.all, 0)}
                                                animalsNumber={count}/>
                            }
                            {
                                chart === "marker" && hasMarker &&
                                <div className="chart-main-container">
                                    <CageMarker cage={cage}/>
                                </div>
                            }
                            {
                                chart === "rfid" &&
                                <>
                                    {this.createRFIDPicker("RFID")}
                                    <div className="chart-main-container">
                                        <RFIDChart cage={cage} RFID={rfid} settlement={settlement}/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Collapse>
            </RowComponent>
        );
    }

}

Cage.propTypes = {
    cage: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onRightClick: PropTypes.func
};

Cage.defaultProps = {
    selected: false,
    onSelect: () => {
    }
};

Cage = withRoles(props => ({roles: [Roles._DEVICE_CAGE], devPlcmnts: extractDevPlcmnts(props.cage)}))(Cage);

Cage = connect((state, props) => {
        const {cage} = props;
        let locations = cage.getLocation();
        let locID = getLocationID(locations[0]);
        return {
            cageData: state.aggregatedData.data,
            shadows: state.shadows.shadows,
            lang: state.language.lang.lang,
            settlement: getSettlement(state, {CID: locID}),
            settlements: getSettlementList(state, {CID: locID})
        }
    },
    dispatch => bindActionCreators({show}, dispatch)
)(Cage);

export default withTranslation()(Cage);

