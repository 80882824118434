import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../loading/LoadingComponent";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForNh3Chart, tickFormatter,} from "../../utils/ChartsUtils";

export class NH3Chart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const currentDay = data.find(item => item.AggTi === +date);
        const {nh3, lastTime} = currentDay ? calculateDataForNh3Chart(currentDay) : {
            nh3: [],
            lastTime: 0
        };
        return {
            data: nh3,
            lastTime
        }
    }


    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const def = [
            {
                color: "black",
                dataKey: "nh3",
                name: t("chamber.chart.nh3"),
                unit: "ppm",
                opacity: 0.2
            }
        ];
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("chamber.chart.nh3")
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [date.startOf("day").toDate().getTime(), "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }} saveAsExcell={"nh3"} tooltipLabelFormatter={tickFormatter} showDomainInput/>
            </React.Fragment>
        )
    }
}

NH3Chart.defaultProps = {
    colors: {
        nh3: "#c018ff",
    },
    connectNulls: true,
    date: moment().subtract(1, "days"),
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

NH3Chart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired,
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

NH3Chart = connect(
    makeMapStateToProps
)(NH3Chart);


export default withTranslation()(NH3Chart);
