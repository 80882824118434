import React, {Component} from 'react';
import {connect} from 'react-redux';
import RowComponent from "../../basics/row-component/RowComponent";
import Checkbox from "../../basics/checkbox/Checkbox";
import PropTypes from "prop-types";
import PigletScale from "../../../beans/devices/PigletScale";
import moment from "moment";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import {Collapse} from "react-bootstrap";
import Select from "../../basics/select/Select";
import {getLocationID, getLocationName} from "../../../utils/BuildingUtils";
import {getShowAliases, isUsingFakeData} from "../../../utils/SettingsUtils";
import {ChamberSettlement, getSettlement, getSettlementList} from "../../../selectors/settlementSelector";
import {isEqual} from "lodash"
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import HistogramPigletScale from "../../charts/HistogramPigletScale";
import HalfUpPigletScale from "../../charts/HalfUpPigletScale";
import MedianPigletScale from "../../charts/MedianPigletScale";
import NormalPigletScale from "../../charts/NormalPigletScale";
import PeaksPigletScale from "../../charts/PeaksPigletScale";
import Weight from "../../../resources/images/cage/weight.svg";
import IconValue from "../../basics/icon-value/IconValue";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import ScreenSize from "../../basics/screen-size/ScreenSize";
import Tooltip from "../../basics/tooltip/Tooltip";
import {BreakPoint} from "../../../constans/breakpointTypes";
import "./_piglet-scale-row.scss"
import GainIcon from "../../../resources/images/cage/gain.svg"
import FatteningDay from "../../../resources/images/cage/fattening_day.svg";
import Filled from "../../../resources/images/silo/fill_day_icon.svg"
import ComposedPigletScaleChart from "../../charts/ComposedPigletScaleChart";
import GainPigletScale from "../../charts/GainPigletScale";
import HmIcon from "../../../resources/images/piglet-scale/hm.svg";
import HmsIcon from "../../../resources/images/piglet-scale/hms.svg";
import HuIcon from "../../../resources/images/piglet-scale/hu.svg";
import HusIcon from "../../../resources/images/piglet-scale/hus.svg";
import MaIcon from "../../../resources/images/piglet-scale/ma.svg";
import MasIcon from "../../../resources/images/piglet-scale/mas.svg";
import NaIcon from "../../../resources/images/piglet-scale/na.svg";
import NasIcon from "../../../resources/images/piglet-scale/nas.svg";
import PsIcon from "../../../resources/images/piglet-scale/ps.svg";
import PssIcon from "../../../resources/images/piglet-scale/pss.svg";
import {fakeAggData} from "../../../demo/porker-scale/fakeFunctions";

function mapStateToProps(state, props) {
    let locations = props.scale.getLocation();
    let id = getLocationID(locations[0]);
    return {
        farm: state.location.farm,
        aggregatedData: state.aggregatedData.data,
        aggregatedDataLoading: state.aggregatedData.fetchingMap,
        settlements: getSettlementList(state, {CID: id}),
        settlement: getSettlement(state, {CID: id})
    };
}

class PigletScaleRow extends Component {

    state = {
        collapse: false,
        chart: "composed",
        location: this.props.scale.getLocation().map(loc => getLocationName(loc)).join(","),
        showAlias: getShowAliases(),
        settlement: this.props.settlement.data,
        loading: true,
        noInsertionInDevice: false,
        gainField: "HM"
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.loading) {
            let currentLoading = nextProps.aggregatedDataLoading.get(nextProps.scale.DevID);
            return {
                loading: typeof currentLoading === "boolean" ? currentLoading : true
            }
        }
        return null;
    }

    componentDidMount() {
        const {settlement} = this.state;
        if(isUsingFakeData()){
            fakeAggData(this.props.scale.DevID)
        }
        if (settlement) {
            this.checkIfNeedAggregatedData(settlement.DtaStartTime, settlement.DtaEndTime || new Date().getTime());
            this.setState({
                noInsertionInDevice: this.checkIfNoStartedInsertionInDevice(settlement)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.settlement.data, prevProps.settlement.data) && !this.state.settlement) {
            let settlement = this.props.settlement.data;
            this.setState({
                settlement,
                noInsertionInDevice: this.checkIfNoStartedInsertionInDevice(settlement)
            });
            this.checkIfNeedAggregatedData(settlement.DtaStartTime, settlement.DtaEndTime || new Date().getTime())
        }
        if (!isEqual(this.props.scale, prevProps.scale)) {
            this.setState({
                noInsertionInDevice: this.checkIfNoStartedInsertionInDevice(this.state.settlement)
            });
        }
    }

    onSelect = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onSelect(this.props.scale);
    };

    checkIfNeedAggregatedData(startTime, stopTime) {
        if (!isUsingFakeData()) {
            let start = moment.utc(startTime);
            let diff = 1;
            if (stopTime) {
                let stop = moment.utc(stopTime);
                diff = stop.diff(start, "days") + 1;
            }
            let aggData = this.props.aggregatedData.get(this.props.scale.DevID);
            let hasData = !!aggData;
            if (hasData) {
                for (let i = 0; i < diff; i++) {
                    let time = start.clone().add(i, "days").toDate().getTime();
                    if (aggData.filter(item => item.AggTi === time).length === 0) {
                        hasData = false;
                        break;
                    }
                }
            }
            if (!hasData) {
                let obj;
                if (stopTime) {
                    obj = {
                        AggDataTimeFrom: startTime,
                        AggDataTimeTo: stopTime
                    };
                } else {
                    obj = {
                        AggDataTime: startTime
                    }
                }
                this.props.dispatch(getAggregatedData(this.props.scale, obj))
            }
        }
    }

    checkIfNoStartedInsertionInDevice(settlement) {
        try {
            const {DtaStartTime} = settlement;
            const {scale} = this.props;
            if (isUsingFakeData()) return false;
            return !scale.Settings.Insertions.find(item => item.StartTime === DtaStartTime);
        } catch (e) {
            return false;
        }
    }

    onCollapseExit = () => {
        this.setState({
            chart: "histogram"
        })
    };

    onClick = () => {
        this.setState(state => ({
            collapse: !state.collapse
        }))
    }

    onChartChange = value => {
        this.setState({
            chart: value
        })
    }

    getName() {
        const {scale: {Alias}} = this.props;
        const {location, showAlias} = this.state;
        if (showAlias) {
            if (Alias) return Alias;
        }
        return location;
    }

    onSettlementChange = settlement => {
        this.setState({
            settlement
        });
        this.checkIfNeedAggregatedData(settlement.DtaStartTime, settlement.DtaEndTime || new Date().getTime());
    }

    onGainFieldChange = value => {
        this.setState({
            gainField: value
        });
    }

    createSettlementSelect(name) {
        const {settlements} = this.props;
        const {settlement} = this.state;
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    <Select value={settlement} options={settlements.map((settlement) => ({
                        name: settlement.DtaEndTime ? `${moment.utc(settlement.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(settlement.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(settlement.DtaStartTime).format("DD.MM.YYYY")} - ...`,
                        value: settlement
                    }))} onChange={this.onSettlementChange} clearButton={false}/>
                </div>
            </div>
        )
    }

    createGainTypeSelect(name) {
        const {settlements, t} = this.props;
        const {settlement, gainField} = this.state;
        return (
            <div className="chart-controls">
                <h4>{name}</h4>
                <div className="chart-controls-container">
                    <Select value={gainField} options={[
                        {
                            name: t("deviceRows.pigletScale.histogram"),
                            value: "HM"
                        },
                        {
                            name: t("deviceRows.pigletScale.histogram") + " - SAVGOL",
                            value: "HMS"
                        },
                        {
                            name: t("deviceRows.pigletScale.halfUp"),
                            value: "HU"
                        },
                        {
                            name: t("deviceRows.pigletScale.halfUp") + " - SAVGOL",
                            value: "HUS"
                        },
                        {
                            name: t("deviceRows.pigletScale.median"),
                            value: "MA"
                        },
                        {
                            name: t("deviceRows.pigletScale.median") + " - SAVGOL",
                            value: "MAS"
                        },
                        {
                            name: t("deviceRows.pigletScale.normal"),
                            value: "NA"
                        },
                        {
                            name: t("deviceRows.pigletScale.normal") + " - SAVGOL",
                            value: "NAS"
                        },
                        {
                            name: t("deviceRows.pigletScale.peaks"),
                            value: "PS"
                        },
                        {
                            name: t("deviceRows.pigletScale.peaks") + " - SAVGOL",
                            value: "PSS"
                        }
                    ]} clearButton={false} onChange={this.onGainFieldChange}/>
                    <Select value={settlement} options={settlements.map((settlement) => ({
                        name: settlement.DtaEndTime ? `${moment.utc(settlement.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(settlement.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(settlement.DtaStartTime).format("DD.MM.YYYY")} - ...`,
                        value: settlement
                    }))} onChange={this.onSettlementChange} clearButton={false}/>
                </div>
            </div>
        )
    }

    getWeight(field, convert = true) {
        const {aggregatedData, scale, settlement: {data: settlement}} = this.props;
        try {
            let data = aggregatedData.get(scale.DevID) || [];
            data = data.filter(item => {
                if (settlement.DtaEndTime && item.AggTi > settlement.DtaEndTime) return false;
                return item.AggTi > settlement.DtaStartTime;
            });
            data.sort((a, b) => b.AggTi - a.AggTi);
            let latest = data[0];
            let latestData = latest.AggDt[latest.AggDt.length - 1];
            if (convert) return convertWeightUnitTo(latestData[field], {unit: UnitTypes.MEDIUM, showUnit: true}) || "-";
            return {weight: latestData[field], time: latest.AggTi}
        } catch (e) {
            return "-";
        }
    }

    getStatus() {
        const {aggregatedData, scale} = this.props;
        return !aggregatedData.get(scale.DevID) ? "warning" : "success";
    }

    getIcon() {
        const {loading} = this.state;
        const {aggregatedData, scale} = this.props;
        if (loading) return "fas fa-spin fa-circle-notch";
        return !aggregatedData.get(scale.DevID) ? "fas fa-wifi-slash fa-fw" : "fas fa-cog fa-spin fa-fw";
    }

    getTooltipContent() {
        const {aggregatedData, scale, t} = this.props;
        let data = aggregatedData.get(scale.DevID);
        if (!data) return t("noConnection");
        data.sort((a, b) => a.AggTi - b.AggTi);
        let latest = data[data.length - 1];
        if (!latest) return t("noData");
        let latestData = latest.AggDt[latest.AggDt.length - 1];
        return (
            <div>
                {t("lastUpdate")}:
                <div><strong>{moment(latestData.T).format("DD.MM.YYYY HH:mm")}</strong></div>
            </div>
        )
    }

    getSubtitle() {
        const {settlement, t} = this.props;
        if (settlement.state === ChamberSettlement.SHOW_SETTLEMENT) {
            return t("deviceRows.pigletScale.noSettlement")
        }
        if (settlement.state === ChamberSettlement.SHOW_SEPARATION) {
            return t("day", {count: 1 + moment().startOf("day").diff(moment(settlement.data.DtaStartTime).startOf("day"), "days")});
        }
    }

    getSettlementWeight(date) {
        try {
            const {scale} = this.props;
            let insertion = scale.Settings.Insertions.find(item => item.StartTime === date);
            return convertWeightUnitTo(insertion.Weight, {
                unit: UnitTypes.MEDIUM,
                showUnit: true
            }) || "-"
        } catch (e) {
            return "-";
        }
    }

    getGain(settlementDate, field = "HM") {
        try {
            const {aggregatedData, scale, settlement: {data: settlement}} = this.props;
            let data = (aggregatedData.get(scale.DevID) || []).slice(0);
            data = data.filter(item => {
                if (settlement.DtaEndTime && item.AggTi > settlement.DtaEndTime) return false;
                return item.AggTi > settlement.DtaStartTime;
            });
            data.sort((a, b) => b.AggTi - a.AggTi);
            let firstItem = data[0];
            let secondItem = data[1];
            let firstWeight = firstItem.AggDt[firstItem.AggDt.length - 1][field];
            let firstDate = firstItem.AggTi;
            let secondWeight = secondItem ? secondItem.AggDt[secondItem.AggDt.length - 1][field] : null;
            let secondDate = secondItem ? secondItem.AggTi : null;
            console.log(firstWeight, secondWeight);
            console.log(firstDate, secondDate);
            // jezeli nie znaleziono drugiej daty to podstaw z zasiedlenia
            if (secondWeight === null) {
                let insertion = scale.Settings.Insertions.find(item => item.StartTime === settlementDate);
                secondWeight = insertion.Weight;
                secondDate = settlementDate;
            }
            let weightDiff = firstWeight - secondWeight;
            let dayDiff = moment(firstDate).diff(secondDate, "days");
            if (weightDiff < 0) return 0;
            return weightDiff / dayDiff;
        } catch (e) {
            return null;
        }
    }

    getGainConverted(gain) {
        let params = {
            unit: UnitTypes.MEDIUM,
            showUnit: true
        }
        return convertWeightUnitTo(gain, params);
    }

    getAge(settlementDate) {
        try {
            const {scale} = this.props;
            let insertion = scale.Settings.Insertions.find(item => item.StartTime === settlementDate);
            return moment.utc().startOf("day").diff(insertion.BirthTime, "days");
        } catch (e) {
            return "-";
        }
    }

    getPlannedSellDate(settlementDate, gain) {
        const {scale, t} = this.props;
        if (gain === 0) {
            return {text: "-", tooltipText: t("deviceRows.pigletScale.plannedSell")}
        }
        try {
            let insertion = scale.Settings.Insertions.find(item => item.StartTime === settlementDate);
            let plannedWeight = insertion.PlannedSellWeight;
            let currentWeight = this.getWeight("HM", false);
            let days = Math.ceil((plannedWeight - currentWeight.weight) / gain);
            if (isNaN(days)) return {text: "-", tooltipText: t("deviceRows.pigletScale.plannedSell")};
            if (days < 0) return {
                text: t("deviceRows.pigletScale.reachedWeight"),
                tooltipText: t("deviceRows.pigletScale.animalsReached", {
                    weight: convertWeightUnitTo(plannedWeight, {
                        unit: UnitTypes.MEDIUM,
                        showUnit: true
                    })
                })
            };
            return {
                text: moment.utc(currentWeight.time).startOf("day").add(days, "days").format("DD.MM.YYYY"),
                tooltipText: t("deviceRows.pigletScale.animalsWillReach", {
                    weight: convertWeightUnitTo(plannedWeight, {
                        unit: UnitTypes.MEDIUM,
                        showUnit: true
                    }),
                    count: days
                })
            };
        } catch (e) {
            return {text: "-", tooltipText: t("deviceRows.pigletScale.plannedSell")};
        }
    }

    render() {
        const {scale, selected, t, onRightClick, settlement: {state: settlementState, data}} = this.props;
        const {collapse, chart, settlement, noInsertionInDevice, gainField} = this.state;
        const options = [
            {
                name: t("deviceRows.pigletScale.composed"),
                value: "composed"
            },
            {
                name: t("gain"),
                value: "gain"
            },
            {
                name: t("deviceRows.pigletScale.histogram"),
                value: "histogram"
            },
            {
                name: t("deviceRows.pigletScale.halfUp"),
                value: "halfUp"
            },
            {
                name: t("deviceRows.pigletScale.median"),
                value: "median"
            },
            {
                name: t("deviceRows.pigletScale.normal"),
                value: "normal"
            },
            {
                name: t("deviceRows.pigletScale.peaks"),
                value: "peaks"
            },
        ]
        let name = this.getName();
        let status = this.getStatus();
        let icon = this.getIcon();
        let subtitle = this.getSubtitle();
        let gain = this.getGain(data ? data.DtaStartTime : 0);
        let currentWeight = this.getWeight("HM");
        let plannedSell = this.getPlannedSellDate(data ? data.DtaStartTime : 0, gain);
        let hmsWeight = this.getWeight("HMS");
        let husWeigth = this.getWeight("HUS");
        let masWeight = this.getWeight("MAS");
        let nasWeight = this.getWeight("NAS");
        let pssWeight = this.getWeight("PSS");
        let isDemo = isUsingFakeData();
        let isBlurred = isDemo ? false : settlementState === ChamberSettlement.SHOW_SETTLEMENT || settlementState === ChamberSettlement.NO_SHOW || noInsertionInDevice;

        return (
            <RowComponent className={collapse ? "device-item piglet-scale opened" : "device-item piglet-scale"}
                          showWorking onContextMenu={e => onRightClick(e, scale)}>
                <div className="device-info" onClick={this.onClick}>
                    <div className={`device-main dev-ps ${status}`}>
                        <div className="device-main-location" title={name}>{name}</div>
                        <div className="device-main-item">
                            {currentWeight}
                        </div>
                        <div className="device-main-item-alternate" title={subtitle}>{subtitle}</div>
                        <ScreenSize>
                            {
                                ({windowWidth}) => (
                                    <Tooltip tooltipContent={this.getTooltipContent()}
                                             placement={windowWidth >= BreakPoint.SM ? "right" : "left"}
                                             type={status}>
                                        <div className="device-main-working">
                                            <i className={icon}/>
                                        </div>
                                    </Tooltip>
                                )
                            }
                        </ScreenSize>

                        <div onClick={this.onSelect} className="device-main-checkbox show-on-small-device sm">
                            <Checkbox label={""} id={scale.DevID} checked={selected} readOnly/>
                        </div>
                    </div>
                    <div className={"device-info-main"}>
                        <div
                            className={`device-info-main-second${isBlurred ? " blur" : ""}`}>
                            <div className="device-info-main-second-name">{scale.Number || "-"}</div>
                            <IconValue icon={Filled} name={t("settlementDate")}
                                       value={data ? moment.utc(data.DtaStartTime).format("DD.MM.YYYY") : "-"}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={FatteningDay} name={t("deviceRows.pigletScale.age")}
                                       value={data ? this.getAge(data.DtaStartTime) : "-"}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={Weight} name={t("deviceRows.pigletScale.settlementWeight")}
                                       value={data ? this.getSettlementWeight(data.DtaStartTime) : "-"}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={GainIcon} name={t("gain")}
                                       value={data ? this.getGainConverted(gain) : "-"}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={Filled} name={plannedSell.tooltipText}
                                       value={plannedSell.text}
                                       disabledTooltip={isBlurred}/>
                        </div>
                        <div className={`device-info-main-third${isBlurred ? " blur" : ""}`}>
                            <IconValue icon={HmIcon} name={t("deviceRows.pigletScale.histogram")}
                                       value={currentWeight}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={HuIcon} name={t("deviceRows.pigletScale.halfUp")}
                                       value={this.getWeight("HU")}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={MaIcon} name={t("deviceRows.pigletScale.median")}
                                       value={this.getWeight("MA")}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={NaIcon} name={t("deviceRows.pigletScale.normal")}
                                       value={this.getWeight("NA")}
                                       disabledTooltip={isBlurred}/>
                            <IconValue icon={PsIcon} name={t("deviceRows.pigletScale.peaks")}
                                       value={this.getWeight("PS")}
                                       disabledTooltip={isBlurred}/>
                        </div>
                        <div className={`device-info-main-third${isBlurred ? " blur" : ""}`}>
                            {!isDemo && <IconValue icon={HmsIcon} name={t("deviceRows.pigletScale.histogram") + " - SAVGOL"}
                                       value={hmsWeight}
                                       disabledTooltip={isBlurred}/>}
                            {!isDemo && <IconValue icon={HusIcon} name={t("deviceRows.pigletScale.halfUp") + " - SAVGOL"}
                                       value={husWeigth}
                                       disabledTooltip={isBlurred}/>}
                            {!isDemo && <IconValue icon={MasIcon} name={t("deviceRows.pigletScale.median") + " - SAVGOL"}
                                       value={masWeight}
                                       disabledTooltip={isBlurred}/>}
                            {!isDemo && <IconValue icon={NasIcon} name={t("deviceRows.pigletScale.normal") + " - SAVGOL"}
                                       value={nasWeight}
                                       disabledTooltip={isBlurred}/>}
                            {!isDemo && <IconValue icon={PssIcon} name={t("deviceRows.pigletScale.peaks") + " - SAVGOL"}
                                       value={pssWeight}
                                       disabledTooltip={isBlurred}/>}
                        </div>
                        {
                            (isBlurred) &&
                            <div className="device-info-main-not-enough-data">
                                {
                                    (settlementState === ChamberSettlement.SHOW_SETTLEMENT || settlementState === ChamberSettlement.NO_SHOW) &&
                                    t("deviceRows.pigletScale.noSettlement")
                                }
                                {
                                    noInsertionInDevice &&
                                    t("deviceRows.pigletScale.noInsertionInDevice")
                                }
                            </div>
                        }
                    </div>
                    <div onClick={this.onSelect} className="device-info-checkbox hide-on-small-device sm">
                        <Checkbox label={""} id={scale.DevID} checked={selected} readOnly/>
                    </div>
                </div>
                <Collapse in={collapse} unmountOnExit onExited={this.onCollapseExit} mountOnEnter>
                    <div className="chart-container">
                        <Select options={options} value={chart} onChange={this.onChartChange} clearButton={false}/>
                        <div className="device-chart">
                            {
                                chart === "histogram" &&
                                <>
                                    {this.createSettlementSelect(t("deviceRows.pigletScale.histogram"))}
                                    <div className="chart-main-container">
                                        <HistogramPigletScale settlement={settlement} pigletScale={scale}/>
                                    </div>
                                </>
                            }
                            {
                                chart === "halfUp" &&
                                <>
                                    {this.createSettlementSelect(t("deviceRows.pigletScale.halfUp"))}
                                    <div className="chart-main-container">
                                        <HalfUpPigletScale settlement={settlement} pigletScale={scale}/>
                                    </div>
                                </>
                            }
                            {
                                chart === "median" &&
                                <>
                                    {this.createSettlementSelect(t("deviceRows.pigletScale.median"))}
                                    <div className="chart-main-container">
                                        <MedianPigletScale settlement={settlement} pigletScale={scale}/>
                                    </div>
                                </>
                            }
                            {
                                chart === "normal" &&
                                <>
                                    {this.createSettlementSelect(t("deviceRows.pigletScale.normal"))}
                                    <div className="chart-main-container">
                                        <NormalPigletScale settlement={settlement} pigletScale={scale}/>
                                    </div>
                                </>
                            }
                            {
                                chart === "peaks" &&
                                <>
                                    {this.createSettlementSelect(t("deviceRows.pigletScale.peaks"))}
                                    <div className="chart-main-container">
                                        <PeaksPigletScale settlement={settlement} pigletScale={scale}/>
                                    </div>
                                </>
                            }
                            {
                                chart === "composed" &&
                                <>
                                    {this.createSettlementSelect(t("deviceRows.pigletScale.composed"))}
                                    <div className="chart-main-container">
                                        <ComposedPigletScaleChart settlement={settlement} pigletScale={scale}/>
                                    </div>
                                </>
                            }
                            {
                                chart === "gain" &&
                                <>
                                    {this.createGainTypeSelect(t("gain"))}
                                    <div className="chart-main-container">
                                        <GainPigletScale pigletScale={scale} settlement={settlement} field={gainField}/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Collapse>
            </RowComponent>
        );
    }
}

PigletScaleRow.propTypes = {
    scale: PropTypes.instanceOf(PigletScale),
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(PigletScaleRow);
