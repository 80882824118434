import React from "react";
import PropTypes from "prop-types"
import {getLatestMetadata} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import moment from "moment";
import {convertPressureUnitTo, convertTemperatureUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import Ventilation from "../../../resources/images/climate/fan_symbol_icon.svg"
import Humidity from "../../../resources/images/climate/humidity_symbol_icon.svg"
import Pressure from "../../../resources/images/climate/pressure_symbol_icon.svg"
import MinAlarm from "../../../resources/images/climate/alarm_min_icon.svg";
import MaxAlarm from "../../../resources/images/climate/alarm_max_icon.svg";
import Cooling from "../../../resources/images/climate/cooling_icon.svg";
import Heating from "../../../resources/images/climate/heating_icon.svg";
import MinVentilation from "../../../resources/images/climate/ventilation_min_icon.svg";
import MaxVentilation from "../../../resources/images/climate/ventilation_max_icon.svg";
import {get} from "lodash";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import {toggleExpandByID, toggleSelectByID} from "../../../actions/mainTabActions";
import {onElementPainted} from "../../../utils/DOMUtils";
import {makeIsExpanded, makeIsSelected} from "../../../selectors/device-rows/rowSelector";
import DeviceRow from "../DeviceRow";
import Tooltip from "../../Tooltip";
import {withTranslation} from "react-i18next";
import Card from "../../basics/card/Card";
import {Col} from "react-bootstrap";

function makeMapStateToProps() {
    const getShadow = makeGetShadowByDevice()
    const isSelected = makeIsSelected();
    const isExpanded = makeIsExpanded();
    const params = {type: "climates"};
    return function mapStateToProps(state, props) {
        params.id = props.id
        return {
            ...getShadow(state, props.device),
            selected: isSelected(state, params),
            expanded: isExpanded(state, params)
        }
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onSelect: (e) => {
            e.stopPropagation();
            dispatch(toggleSelectByID("climates", ownProps.id))
            return false;
        },
        onExpand: () => dispatch(toggleExpandByID("climates", ownProps.id))
    }
}

export class ClimateRow extends React.Component {


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.expanded !== this.props.expanded) {
            if (!this.props.expanded) {
                onElementPainted(this.props.measure)
            }
        }
    }

    getValues = () => {
        const {shadow, name, t, device, fetching} = this.props;
        const temperatureUnit = getUnit("temperature", UnitTypes.SMALL);
        const errors = this.getErrors();
        const workType = get(shadow, "ambientConditions.workType", 0);
        const active = !!(!shadow || workType !== 0)
        const temperatureFormatter = (value, rawValue = true) => convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: !!rawValue
        })
        const pressureUnit = getUnit("pressure");
        const pressureFormatter = (value) => convertPressureUnitTo(value, {
            showUnit: false,
            fixed: 1,
            acceptNil: true,
            rawValue: false
        })
        return {
            upperTitle: name,
            title: temperatureFormatter(get(shadow, "ambientConditions.averageCurrentAirTemperature"), false),
            lowerTitle: active ? `${t("chamber.chart.temperatureDesired")}: ${temperatureFormatter(get(shadow, "configuration.requestedTemperature"), false)}` : t("inactive"),
            number: device.Number || "-",
            icon: {
                errors,
                isLoading: fetching,
                isConnected: !!shadow,
                dateTime: getLatestMetadata(get(shadow, "metadata")),
                isDisabled: !active,
                isAlerted: !!errors.length
            },
            params: [
                {
                    value: get(shadow, "ambientConditions.generalVentilationEfficiency"),
                    title: t("chamber.chart.ventilation"),
                    icon: Ventilation,
                    unit: "%",
                    // valueFormatter: calculateVentilation,
                    show: true
                },
                {
                    //todo: zmienic na aktualna temp zadana
                    value: get(shadow, "configuration.requestedTemperature") - get(shadow, "configuration.alarms.minimumTemperatureAlarmDeviation"),
                    title: t("minTempAlarm"),
                    icon: MinAlarm,
                    unit: temperatureUnit,
                    valueFormatter: temperatureFormatter,
                    show: true
                },
                {
                    //todo: zmienic na aktualna temp zadana
                    value: get(shadow, "configuration.requestedTemperature") + get(shadow, "configuration.alarms.maximumTemperatureAlarmDeviation"),
                    title: t("maxTempAlarm"),
                    icon: MaxAlarm,
                    unit: temperatureUnit,
                    valueFormatter: temperatureFormatter,
                    show: true
                },
                {
                    //todo: zamienic na aktualna min wentylacje
                    value: get(shadow, "configuration.ventilationConfiguration.minimumVentilation"),
                    title: t("farmView.climate.minVentilation"),
                    icon: MinVentilation,
                    unit: "%",
                    show: true
                },
                {
                    //todo: zamienic na aktualna max wentylacje
                    value: get(shadow, "configuration.ventilationConfiguration.maximumVentilation"),
                    title: t("farmView.climate.maxVentilation"),
                    icon: MaxVentilation,
                    unit: "%",
                    show: true
                },
                {
                    value: get(shadow, "ambientConditions.humidity"),
                    title: t("chamber.chart.humidity"),
                    icon: Humidity,
                    unit: "%"
                },
                {
                    value: get(shadow, "ambientConditions.coolingOn"),
                    title: t("chamber.chart.cooling"),
                    icon: Cooling,
                    valueFormatter: (value) => <i className={`fas fa-fw fa-${value ? "check" : "times"}`}/>
                },
                {
                    value: get(shadow, "ambientConditions.heatingOn"),
                    title: t("settings.heating"),
                    icon: Heating,
                    valueFormatter: (value) => <i className={`fas fa-fw fa-${value ? "check" : "times"}`}/>
                },
                {
                    value: get(shadow, "ambientConditions.pumpPressure"),
                    title: t("chamber.chart.pressure"),
                    icon: Pressure,
                    valueFormatter: pressureFormatter,
                    unit: pressureUnit
                }
            ]
        }
    }

    getErrors = () => {
        const {t, shadow} = this.props;
        try {
            if (shadow) {
                const errors = [];
                const requestedTemp = get(shadow, "configuration.requestedTemperature"); //todo zamienic na aktualna wartosc
                const currentTemp = get(shadow, "ambientConditions.averageCurrentAirTemperature");
                const alarmMin = requestedTemp - get(shadow, "configuration.alarms.minimumTemperatureAlarmDeviation");
                const alarmMax = requestedTemp + get(shadow, "configuration.alarms.maximumTemperatureAlarmDeviation");
                const alarmAbsolute = get(shadow, "configuration.alarms.absoluteAlarm");
                if (currentTemp > alarmAbsolute) return [t("farmView.climate.absoluteAlarm")];
                if (currentTemp > alarmMax) errors.push(t("farmView.climate.errors.1"));
                if (currentTemp < alarmMin) errors.push(t("farmView.climate.errors.0"));
                return errors;
            }
        } catch (e) {
            console.error(e);
        }
        return [];
    }


    icon = ({color, header, icon, dateTime}) => {
        const errors = this.getErrors();

        return (
            <Tooltip tooltipContent={
                <div>
                    <div>{header}</div>
                    {
                        !!dateTime &&
                        <div>
                            <i className="fas fa-sync mr-1"/><b>{moment(dateTime).format("HH:mm:ss DD.MM.YY")}</b>
                        </div>
                    }
                    <div><small>{errors.join("\n")}</small></div>
                </div>
            }
                     placement={"left"}
                     type={color}>
                <div>
                    <i className={icon}/>
                </div>
            </Tooltip>
        )
    }

    onContextMenu = (e) => {
        const {openMenu, device} = this.props;
        e.stopPropagation();
        e.preventDefault();
        e.persist();
        openMenu(e, device);
        return false;
    }

    render() {
        const {selected, onSelect, expanded, measure} = this.props;
        const {upperTitle, title, lowerTitle, params, icon, number} = this.getValues();
        return (
            <Col xs={12}>
                <Card type={"container"} className={"pointer"} selected={selected}>
                    <DeviceRow
                        onClick={onSelect}
                        icon={icon}
                        upperTitle={upperTitle}
                        title={title}
                        lowerTitle={lowerTitle}
                        params={params}
                        expanded={expanded}
                        selected={selected}
                        number={number}
                        showingCallback={measure}
                        onContextMenu={this.onContextMenu}
                    />
                </Card>
            </Col>


        );
    }

}

ClimateRow.propTypes = {
    device: PropTypes.object.isRequired,
    id: PropTypes.string,
    placements: PropTypes.array,
    name: PropTypes.string
};


// ClimateRow = withRoles(props => ({
//     roles: [Roles._DEVICE_CLIMATE],
//     devPlcmnts: extractDevPlcmnts(props.climate)
// }))(ClimateRow);

ClimateRow = connect(makeMapStateToProps, mapDispatchToProps)(ClimateRow);

ClimateRow.defaultProps = {
    type: "climates"
}
// export default ClimateRow;
export default withTranslation()(ClimateRow);
