import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_simple-grid-header.scss";

class SimpleGridHeader extends Component {

    render() {
        const {width, itemRef, columnCount, headerRenderer, columnWidth, rowHeight} = this.props;
        return (
            <div ref={itemRef} className={"fetura-simple-grid-header"} style={{height: rowHeight, width}}>
                    {
                        new Array(columnCount).fill(0).map((o, index) => <div className={"header"} style={{
                            width: columnWidth,
                            height: rowHeight
                        }}>
                            {headerRenderer({
                                headerIndex: index,
                                columnCount
                            })}
                        </div>)
                    }
            </div>
        );
    }
}

SimpleGridHeader.propTypes = {
    itemRef: PropTypes.func,
    columnWidth: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    columnCount: PropTypes.number.isRequired,
    headerRenderer: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default SimpleGridHeader;