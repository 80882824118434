import React from "react";
import PropTypes from "prop-types"
// import withRoles from "../../withRoles";
// import {Roles} from "../../../constans/roles";
import {calculateVentilation, getLatestMetadata} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import moment from "moment";
import {convertLengthUnitTo, convertTemperatureUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import Ventilation from "../../../resources/images/climate/fan_symbol_icon.svg"
import Humidity from "../../../resources/images/climate/humidity_symbol_icon.svg"
import Slurry from "../../../resources/images/climate/poop_symbol_icon.svg"
import H2S from "../../../resources/images/climate/h2s_symbol_icon.svg"
import NH3 from "../../../resources/images/climate/nh3_symbol_icon.svg"
import CO2 from "../../../resources/images/climate/co2_symbol_icon.svg"
import Pressure from "../../../resources/images/climate/pressure_symbol_icon.svg"
import MinAlarm from "../../../resources/images/climate/alarm_min_icon.svg";
import MaxAlarm from "../../../resources/images/climate/alarm_max_icon.svg";
import Cooling from "../../../resources/images/climate/cooling_icon.svg";
import MinVentilation from "../../../resources/images/climate/ventilation_min_icon.svg";
import MaxVentilation from "../../../resources/images/climate/ventilation_max_icon.svg";
import {get} from "lodash";
import ClimateCharts from "./ClimateCharts";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import {toggleExpandByID, toggleSelectByID} from "../../../actions/mainTabActions";
import {onElementPainted} from "../../../utils/DOMUtils";
import {makeIsExpanded, makeIsSelected} from "../../../selectors/device-rows/rowSelector";
import DeviceRow from "../DeviceRow";
import Tooltip from "../../Tooltip";
import {withTranslation} from "react-i18next";
import Card from "../../basics/card/Card";
import {Col} from "react-bootstrap";

function makeMapStateToProps() {
    const getShadow = makeGetShadowByDevice()
    const isSelected = makeIsSelected();
    const isExpanded = makeIsExpanded();
    const params = {type: "climates"};
    return function mapStateToProps(state, props) {
        params.id = props.id
        return {
            ...getShadow(state, props.device),
            selected: isSelected(state, params),
            expanded: isExpanded(state, params)
        }
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onSelect: (e) => {
            e.stopPropagation();
            dispatch(toggleSelectByID("climates", ownProps.id))
            return false;
        },
        onExpand: () => dispatch(toggleExpandByID("climates", ownProps.id))
    }
}

export class ClimateRow extends React.Component {

    hasAlarm = (shadow) => {
        if (!shadow) return true;
        return shadow.ambientConditions.alerts.reduce((a, b) => a + b, 0) > 0;

    };
    tempFormatter = value => {
        return convertTemperatureUnitTo(value, {showUnit: true, fixed: 1})
    };

    getTemperature(shadow) {
        if (!shadow) return "-";
        let value;
        try {
            value = shadow.ambientConditions.averageCurrentAirTemperature
        } catch (e) {
            value = undefined;
        }
        return this.tempFormatter(value);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.expanded !== this.props.expanded) {
            if (!this.props.expanded) {
                onElementPainted(this.props.measure)
            }
        }
    }

    getExpectedTemperature(shadow) {
        if (!this.isActive(shadow)) return "-";
        let value;
        try {
            value = shadow.ambientConditions.requestedAirTemperature
        } catch (e) {
            value = undefined;
        }
        return this.tempFormatter(value);
    }

    isActive(shadow) {
        try {
            return shadow.configuration.isActive && !shadow.ambientConditions.controllerStatus[2];
        } catch (e) {
            return false;
        }
    }

    getValues = () => {
        const {shadow, name, t, device, fetching} = this.props;
        const temperatureUnit = getUnit("temperature", UnitTypes.SMALL);
        const lengthUnit = getUnit("length", UnitTypes.MEDIUM);
        const errors = this.getErrors();
        const temperatureFormatter = (value) => convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: true
        })
        const lengthFormatter = (value) => convertLengthUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: true
        })
        return {
            upperTitle: name,
            title: this.getTemperature(shadow),
            lowerTitle: this.isActive(shadow) ? `${t("chamber.chart.temperatureDesired")}: ${this.getExpectedTemperature(shadow)}` : t("inactive"),
            number: device.Number || "-",
            icon: {
                errors,
                isLoading: fetching,
                isConnected: !!shadow,
                dateTime: getLatestMetadata(get(shadow, "metadata")),
                isDisabled: !this.isActive(shadow),
                isAlerted: this.hasAlarm(shadow)
            },
            params: [
                {
                    value: get(shadow, "ambientConditions.generalVentilationEfficiency"),
                    title: t("chamber.chart.ventilation"),
                    icon: Ventilation,
                    unit: "%",
                    valueFormatter: calculateVentilation,
                    show: true
                },
                {
                    value: get(shadow, "ambientConditions.requestedAirTemperature") - get(shadow, "configuration.alarms.minimumTemperatureAlarmDeviation"),
                    title: t("minTempAlarm"),
                    icon: MinAlarm,
                    unit: temperatureUnit,
                    valueFormatter: temperatureFormatter,
                    show: true
                },
                {
                    value: get(shadow, "ambientConditions.requestedAirTemperature") + get(shadow, "configuration.alarms.maximumTemperatureAlarmDeviation"),
                    title: t("maxTempAlarm"),
                    icon: MaxAlarm,
                    unit: temperatureUnit,
                    valueFormatter: temperatureFormatter,
                    show: true
                },
                {
                    value: get(shadow, "configuration.ventilationConfiguration.minimumVentilation"),
                    title: t("farmView.climate.minVentilation"),
                    icon: MinVentilation,
                    unit: "%",
                    valueFormatter: calculateVentilation,
                    show: true
                },
                {
                    value: get(shadow, "configuration.ventilationConfiguration.maximumVentilation"),
                    title: t("farmView.climate.maxVentilation"),
                    icon: MaxVentilation,
                    unit: "%",
                    valueFormatter: calculateVentilation,
                    show: true
                },
                {
                    value: get(shadow, "shadow.ambientConditions.expansionCardSensorsIndications[2]"),
                    title: t("chamber.chart.humidity"),
                    icon: Humidity,
                    unit: "%"
                },
                {
                    value: get(shadow, "service.cooling"),
                    title: t("chamber.chart.cooling"),
                    icon: Cooling,
                    valueFormatter: (value) => <i className={`fas fa-fw fa-${value ? "check" : "times"}`}/>
                },
                {
                    value: get(shadow, "ambientConditions.expansionCardSensorsIndications[3]"),
                    title: t("chamber.chart.pressure"),
                    icon: Pressure,
                    unit: "bar"
                },
                {
                    value: get(shadow, "ambientConditions.expansionCardSensorsIndications[0]"),
                    title: t("chamber.chart.slurry"),
                    icon: Slurry,
                    unit: lengthUnit,
                    valueFormatter: lengthFormatter
                },
                {
                    value: get(shadow, "shadow.ambientConditions.expansionCardSensorsIndications[4]"),
                    title: t("chamber.chart.co2"),
                    icon: CO2,
                    unit: "ppm"
                },
                {
                    value: get(shadow, "shadow.ambientConditions.expansionCardSensorsIndications[6]"),
                    title: t("chamber.chart.nh3"),
                    icon: NH3,
                    unit: "ppm"
                },
                {
                    value: get(shadow, "shadow.ambientConditions.expansionCardSensorsIndications[5]"),
                    title: t("chamber.chart.h2s"),
                    icon: H2S,
                    unit: "ppm"
                }
            ]
        }
    }

    getErrors = () => {
        const {t, shadow} = this.props;
        try {
            if (shadow && shadow.ambientConditions.alerts.reduce((a, b) => a + b, 0) > 0) {
                if (shadow.ambientConditions.alerts[0] && shadow.ambientConditions.alerts[1]) return [t('farmView.climate.absoluteAlarm')];
                let errors = [];
                for (let i = 0; i < shadow.ambientConditions.alerts.length; i++) {
                    if (shadow.ambientConditions.alerts[i]) errors.push(t(`farmView.climate.errors.${i}`));
                }
                return errors;
            }
        } catch (e) {
            console.error(e);
        }
        return [];
    }


    icon = ({color, header, icon, dateTime}) => {
        const errors = this.getErrors();

        return (
            <Tooltip tooltipContent={
                <div>
                    <div>{header}</div>
                    {
                        !!dateTime &&
                        <div>
                            <i className="fas fa-sync mr-1"/><b>{moment(dateTime).format("HH:mm:ss DD.MM.YY")}</b>
                        </div>
                    }
                    <div><small>{errors.join("\n")}</small></div>
                </div>
            }
                     placement={"left"}
                     type={color}>
                <div>
                    <i className={icon}/>
                </div>
            </Tooltip>
        )
    }

    onContextMenu = (e) => {
        const {openMenu, device} = this.props;
        e.stopPropagation();
        e.preventDefault();
        e.persist();
        openMenu(e, device);
        return false;
    }

    render() {
        const {onExpand, selected, onSelect, expanded, measure, device} = this.props;
        const {upperTitle, title, lowerTitle, params, icon, number} = this.getValues();
        return (
            <Col xs={12}>
                <Card type={"container"} className={"pointer"} selected={selected}>
                    <DeviceRow
                        onClick={onSelect}
                        icon={icon}
                        upperTitle={upperTitle}
                        title={title}
                        lowerTitle={lowerTitle}
                        params={params}
                        expanded={expanded}
                        selected={selected}
                        onExpand={onExpand}
                        number={number}
                        showingCallback={measure}
                        onContextMenu={this.onContextMenu}
                    />
                    {
                        !!expanded &&
                        <ClimateCharts measure={measure} device={device}/>
                    }
                </Card>
            </Col>


        );
    }

}

ClimateRow.propTypes = {
    device: PropTypes.object.isRequired,
    id: PropTypes.string,
    placements: PropTypes.array,
    name: PropTypes.string
};


// ClimateRow = withRoles(props => ({
//     roles: [Roles._DEVICE_CLIMATE],
//     devPlcmnts: extractDevPlcmnts(props.climate)
// }))(ClimateRow);

ClimateRow = connect(makeMapStateToProps, mapDispatchToProps)(ClimateRow);

ClimateRow.defaultProps = {
    type: "climates"
}
// export default ClimateRow;
export default withTranslation()(ClimateRow);
