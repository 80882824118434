import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getSelectedDispensers} from "../../../../utils/FeedingUtils";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import Button from "../../../basics/button/Button";
import _, {isEqual} from "lodash";
import {ModalName as FeedingExtraFeedingModalName} from "../../../modals-new/feeding-extra-feeding/FeedingExtraFeedingModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as FeedingManageFeedingModalName} from "../../../modals-new/feeding-manage-feeding/FeedingManageFeedingModal";
import {ModalName as FeedingSetAlarmModalName} from "../../../modals-new/feeding-set-alarm/FeedingSetAlarmModal";
import Menu from "../../../basics/menu/Menu";
import MenuItem from "../../../basics/menu/MenuItem";
import {FeedingType} from "../../../../constans/feedingTypes";
import InfoBox from "../../../basics/info-box/InfoBox";
import {SectorType} from "../../../../constans/sectorTypes";
import {ModalName as FeedingSkipDosesModalName} from "../../../modals-new/feeding-skip-doses/FeedingSkipDosesModal";
import {getFeedingSelected} from "../../../../selectors/feedingSelector";
import {feedingUnselectAll} from "../../../../actions/feedingActions";
import {DeletePig, SetLock, TurnDispenserOff} from "../mini-components/FeedingConfirmModals";
import {scrollToID} from "../../../../utils/DOMUtils";
import {ModalName as FeedingConditionModalName} from "../../../modals-new/feeding-condition-modal/FeedingConditionModal";
import {ModalName as FeedingReportInseminationModalName} from "../../../modals-new/feeding-report-insemination/FeedingReportInseminationModal";
import {ModalName as FeedingReportParturitionModalName} from "../../../modals-new/feeding-report-parturition/FeedingReportParturitionModal";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {ModalName as FeedingEmptyForageModalName} from "../../../modals-new/feeding-empty-forage/FeedingEmptyForageModal";
import {ModalName as FeedingExtraWaterModalName} from "../../../modals-new/feeding-extra-water-modal/FeedingExtraWaterModal";
import DispenserNRF from "../../../../beans/devices/DispenserNRF";

export class DispenserButtons extends React.Component {

    constructor(props) {
        super(props);
        const {chamberId, dispensersSelected} = this.props;
        this.state = getSelectedDispensers(chamberId, dispensersSelected);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {chamberId, dispensersSelected} = this.props;
        if (!_.isEqual(chamberId, nextProps.chamberId) || !_.isEqual(dispensersSelected, nextProps.dispensersSelected)) {
            const result = getSelectedDispensers(nextProps.chamberId, nextProps.dispensersSelected);
            this.setState({...result});
        }
    }

    /**
     * Metoda do wołania modala z potwierdzeniem ustawienia blokady na dozowniku
     * @param status - flaga czy blokada jest włączona czy nie
     */
    handleLock = (status = false) => {
        this.openConfirmModal(SetLock, {status});
    };

    /**
     * Metoda do wołania modala z potwierdzeniem usunięcia przejścia RFID z dozownika
     */
    handleDeletePigRFID = () => {
        const {chamberID} = this.state;
        this.openConfirmModal(DeletePig, {chamberID});
    };

    /**
     * Metoda do wołania modala z potwierdzeniem wyłączenia (ustawienia krzywej zywienia na 0) dozownika
     */
    handleDisableDispenser = () => {
        this.openConfirmModal(TurnDispenserOff);
    };


    /**
     * Metoda ktora przekazuje funkcje do scrollowania do komponentu InfoBox jesli zostala wybrana komora
     * @return {undefined|(function(): boolean)}
     */
    handleClickOnInfoBox = () => {
        const {chamberId} = this.state;
        if (chamberId) {
            return () => scrollToID(chamberId);
        } else {
            return undefined;
        }
    };

    isRFID = () => {
        const {feedingType} = this.state;
        return isEqual(feedingType, FeedingType.GROUP);
    };


    render() {
        const {t, isActive, event, onClick} = this.props;
        const {selectedNodes, sectorType, feedingType} = this.state;
        const selected = {
            allFeeding: true,
            hasWater: false
        };
        for (let node of selectedNodes) {
            if (node.device instanceof DispenserNRF && node.device.hasWater()) {
                selected.hasWater = true;
            }
        }

        // const isNRFSelected = !!(getReceiversType(receivers) & 0b10);
        const menuItems = [
            {
                name: t("extraFeeding"),
                onClick: () => this.openModal(FeedingExtraFeedingModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("manageFeeding"),
                onClick: () => this.openModal(FeedingManageFeedingModalName),
                show: [FeedingType.INDIVIDUAL, FeedingType.GROUP].includes(feedingType)
            },
            {
                name: t("deviceRows.feeding.dispenserButtons.skipDoses"),
                onClick: () => this.openModal(FeedingSkipDosesModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("lockFeeding"),
                onClick: () => this.handleLock(true),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("unlockFeeding"),
                onClick: () => this.handleLock(false),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("deviceRows.feeding.dispenserButtons.deletePig"),
                onClick: () => this.handleDeletePigRFID(),
                show: [FeedingType.GROUP].includes(feedingType)
            },
            {
                name: t("reportCondition"),
                onClick: () => this.openModal(FeedingConditionModalName),
                show: [FeedingType.INDIVIDUAL, FeedingType.GROUP].includes(feedingType)
            },
            {
                name: t("alarms"),
                onClick: () => this.openModal(FeedingSetAlarmModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("deviceRows.feeding.dispenserButtons.reportInsemination"),
                onClick: () => this.openModal(FeedingReportInseminationModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType) && [SectorType.MATING].includes(sectorType)
            },
            {
                name: t("deviceRows.feeding.dispenserButtons.reportParturition"),
                onClick: () => this.openModal(FeedingReportParturitionModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType) && [SectorType.DELIVERY].includes(sectorType)
            },
            {
                name: t("disableDispenser"),
                onClick: () => this.handleDisableDispenser(),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("emptyDispenser"),
                onClick: () => this.openModal(FeedingEmptyForageModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType)
            },
            {
                name: t("extraWater"),
                onClick: () => this.openModal(FeedingExtraWaterModalName),
                show: [FeedingType.INDIVIDUAL].includes(feedingType) && selected.hasWater
            }
        ];

        return (
            <>
                <ButtonGroup show={!!isActive} fixed>
                    <InfoBox onClick={this.handleClickOnInfoBox} boxColor={"info"}>
                        {i18next.t(`deviceRows.feeding.dispenserButtons.${this.isRFID() ? "selectedAnimals" : "selectedStandings"}`, {count: selectedNodes.length})}
                    </InfoBox>
                    <Button buttonStyle={"round"}
                            icon={<i className={"fas fa-ellipsis-v"}/>}
                            type={"button"}
                            buttonColor={"info"}
                            disabled={!selectedNodes.length}
                            onClick={onClick}
                    />
                </ButtonGroup>
                <Menu event={event}>
                    {
                        menuItems.map((item, index) => item.show ? (
                            <MenuItem key={index} disabled={item.disabled} onClick={() => item.onClick()}>
                                {item.name}
                            </MenuItem>
                        ) : null)
                    }
                </Menu>

            </>
        );
    }

    /**
     * Uniwersalana funkcja otwierajaca modale zwiazane z zywieniem
     * @param modalName
     */
    openModal = (modalName) => {
        const {show} = this.props;
        const {feedingType, selectedNodes, receivers, chamberID, sectorType} = this.state;
        show(modalName, {
            type: feedingType,
            selectedNodes: selectedNodes,
            receivers: receivers,
            locationID: chamberID,
            sectorType: sectorType,
            clearSelection: () => this.clearSelection()
        })
    };

    /**
     * Uniwersalna metoda otwierajaca modal potwierdzjacy
     * @param fn - funkcja modala potwierdzajacego z FeedingConfirmModals.js
     * @param extraProps - dodatkowe parametry do przekazania
     */
    openConfirmModal = (fn, extraProps = {}) => {
        const {show, t} = this.props;
        const {feedingType, receivers, selectedNodes = []} = this.state;
        fn({
            show,
            t,
            feedingType,
            receivers,
            selectedNodes,
            onConfirmed: this.clearSelection,
            ...extraProps
        })
    };

    /**
     * Metoda do wyczyszczenia zaznaczen z wszystkich dozowników
     */
    clearSelection = () => {
        const {dispatch} = this.props;
        const {chamberID} = this.state;
        dispatch(feedingUnselectAll({CID: chamberID}));
    };

}

DispenserButtons.propTypes = {
    chambers: PropTypes.array.isRequired,
    event: PropTypes.object,
    onClick: PropTypes.func
};
DispenserButtons = connect((state, props) => {
        return {
            dispensersSelected: getFeedingSelected(state, {CID: state.feeding.selectedChamber}),
            chamberId: state.feeding.selectedChamber,
            user: state.user.user
        }
    }
)(DispenserButtons);
DispenserButtons = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(DispenserButtons);
export default withTranslation()(DispenserButtons);
