import React, {Component} from 'react';
import {connect} from 'react-redux';
import Chart from "../basics/chart/Chart";
import Loading from "../loading/LoadingComponent";
import NotFound from "../NotFound";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types"
import PigletScale from "../../beans/devices/PigletScale";
import {isEqual} from "lodash"
import Worker from "../../workers/charts/histogramPigletScale.worker";
import {checkIfUserIsService} from "../../utils/NewRolesUtils";
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import moment from "moment";

function mapStateToProps(state) {
    return {
        aggregatedData: state.aggregatedData.data
    };
}

class HistogramPigletScale extends Component {

    state = {
        loading: true,
        data: [],
        isService: checkIfUserIsService()
    }
    worker = new Worker();

    constructor(props) {
        super(props);

        this.worker.onmessage = event => {
            this.setState({
                data: event.data.data,
                loading: false
            })
        };
    }

    componentDidMount() {
        this.sendDataToWorker();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.aggregatedData, prevProps.aggregatedData) || !isEqual(this.props.settlement, prevProps.settlement)) {
            console.log("new data", this.props.settlement, this.props.aggregatedData);
            this.sendDataToWorker();
        }
    }

    componentWillUnmount() {
        this.worker.terminate();
    }

    sendDataToWorker() {
        const {aggregatedData, pigletScale, settlement} = this.props;
        const {isService} = this.state;
        let data = aggregatedData.get(pigletScale.DevID) || [];
        this.worker.postMessage({
            data,
            settlement,
            isService
        });
        this.setState({
            loading: true
        })
    }

    weightValueFormatter = value => {
        if (value === null) {
            return null;
        }
        return convertWeightUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            rawValue: true
        });
    };

    tickFormatter = value => {
        const {isService} = this.state;
        if (isService) {
            return moment(value).format("DD.MM.YYYY HH:mm");
        }
        return moment(value).format("DD.MM.YYYY");
    };

    render() {
        const {loading, data} = this.state;
        const {t} = this.props;
        const dataDef = [
            {
                color: "green",
                dataKey: "value",
                name: t("weight"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM)
            },
            {
                color: "green",
                dataKey: "amount",
                name: t("amount"),
                opacity: 0.4,
                chartType: "Bar",
                defaultOff: true,
                strokeOpacity: .2,
                yAxisId: "right"
            },
            {
                color: "blue",
                dataKey: "valueSavgol",
                name: t("chamber.chart.weightSavgol"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM)
            },
            {
                color: "blue",
                dataKey: "amountSavgol",
                name: t("chamber.chart.amountSavgol"),
                opacity: 0.4,
                chartType: "Bar",
                defaultOff: true,
                strokeOpacity: .2,
                yAxisId: "right"
            }
        ];
        return (
            <React.Fragment>
                <Loading isLoading={loading}/>
                {
                    data.length === 0 &&
                    <NotFound/>
                }
                {
                    data.length > 0 &&
                    <>
                        <Chart dataDef={dataDef} data={data}
                               Yaxis={{
                                   name: t("weight")
                               }}
                               Xaxis={{
                                   name: t("time"),
                                   dataKey: "name",
                                   formatter: this.tickFormatter,
                               }}
                               saveAsExcell={"pigletScaleHistogram"}
                               tooltipLabelFormatter={this.tickFormatter}
                               type="Composed"
                               secondYaxis={{
                                   name: t("amount")
                               }}/>
                    </>
                }
            </React.Fragment>
        );
    }
}

HistogramPigletScale.propTypes = {
    settlement: PropTypes.shape({
        DtaStartTime: PropTypes.number.isRequired,
        DtaEndTime: PropTypes.number,
    }).isRequired,
    pigletScale: PropTypes.instanceOf(PigletScale).isRequired,
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(HistogramPigletScale);
