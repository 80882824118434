import React from "react";
import {FeedingType, NRFWorkType} from "../../../../constans/feedingTypes";
import {ModalName as ConfirmModalName} from "../../../modals-new/confirm-modal/ConfirmModal";
import {stringBuilder} from "../../../../utils/TextUtils";
import store from "../../../../store/store";
import {setBlockOuts} from "../../../../IOT/device-functions/DispenserDriverFunctions";
import {setAnimal, setLock, setWorkType} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {getMultiForDispenser} from "../../../../utils/DevicesUtils";
import {DispenserNRFCommandTypes} from "../../../../constans/mqttMessages";
import TinyList from "../../../basics/tiny-list/TinyList";
import {getFeedStateRFIDDelta} from "../../../../actions/feedingActions";
import {createTransfer, createTransferNotificationFailure} from "../../../../api/events/Transfer";
import buildingsDB from "../../../../database/buildingsDB";
import {get} from "lodash";
import devicesDB from "../../../../database/devicesDB";
import {DevType} from "../../../../constans/devices";

function namesToHTMLList(names = []) {
    return <TinyList list={names} maxItems={10}/>
}

/**
 * DISCLAMER FUNKCJE PONIZEJ NIE ZNAJDUJA SIĘ W FEEDINGUTILS ZE WZGLEDU NA TO ZE IMPORTY SIE KLOCA I COS CRASHUJE APKE (TAK SAMO JAK BYLO Z GETLOCATIONID :()
 */
/**
 * We use values, dispatch, props approach to make regular functions to use redux-form args pattern
 * @param status
 * @param dispatch
 * @param receivers
 * @private
 */
export function _setLock({status}, dispatch, {receivers}) {
    for (let dispensers of receivers.values()) {
        if (dispensers.individualDTM.length) {
            dispensers.individualDTM.forEach((dev) => {
                setBlockOuts(dev.DevID, dev.outputs.map((out) => ({
                    number: out.number,
                    lock: !!status
                })));
            })
        }
        if (dispensers.individualNRF.devices.length) {
            setLock(dispensers.individualNRF.devices, status);
        }
    }
}

export function _deleteRFID(values, dispatch, {receivers, selectedNodes, feedingType, chamberID}) {
    if (feedingType === FeedingType.GROUP) {
        const RFIDs = selectedNodes.map(o => ({
            RFID: o.id,
            AnmID: o.animal ? o.animal.AnmID : undefined,
            removed: true,
            PlcmntID: chamberID
        }));
        for (let gatewayId of receivers.keys()) {
            setAnimal(gatewayId, RFIDs, {
                onSuccess: () => {
                    const data = new Map();
                    const transferData = [];
                    const tree = buildingsDB.getTreeByLocationID(chamberID);
                    const transferTo = get(tree, "sector.SID", get(store.getState(), "location.farm"));
                    const tmp = {};
                    RFIDs.forEach(o => {
                        tmp[o.RFID] = null
                        //dobre api takie fajne ze musze wiecej rzeczy pobierac niz samemu z fronta to robilem :(
                        if (o.AnmID) {
                            transferData.push({
                                AnmID: o.AnmID,
                                transferTo: transferTo,
                                DevIDsNewPlcmnt: [],
                                EvTime: +new Date(),
                                DevIDsOldPlcmnt: devicesDB.getDevicesInPlcmntID(chamberID, {showDevicesInChildren: false}).filter(o=> [DevType.DISPENSER_NRF, DevType.DISPENSER].includes(o.DevType)).map(o=>({
                                    DevID: o.DevID,
                                    GwID: o.GatewayID
                                }))
                            })
                        }
                    });
                    data.set(chamberID, tmp);
                    console.error(data);
                    dispatch(getFeedStateRFIDDelta(data));
                    if (transferData.length) {
                        createTransfer(transferData).catch(err => {
                                console.error(err);
                                createTransferNotificationFailure()
                            }
                        )
                    }
                }
            })
        }
    }
}

export function _turnDispenserOff(values, dispatch, {receivers}) {
    for (let [gatewayId, dispensers] of receivers.entries()) {
        const data = [];
        if (dispensers.individualDTM.length) {
            dispensers.individualDTM.forEach((dev) => {
                dev.outputs.forEach(out => {
                    data.push({
                        ...out,
                        feeding: {
                            curveNr: 0,
                            number: out.number
                        }
                    })
                })
            })
        }
        if (dispensers.individualNRF.animals.length) {
            dispensers.individualNRF.animals.forEach(animal => {
                data.push({
                    ...animal,
                    feeding: {
                        curveNr: 0
                    }
                })
            })
        }
        if (data.length) {
            setAnimal(gatewayId, data);
        }
    }
}

export function _forceWorkType({workType = NRFWorkType.SCHEDULE}, dispatch, {receivers}) {
    const getWorkType = (dev) => {
        try {
            const {
                maxOneDose,
                initDose,
                timeOrImpulse,
                optButt = 0
            } = dev.Settings[DispenserNRFCommandTypes.SET_WORK_TYPE];
            return {
                maxOneDose,
                initDose,
                timeOrImpulse,
                optButt
            }
        } catch (e) {
            return {};
        }
    };
    for (let dispensers of receivers.values()) {
        if (dispensers.individualNRF.devices.length) {
            let map = new Map();
            for (let deviceId of dispensers.individualNRF.devices) {
                const multi = getMultiForDispenser(deviceId);
                const key = multi ? multi.DevID : undefined;
                let value = map.get(key) || {devIds: [], setting: {...getWorkType(multi), workType}};
                value.devIds.push(deviceId);
                map.set(key, value);
            }
            for (let [multiId, value] of map.entries()) {
                console.log("sending -> ", multiId, value);
                setWorkType(value.devIds, value.setting);
            }
        }
    }
}

export const SetLock = ({
                            status = false, feedingType, receivers, selectedNodes, show, t, onConfirmed = () => {
    }
                        }) => {
    if ([FeedingType.INDIVIDUAL].includes(feedingType)) {
        show(ConfirmModalName, {
            title: status ? t("deviceRows.feeding.dispenserButtons.confirmLock") : t("deviceRows.feeding.dispenserButtons.confirmUnlock"),
            text: stringBuilder(status ? t("deviceRows.feeding.dispenserButtons.confirmLockText") : t("deviceRows.feeding.dispenserButtons.confirmUnlockText"), selectedNodes.map(node => node.name).join(", ")),
            confirmText: t("yes"),
            children: namesToHTMLList(selectedNodes.map(node => node.name)),
            onConfirmed: (props) => {
                _setLock({status}, null, {receivers});
                props.handleHide();
                onConfirmed();
            }
        });
    }
}


export const DeletePig = ({
                              feedingType, receivers, selectedNodes = [], chamberID, show, t, onConfirmed = () => {
    }
                          }) => {
    if (FeedingType.GROUP === feedingType) {
        show(ConfirmModalName, {
            title: t("deviceRows.feeding.dispenserButtons.confirmDelete"),
            text: t("deviceRows.feeding.dispenserButtons.confirmDeleteText"),
            confirmText: t("yes"),
            onConfirmed: (props) => {
                _deleteRFID(null, store.dispatch, {
                    receivers,
                    selectedNodes,
                    feedingType,
                    chamberID
                });
                props.handleHide();
                onConfirmed();
            }
        });
    }
}


export const TurnDispenserOff = ({
                                     feedingType, receivers, selectedNodes, show, t, onConfirmed = () => {
    }
                                 }) => {
    if (feedingType === FeedingType.INDIVIDUAL) {
        show(ConfirmModalName, {
            title: t("deviceRows.feeding.dispenserButtons.confirmDisable"),
            text: t("deviceRows.feeding.dispenserButtons.confirmDisableText"),
            children: namesToHTMLList(selectedNodes.map(node => node.name)),
            confirmText: t("yes"),
            onConfirmed: (props) => {
                _turnDispenserOff(null, null, {receivers});
                props.handleHide();
                onConfirmed();
            }
        });
    }

}
