import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import NotFound from "../NotFound";
import Loading from "../loading/LoadingComponent";
import Chart from "../basics/chart/Chart";
import Worker from "../../workers/charts/hourlyChart.worker"
import {withTranslation} from "react-i18next";

@connect((store) => {
    return {
        cageData: store.aggregatedData.data,
    };
})
export class HourlyChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props.t("chamber"), nextProps.t("chamber"))) return true;
        return !_.isEqual(this.state, nextState);
    }

    async componentDidMount() {
        let worker = new Worker();
        let cageData = this.props.cageData.get(this.props.cage.DevID);
        worker.postMessage({
            cageData,
            date: this.props.date.toDate().getTime()
        });
        worker.onmessage = event => {
            this.setState({
                data: event.data.hourly,
                loading: false,
            })
        };
        this.setState({
            worker
        })
    }

   UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        let cageData = nextProps.cageData.get(nextProps.cage.DevID);
        this.state.worker.postMessage({
            cageData,
            date: nextProps.date.toDate().getTime()
        })
    }

    componentWillUnmount() {
        if (this.state.worker) {
            this.state.worker.terminate();
        }
    }

    hourFormatter = value => {
        const {t} = this.props;
        return `${t("hour")}: ${value}`;
    };

    render() {
        const {loading} = this.state;
        const chartDef = [
            {
                color: "green",
                dataKey: "all",
                name: this.props.t("all"),
            },
            {
                color: "blue",
                dataKey: "left",
                name: this.props.t("left"),
            },
            {
                color: "pink",
                dataKey: "right",
                name: this.props.t("right"),
            },
            {
                color: "orange",
                dataKey: "middle",
                name: this.props.t("middle"),
            }
        ];
        return (
            <React.Fragment>
                <Loading isLoading={loading}/>
                {
                    this.state.data.length === 0 && <NotFound/>
                }
                {
                    this.state.data.length > 0 &&
                    <>
                        <Chart dataDef={chartDef} data={this.state.data} Yaxis={{
                            name: this.props.t("amount")
                        }} Xaxis={{
                            name: this.props.t("hour"),
                            dataKey: "name"
                        }} type={"Bar"} saveAsExcell={"hourly"} tooltipLabelFormatter={this.hourFormatter}/>
                    </>
                }
            </React.Fragment>
        )
    }
}

HourlyChart.propTypes = {
    cage: PropTypes.object,
    date: PropTypes.instanceOf(moment),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

HourlyChart.defaultProps = {
    colors: {
        all: "#359d3c",
        left: "#4a9bd4",
        right: "#f44b4b",
        middle: "#ff9419",
        inactive: "#999"
    },
    brushHeight: 36,
    height: "100%",
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

export default withTranslation()(HourlyChart);