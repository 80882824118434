import React from "react"
import {connect} from "react-redux"
import {Collapse, ProgressBar} from "react-bootstrap"
import "./_data-loader.scss"
import {
    getBuildings,
    getDevices,
    getDictionaries,
    getSettings,
    getTechnologyGroups,
    loadGroups,
    loadNotifications,
    loadReports
} from "../../utils/DataLoaderUtils";
import animalsDB from "../../database/animalsDB";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../../api/paths";
import eventsDB from "../../database/eventsDB";
import NewIOT from "../../IOT/NewIOT";
import {askForPermisstionToReceiveNotifications} from "../../utils/FirebaseUtils";
import {getAllBuildings} from "../../actions/farmsActions";
import {getDevices as getDevicesToStore} from "../../actions/devicesActions"
import settingsDB from "../../database/settingsDB";
import {getTechnologyGroups as getTechnologyGroupsToStore} from "../../actions/technologyGroupActions";
import {getAllNotifications} from "../../actions/notificationsActions";
import {getAnimalModificationTime} from "../../actions/animalsActions";
import {getAllDictionaries} from "../../actions/dictionaryActions";
import {getAllReports} from "../../actions/raportsActions";
import {getAllGroups} from "../../actions/groupActions";
import moment from "moment";
import {getFarmZoneType} from "../../utils/TimeUtils";
import {listSettlementsDynamoDB} from "../../actions/settlementActions";
import "moment-timezone";
import {fetchAllUsers} from "../../actions/userActions";

@connect(store => {
    return {
        farm: store.location.farm,
        user: store.user.user,
        subscriptions: store.mqtt.subscriptions
    }
})
export class DataLoader extends React.Component {

    state = {
        progress: 0,
        show: false,
    };
    controller = new window.AbortController();
    hideTimeout = null;

    componentDidMount() {
        this.fetchFarmData();
        if (this.props.farm) {
            this.setState({
                show: true,
                progress: 0
            }, () => {
                this.startFetching();
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.farm !== prevProps.farm) {
            clearTimeout(this.hideTimeout);
            if (this.props.farm) {
                // zmienila sie ferma wiec wlacz ladowanie
                this.fetchFarmData();
                if (!this.state.show) {
                    this.setState({
                        show: true,
                        progress: 0
                    }, () => {
                        this.startFetching()
                    })
                }
            } else {
                // uzytkownik wyszedl z fermy
                this.controller.abort();
                this.setState({
                    show: false
                })
            }
        }
    }

    runTask(task) {
        return new Promise((resolve, reject) => {
            if ('requestIdleCallback' in window) {
                window.requestIdleCallback(async () => {
                    try {
                        if (this.state.show) {
                            await task();
                        }
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            } else {
                setTimeout(async () => {
                    try {
                        if (this.state.show) {
                            await task();
                        }
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }, 0);
            }
        })
    }

    updateProgress(progress) {
        if ("requestAnimationFrame" in window) {
            window.requestAnimationFrame(() => {
                this.setState({
                    progress
                })
            })
        } else {
            this.setState({
                progress
            })
        }
    }

    async getAnimals(LastEvaluatedKey = null) {
        const {farm} = this.props;
        let queryParams = {
            FarmID: farm,
            DtaModTime: animalsDB.getModificationTime(farm).DtaModTime,
            partial: true
        };
        if (LastEvaluatedKey) {
            queryParams.DtaModTime = 0;
            queryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
            queryParams.ESKAnmID = LastEvaluatedKey.AnmID;
        }
        let animals = await invokeApig({
            ...Paths.listAnimal({farmID: farm}),
            method: "GET",
            queryParams: queryParams,
            signal: this.controller.signal
        });
        let items = animals.items;
        if (animals.LastEvaluatedKey) {
            this.updateProgress(this.state.progress + .1);
            items.push(...await this.getAnimals(animals.LastEvaluatedKey));
        }
        return items;
    }

    async loadAnimals() {
        try {
            let animals = await this.getAnimals();
            if (animals.length > 0) {
                await animalsDB.insertIntoAnimals(animals);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async getEvents(LastEvaluatedKey = null) {
        const {farm} = this.props;
        let queryParams = {FarmID: farm, DtaModTime: eventsDB.getModificationTime(farm).DtaModTime, partial: true};
        if (LastEvaluatedKey) {
            queryParams.DtaModTime = 0;
            queryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
            queryParams.ESKEvID = LastEvaluatedKey.EvID;
        }
        let events = await invokeApig({
            ...Paths.listEvent({farmID: farm}),
            method: "GET",
            queryParams: queryParams
        });
        let items = events.items;
        if (events.LastEvaluatedKey) {
            this.updateProgress(this.state.progress + .02);
            items.push(...await this.getEvents(events.LastEvaluatedKey));
        }
        return items;
    }

    async loadEvents() {
        try {
            let events = await this.getEvents();
            if (events.length > 0) {
                await eventsDB.insertIntoEvents(events);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async startFetching() {
        this.controller.abort();
        this.controller = new window.AbortController();
        const {signal} = this.controller;
        const {farm, user, subscriptions, dispatch} = this.props;
        await this.runTask(async () => {
            await getBuildings(farm, signal);
            this.updateProgress(1);
            dispatch(getAllBuildings(farm));
        });
        await this.runTask(async () => {
            await getDevices(farm, user, signal);
            this.updateProgress(2);
            dispatch(getDevicesToStore(farm));
        });
        await this.runTask(async () => {
            await getSettings(user.ClientID, farm, signal);
            this.updateProgress(3);
            dispatch({
                type: "LIST_ALL_SETTINGS",
                payload: settingsDB.getAllSettings(farm),
                meta: {user}
            });
        });
        await this.runTask(async () => {
            await getTechnologyGroups(farm, signal);
            this.updateProgress(4);
            dispatch(getTechnologyGroupsToStore(farm));
        });
        await this.runTask(async () => {
            await loadNotifications(farm, signal);
            this.updateProgress(5);
            dispatch(getAllNotifications(farm));
        });
        await this.runTask(async () => {
            await this.loadAnimals();
            this.updateProgress(6);
            dispatch(getAnimalModificationTime(farm));
        });
        await this.runTask(async () => {
            await getDictionaries(user.ClientID, signal);
            this.updateProgress(7);
            dispatch(getAllDictionaries());
        });
        await this.runTask(async () => {
            await this.loadEvents();
            this.updateProgress(8);
        });
        await this.runTask(async () => {
            await loadGroups(farm, signal);
            this.updateProgress(9);
            dispatch(getAllGroups(farm));
        });
        await this.runTask(async () => {
            await loadReports(user.ClientID, user.LocalUserID, signal);
            this.updateProgress(10);
            dispatch(getAllReports(farm));
        });
        await this.runTask(async () => {
            if (subscriptions.length < 2) {
                NewIOT.subscribeAllTopics();
            }
            await askForPermisstionToReceiveNotifications(user);
            this.updateProgress(11);
            this.hideTimeout = setTimeout(() => {
                this.setState({
                    show: false
                })
            }, 2000);
        });
    }

    fetchFarmData() {
        if (this.props.farm) {
            let farmTimezone = getFarmZoneType(this.props.farm);
            moment.tz.setDefault(farmTimezone);
            this.props.dispatch(listSettlementsDynamoDB(this.props.farm));
        } else {
            let localTimeZone = moment.tz.guess(true);
            moment.tz.setDefault(localTimeZone);
        }
        this.props.dispatch(fetchAllUsers());
    };

    render() {
        return (
            <Collapse in={this.state.show} className="data-loader">
                <ProgressBar now={this.state.progress} max={11} variant={this.state.progress === 11 ? "success" : ""}/>
            </Collapse>
        )
    }

}

export default DataLoader;
