import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import LoadingComponent from "../loading/LoadingComponent";
import _ from "lodash";
import {getMinutesOfDay} from "../../utils/DateTimeUtils";
import {convertVolumeUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {withTranslation} from "react-i18next";

@connect(state => ({
    aggregatedData: state.aggregatedData.data,
    fetchingMap: state.aggregatedData.fetchingMap,
}))


export class WaterHourlyChart extends Component {

    getTickUsage = (AggDt = [], tickNumber, tickMinutes = 30, _startIndex = 0) => {
        const result = {
            hasData: false,
            value: 0,
            _startIndex: 0
        };
        const minutesStart = tickNumber * tickMinutes;
        const minutesStop = (tickNumber + 1) * tickMinutes;
        for (let i = _startIndex; i < AggDt.length; i++) {
            const minutes = getMinutesOfDay(AggDt[i].T);
            if ((minutes >= minutesStart) && (minutes < minutesStop)) {
                result._startIndex = i;
                result.value = AggDt[i].MR;
                result.hasData = true;
            }
        }
        return result;
    };
    getData = _.memoize(({date, data = []}) => {
        const result = [];
        const start = moment().startOf("day");
        let startIndex;
        const day = data.find(d => d.AggTi === +date);
        if (day) {
            for (let i = 0; i < 48; i++) {
                const tmp = this.getTickUsage(day.AggDt, i, 30, startIndex);
                startIndex = tmp._startIndex;
                const before = _.get(result[i - 1], "meter", _.get(day.AggDt[0], "MR", 0));
                const current = tmp.hasData ? tmp.value : before;
                console.log(i, tmp, before, current)
                result[i] = {
                    time: +start.clone().add(i * 30, "minute"),
                    name: start.clone().add(i * 30, "minute").format("HH:mm"),
                    meter: current,
                    consumption: (_.isNil(current) || _.isNil(before)) ? null : current - before
                }
            }
        }
        return result;
    });

    formatter = (value) => {
        return _.isFinite(value) ? convertVolumeUnitTo(value, {
            showUnit: false,
            unit: UnitTypes.BIG,
            rawValue: true,
            fixed: 2
        }) : null
    };

    render() {
        const {fetchingMap, device, aggregatedData, date, t} = this.props;
        const data = this.getData({data: aggregatedData.get(device.DevID), date: date});
        const loading = !!fetchingMap.get(device.DevID) && !data.length;
        const def = [
            {
                color: "blue",
                dataKey: "consumption",
                name: t("charts.waterHourlyChart.consumption"),
                valueConverter: this.formatter,
                unit: getUnit("volume", UnitTypes.BIG),
                opacity: 0.2,
            }
        ];
        return (
            <React.Fragment>
                <LoadingComponent isLoading={loading}/>
                <Chart type={"Bar"} dataDef={def} data={data} Yaxis={{
                    name: t("charts.waterHourlyChart.consumption"),
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name"
                }} saveAsExcell={"water"}
                />
            </React.Fragment>
        )
    }
}

WaterHourlyChart.defaultProps = {
    colors: {
        water: "#4a9bd4"
    },
    connectNulls: true,
    date: +moment().utc(),
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};


WaterHourlyChart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    date: PropTypes.number.isRequired,
    device: PropTypes.object.isRequired,
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

export default withTranslation()(WaterHourlyChart);
