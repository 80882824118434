import React from "react";
import buildingsDB from "../../../../database/buildingsDB";
import {FeedingType, NRFWorkType} from "../../../../constans/feedingTypes";
import {Col, Row} from "react-bootstrap";
import {blinkElement, scrollVirtualizedFixedHeight} from "../../../../utils/DOMUtils";
import {getFeedingSorted} from "../../../../selectors/feedingSelector";
import {connect} from "react-redux";
import Button from "../../../basics/button/Button";
import {_forceWorkType, SetLock} from "./FeedingConfirmModals";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {setAlert} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import IconStatus from "./IconStatus";
import {isMobile} from "../../../../utils/MobileUtils";
import Card from "../../../basics/card/Card";
import {ModalName as FeedingExtraFeedingModalName} from "../../../modals-new/feeding-extra-feeding/FeedingExtraFeedingModal";
import {ModalName as FeedingSetAlarmModalName} from "../../../modals-new/feeding-set-alarm/FeedingSetAlarmModal";
import {withTranslation} from "react-i18next";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import Highlight from "../../../basics/highlight/Highlight";

const buttonProps = {
    type: "button",
    buttonColor: "info",
    buttonStyle: "text"
};

// const mobileButtonProps = {
//     buttonColor: "info",
//     className: "w-100 h-100"
// };

class DeviceListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: !props.data.connected
        }
    }

    componentDidMount() {
        if (!this.props.data.connected) {
            this.startLoading();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.loading && nextProps.data.connected) {
            this.stopLoading();
        }
    }

    startLoading = () => {
        clearTimeout(this.timeout);
        this.setState({
            loading: true
        });
        this.timeout = setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 7500)
    };

    stopLoading = () => {
        clearTimeout(this.timeout);
        this.setState({
            loading: false
        })
    };

    openConfirmModal = (fn, extraProps = {}) => {
        const {show, t} = this.props;
        const {data, data: {receiver}} = this.props;
        const map = new Map();
        map.set(receiver.gatewayId, {
            individualNRF: {devices: [receiver.deviceId], animals: []},
            individualDTM: [],
            groupNRF: []
        });
        fn({
            show,
            feedingType: FeedingType.INDIVIDUAL,
            t,
            content: t("deviceRows.feeding.dispenserButtons"),
            receivers: map,
            selectedNodes: [data],
            onConfirmed: () => {
            },
            ...extraProps
        })
    };

    openModal = (modalName) => {
        const {show} = this.props;
        const {chamberId, data, data: {receiver}} = this.props;
        const map = new Map();
        map.set(receiver.gatewayId, {
            individualNRF: {devices: [receiver.deviceId], animals: []},
            individualDTM: [],
            groupNRF: []
        });
        show(modalName, {
            type: FeedingType.INDIVIDUAL,
            selectedNodes: [data],
            receivers: map,
            size: 1,
            locationID: chamberId,
            sectorType: buildingsDB.getSectorTypeByChamberID(chamberId),
            clearSelection: () => {
            }
        })
    };

    callFunc = (fn, values = {}, extraProps = {}) => {
        const {chamberId, data, data: {receiver}} = this.props;
        const map = new Map();
        map.set(receiver.gatewayId, {
            individualNRF: {devices: [receiver.deviceId], animals: []},
            individualDTM: [],
            groupNRF: []
        });
        const result = {
            feedingType: FeedingType.INDIVIDUAL,
            selectedNodes: [data],
            receivers: map,
            size: 1,
            chamberID: chamberId,
            sectorType: buildingsDB.getSectorTypeByChamberID(chamberId)
        };
        return fn(values, null, {...result, ...extraProps});
    };

    handleScrollToRFID = (rfid) => {
        const {standings, chamberId} = this.props;
        scrollVirtualizedFixedHeight(document.getElementById(`list_${chamberId}`), standings.findIndex(o => o.id === rfid), standings.length);
        blinkElement(`rfid_${rfid}`);
    };

    handleWorkType = () => {
        this.callFunc(_forceWorkType, {workType: NRFWorkType.RFID});
    };

    reconnect = () => {
        const {startSendingDeviceState, chamberId, data: {receiver}} = this.props;
        this.startLoading();
        startSendingDeviceState(receiver.deviceId, receiver.gatewayId, {CID: chamberId}, {
            onSuccess: this.stopLoading,
            onFailure: this.stopLoading
        });
    };

    handleUnpause = () => {
        this.openConfirmModal(SetLock, {status: false})
    };

    handlePause = () => {
        this.openConfirmModal(SetLock, {status: true})
    };

    handleAlert = () => {
        this.openModal(FeedingSetAlarmModalName);
    };

    clearAlert = () => {
        const alert = (values, dispatch, props) => {
            const {receivers} = props;
            for (let devs of receivers.values()) {
                if (devs.individualNRF.devices.length > 0) {
                    setAlert(devs.individualNRF.devices, false);
                }
            }
        };
        this.callFunc(alert)
    };

    handleExtraFeeding = () => {
        this.openModal(FeedingExtraFeedingModalName)
    };

    render() {
        const {device: {Address, Name} = {}, data: {efficiency = 0, dateTime, connected, locked, workType, alert, receiver, errors = [], lastRFID = ""}, t} = this.props;
        const {loading} = this.state;
        const mobile = isMobile();
        const cName = ["device-rfid mb-0", mobile ? "mobile" : ""].filter(o => o).join(" ");
        // TODO - obsluga innych kodow bledow
        const errorsTranslated = (errors || []).map((code) => {
            switch (code) {
                case 5101:
                    return t([`notificationsCenter.errorCodes.S${code}`, `notificationsCenter.errorCodes.${code}`], {number: lastRFID ? lastRFID : "-"});
                default:
                    return t([`notificationsCenter.errorCodes.S${code}`, `notificationsCenter.errorCodes.${code}`]);
            }
        });
        const efficiencyComp = <small className={"mr-1"}>
            <Highlight
                highlightColor={efficiency ? "info" : "warning"}>{t("deviceRows.feeding.dispenserRow.efficiencyTime", {
                efficiency: convertWeightUnitTo(efficiency, {
                    showUnit: true,
                    unit: UnitTypes.SMALL,
                    fixed: 0
                })
            })}</Highlight>
        </small>
        return (
            <Col md={12} sm={12} xs={12} lg={6} xl={4} className={"mb-2"}>
                <Card className={cName}>
                    <IconStatus dateTime={dateTime} loading={loading} workType={workType}
                                feedingType={FeedingType.GROUP}
                                receiver={receiver} locked={locked} alert={alert}
                                feeding={true} connected={connected}
                                errors={errorsTranslated}/>

                    <Row>
                        <Col ms={12}>
                            <small>
                                <span className={"opacity-75 mr-1"}>{Name}</span>
                                <b
                                    className={"opacity-50 mr-1"}>0x{(Address || 0).toString(16).toUpperCase()}</b>
                            </small>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {
                                lastRFID &&
                                <div className={"pointer align-items-baseline"}
                                     title={t("deviceRows.feeding.deviceItem.clickToScrollToPig")}
                                     onClick={() => this.handleScrollToRFID(lastRFID)}>{efficiencyComp}{lastRFID}<i
                                    className="fas fa-arrow-from-top ml-1"/></div>
                            }
                            {
                                !lastRFID &&
                                <div
                                    className={"align-items-baseline "}>{((workType === "R" && !locked) || loading) ?
                                    <span
                                        className={"loading-animation"}>{loading ? t("deviceRows.feeding.dispenserRow.loading") :
                                        <>{efficiencyComp}{t("deviceRows.feeding.deviceItem.waitingForPig")}</>
                                    }</span> :
                                    <span>{!!connected ? t("deviceRows.feeding.dispenserRow.pause") : t("noConnection")}</span>} </div>
                            }
                        </Col>
                    </Row>
                    <Row className={"button-actions"}>
                        <Col md={12}>
                            <ButtonGroup className={"m-0"}>
                                <Button {...buttonProps} onClick={this.reconnect}
                                        icon={<i className={`fas fa-sync fa-fw ${loading ? "fa-spin" : ""}`}/>}
                                        title={t("deviceRows.feeding.deviceItem.retryConnection")}/>
                                {
                                    (!loading && !!connected) && <>
                                        {
                                            (workType !== NRFWorkType.RFID) &&
                                            <Button  {...buttonProps} onClick={this.handleWorkType}
                                                     icon={<i className="fas fa-wrench fa-fw"/>}
                                                     title={t("deviceRows.feeding.deviceItem.setWorkTypeAsRFID")}/>
                                        }
                                        {
                                            !!locked &&
                                            <Button {...buttonProps} onClick={this.handleUnpause}
                                                    icon={<i className="fas fa-play fa-fw"/>}
                                                    title={t("unlockFeeding")}/>
                                        }
                                        {
                                            !locked &&
                                            <Button {...buttonProps} onClick={this.handlePause}
                                                    icon={<i className="fas fa-pause fa-fw"/>}
                                                    title={t("lockFeeding")}/>
                                        }
                                        <Button {...buttonProps} onClick={this.handleAlert}
                                                icon={<i
                                                    className={!!alert ? "fas fa-bell-slash fa-fw" : "fas fa-bell fa-fw"}/>}
                                                title={t("deviceRows.feeding.deviceItem.clearAlerts")}/>
                                        <Button {...buttonProps} onClick={this.handleExtraFeeding}
                                                icon={<i className="fas fa-utensils"/>}
                                                title={t("extraFeeding")}/>

                                    </>
                                }

                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    }
}

DeviceListItem = connect((state, props) => ({
        standings: getFeedingSorted(state, {CID: props.chamberId}),
        farm: location.farm
    }),
    dispatch => bindActionCreators({show}, dispatch)
)(DeviceListItem);
export default withTranslation()(DeviceListItem);
