import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import moment from "moment";
import Loading from "../loading/LoadingComponent"
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {calculateDataForTemperatureChart, temperatureConverter} from "../../utils/ChartsUtils";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

export class TemperatureChart extends Component {

    getData = () => {
        const {aggregated: {data}, date} = this.props;
        const {temps, lastTime} = calculateDataForTemperatureChart(data, +date);
        return {
            data: [...temps.values()],
            lastTime
        }
    }

    tickFormatter = value => {
        return moment(value).format("HH:mm");
    };

    render() {
        const {data, lastTime} = this.getData();
        const {date, aggregated: {fetching}, t} = this.props;
        const unit = getUnit("temperature", UnitTypes.SMALL);
        const def = [
            {
                color: "green",
                dataKey: "temperature",
                name: t("chamber.chart.temperature"),
                unit: unit,
                opacity: 0.2,
                valueConverter: temperatureConverter
            },
            {
                color: "blue",
                dataKey: "temperatureDesired",
                name: t("chamber.chart.temperatureDesired"),
                unit: unit,
                opacity: 0.2,
                valueConverter: temperatureConverter
            },
            {
                color: "red",
                dataKey: "minTemp",
                name: t('chamber.chart.minTemp'),
                unit: unit,
                valueConverter: temperatureConverter
            },
            {
                color: "red",
                dataKey: "maxTemp",
                name: t('chamber.chart.maxTemp'),
                unit: unit,
                valueConverter: temperatureConverter
            }
        ];
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <>
                    <Chart dataDef={def} data={data} Yaxis={{
                        name: t("chamber.chart.temperature")
                    }} Xaxis={{
                        name: t("time"),
                        dataKey: "name",
                        ticks: ticks,
                        domain: [date.clone().startOf("day").toDate().getTime(), "dataMax"],
                        formatter: this.tickFormatter,
                        type: "number"
                    }} saveAsExcell={"temperature"} tooltipLabelFormatter={this.tickFormatter} showDomainInput/>
                </>
            </React.Fragment>
        )
    }
}

TemperatureChart.defaultProps = {
    colors: {
        temperature: "#359d3c",
        minTemp: "#000000",
        maxTemp: "#f44b4b",
        temperatureDesired: "#4a9bd4",
        inactive: "#999"
    },
    connectNulls: true,
    date: moment().subtract(1, "days"),
    showGrid: PropTypes.bool,
    onSetGridApi: PropTypes.func,
    height: "100%",
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

TemperatureChart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    date: PropTypes.instanceOf(moment).isRequired,
    device: PropTypes.object.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

TemperatureChart = connect(makeMapStateToProps)(TemperatureChart);

export default withTranslation()(TemperatureChart);
