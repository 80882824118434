import React from "react";
import PropTypes from "prop-types"
import {getLatestMetadata} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import moment from "moment";
import {convertTemperatureUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import MinAlarm from "../../../resources/images/climate/alarm_min_icon.svg";
import MaxAlarm from "../../../resources/images/climate/alarm_max_icon.svg";
import {get} from "lodash";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import DeviceRow from "../DeviceRow";
import Tooltip from "../../Tooltip";
import {withTranslation} from "react-i18next";
import Card from "../../basics/card/Card";
import {Col} from "react-bootstrap";

function makeMapStateToProps() {
    const getShadow = makeGetShadowByDevice()
    const params = {type: "climates"};
    return function mapStateToProps(state, props) {
        params.id = props.id
        return {
            ...getShadow(state, props.device)
        }
    }
}

class ClimateNRFRow extends React.Component {

    hasAlarm(shadow) {
        return get(shadow, "temp[0].alarmMin", false) || get(shadow, "temp[0].alarmMax", false) || get(shadow, "temp[0].temp") === undefined;
    }

    tempFormatter = value => {
        return convertTemperatureUnitTo(value, {showUnit: true, fixed: 1})
    };

    getTemperature(shadow) {
        if (!shadow) return "-";
        let value;
        try {
            value = shadow.temp[0].temp
        } catch (e) {
            value = undefined;
        }
        return this.tempFormatter(value);

    }

    onContextMenu = e => {
        const {openMenu} = this.props;
        openMenu(e);
        return false;
    }


    getValues = () => {
        const {shadow, name, t, device, fetching} = this.props;
        const temperatureUnit = getUnit("temperature", UnitTypes.SMALL);
        const errors = this.getErrors();
        const temperatureFormatter = (value) => convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            showUnit: true,
            fixed: 1,
            acceptNil: true,
            rawValue: true
        })
        return {
            upperTitle: name,
            title: this.getTemperature(shadow),
            lowerTitle: "",
            number: device.Number || "-",
            icon: {
                errors,
                isLoading: fetching,
                isConnected: !!shadow,
                dateTime: getLatestMetadata(get(shadow, "metadata")),
                isDisabled: false,
                isAlerted: this.hasAlarm(shadow)
            },
            params: [
                {
                    value: get(device, "Settings.Temperature.MinimumAlarm"),
                    title: t("minTempAlarm"),
                    icon: MinAlarm,
                    unit: temperatureUnit,
                    valueFormatter: temperatureFormatter,
                    show: true
                },
                {
                    value: get(device, "Settings.Temperature.MaximumAlarm"),
                    title: t("maxTempAlarm"),
                    icon: MaxAlarm,
                    unit: temperatureUnit,
                    valueFormatter: temperatureFormatter,
                    show: true
                }
            ]
        }
    }

    getErrors = () => {
        const {t, shadow} = this.props;
        try {
            if (shadow && shadow.temp[0].temp === undefined) {
                return [t("farmView.climate.temperatureSensorError")];
            }
            if (!!shadow && !!shadow.temp[0].alarmMin) {
                return [t(`farmView.climate.errors.0`)];
            }
            if (!!shadow && !!shadow.temp[0].alarmMax) {
                return [t(`farmView.climate.errors.1`)];
            }
        } catch (e) {
            console.error(e);
        }
        return [];
    }

    icon = ({color, header, icon, dateTime}) => {
        const errors = this.getErrors();

        return (
            <Tooltip tooltipContent={
                <div>
                    <div>{header}</div>
                    {
                        !!dateTime &&
                        <div>
                            <i className="fas fa-sync mr-1"/><b>{moment(dateTime).format("HH:mm:ss DD.MM.YY")}</b>
                        </div>
                    }
                    <div><small>{errors.join("\n")}</small></div>
                </div>
            }
                     placement={"left"}
                     type={color}>
                <div>
                    <i className={icon}/>
                </div>
            </Tooltip>
        )
    }

    render() {
        const {measure} = this.props;
        const {upperTitle, title, lowerTitle, params, icon, number} = this.getValues();
        return (
            <Col xs={12}>
                <Card type={"container"}>
                    <DeviceRow
                        icon={icon}
                        upperTitle={upperTitle}
                        title={title}
                        number={number}
                        lowerTitle={lowerTitle}
                        params={params}
                        showingCallback={measure}
                    />
                </Card>
            </Col>

        );
    }

}

ClimateNRFRow.propTypes = {
    device: PropTypes.object.isRequired,
    id: PropTypes.string,
    placements: PropTypes.array,
    name: PropTypes.string
};


// ClimateRow = withRoles(props => ({
//     roles: [Roles._DEVICE_CLIMATE],
//     devPlcmnts: extractDevPlcmnts(props.climate)
// }))(ClimateRow);

ClimateNRFRow = connect(makeMapStateToProps)(ClimateNRFRow);

ClimateNRFRow.defaultProps = {
    type: "climates"
}
// export default ClimateRow;
export default withTranslation()(ClimateNRFRow);
