import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import Loading from "../loading/LoadingComponent";
import PropTypes from "prop-types";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../selectors/aggregatedSelector";
import {calculateDataForH2sChart, tickFormatter} from "../../utils/ChartsUtils";

export class H2SChart extends Component {

    getData = () => {
        const {date, aggregated: {data}} = this.props;
        const currentDay = data.find(item => item.AggTi === +date);
        const {h2s, lastTime} = currentDay ? calculateDataForH2sChart(currentDay) : {
            h2s: [],
            lastTime: 0
        };
        return {
            data: h2s,
            lastTime
        }
    }

    render() {
        const {aggregated: {fetching}, date, t} = this.props;
        const {data, lastTime} = this.getData();
        const def = [
            {
                color: "black",
                dataKey: "h2s",
                name: t("chamber.chart.h2s"),
                unit: "ppm",
                opacity: 0.2
            }
        ];
        const ticks = [];
        for (let i = 0; i <= lastTime; i++) {
            ticks.push(date.clone().hour(i).startOf("hour").toDate().getTime());
        }
        return (
            <React.Fragment>
                <Loading bgInherit={true} isLoading={fetching}/>
                <Chart dataDef={def} data={data} Yaxis={{
                    name: t("chamber.chart.h2s")
                }} Xaxis={{
                    name: t("time"),
                    dataKey: "name",
                    ticks: ticks,
                    domain: [date.startOf("day").toDate().getTime(), "dataMax"],
                    formatter: tickFormatter,
                    type: "number"
                }} saveAsExcell={"h2s"} tooltipLabelFormatter={tickFormatter} showDomainInput/>
            </React.Fragment>
        )
    }
}

H2SChart.defaultProps = {
    colors: {
        h2s: "#ff9419",
    },
    connectNulls: true,
    date: moment().subtract(1, "days"),
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

H2SChart.propTypes = {
    colors: PropTypes.object,
    connectNulls: PropTypes.bool,
    date: PropTypes.instanceOf(moment).isRequired,
    climate: PropTypes.object.isRequired,
    showExcelButton: PropTypes.bool,
    excelPortalContainerID: PropTypes.string
};

function makeMapStateToProps() {
    const getAggregated = makeGetAggregatedData()
    return function mapStateToProps(state, props) {
        return {
            aggregated: getAggregated(state, props.device)
        }
    }
}

H2SChart = connect(
    makeMapStateToProps
)(H2SChart);

export default withTranslation()(H2SChart);
