import React from "react";
import PropTypes from "prop-types"
import withRoles from "../../withRoles";
import {Roles} from "../../../constans/roles";
import Button from "../../basics/button/Button";
import {extractDevPlcmnts} from "../../../utils/DevicesUtils";
import {connect} from "react-redux";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import moment from "moment";
import Input from "../../basics/input/Input";
import Select from "../../basics/select/Select";
import {withTranslation} from "react-i18next";
import {makeGetAggregatedData} from "../../../selectors/aggregatedSelector";
import NewIOT from "../../../IOT/NewIOT";
import {Col, Row} from "react-bootstrap";
import ScaleChart from "../../charts/ScaleChart";
import {isNil} from "lodash";
import ScaleDurationChart from "../../charts/ScaleDurationChart";
import {Milliseconds} from "../../../constans/milliseconds";

function makeMapStateToProps(ownState, ownProps) {
    const getAggregated = makeGetAggregatedData();
    const params = {DevID: ownProps.device.DevID, index: ownProps.index};
    return function mapStateToProps(state) {
        return {
            aggregated: getAggregated(state, params)
        }
    }
}

export class SiloCharts extends React.Component {


    constructor(props) {
        super(props);
        this.dates = [
            {start: moment.utc().startOf("day").subtract(30, 'days'), end: moment.utc().startOf("day")},
            {start: moment.utc().startOf("day").subtract(7, 'days'), end: moment.utc().startOf("day")},
            {start: moment.utc().startOf("day").subtract(90, 'days'), end: moment.utc().startOf("day")}
        ]
        this.state = {
            chart: "duration",
            date: moment.utc().startOf("day"),
            duration: this.getDurationOptions()[0].value
        }
    }

    componentDidMount() {
        const {device} = this.props;
        NewIOT.sendRequestForAggregatedData(device);
        this.checkIfHaveInAggregatedData();
        this.props.measure();
    }

    checkIfHaveInAggregatedData() {
        const {aggregated: {data}, dispatch, device, index} = this.props;
        const {date, duration} = this.state;
        const timestamp1 = this.isSingleDate() ? +date : +duration.start;
        const timestamp2 = this.isSingleDate() ? null : +duration.end;
        const datesToCheck = isNil(timestamp2) ? [timestamp1] : new Array((Math.abs(moment.utc(timestamp1).diff(moment.utc(timestamp2), "day")) + 1)).fill(0).map((o, i) => {
            return Math.min(timestamp2, timestamp1) + (i * Milliseconds.DAY);
        })
        console.log(timestamp2, timestamp1, datesToCheck, "DLKDDL")
        const size = datesToCheck.length;
        let hasData = true;
        for (let i = 0; i < size; i++) {
            if (!data.find(item => item.AggTi === datesToCheck[i])) {
                hasData = false;
                break;
            }
        }
        if (!hasData) {
            if (isNil(timestamp2)) {
                dispatch(getAggregatedData(device, {
                    AggDataTime: timestamp1,
                    index
                }))
            } else {
                dispatch(getAggregatedData(device, {
                    AggDataTimeFrom: timestamp1,
                    AggDataTimeTo: timestamp2,
                    index
                }))
            }

        }
    }

    onChartChange = value => {
        this.setState({
            chart: value
        })
    };


    setDate(date) {
        if (date.diff(moment.utc().startOf("day"), "days") <= 0) {
            this.setState({
                date
            });
        }
    }

    onDurationChange = value => {
        console.log(value, "change")
        this.setState({
            duration: value
        })
    }

    onDateChange = value => {
        let date = moment.utc(value);
        this.setDate(date);
    };

    onLowerDateClick = () => {
        let date = this.state.date.clone().subtract(1, "day");
        this.setDate(date);
    };

    onAddDateClick = () => {
        let date = this.state.date.clone().add(1, "day");
        this.setDate(date);
    };

    getOptions = () => {
        const {t} = this.props;
        return [
            {
                name: t("deviceRows.siloses.siloRow.periodical"),
                value: "duration"
            },
            {
                name: t("daily"),
                value: "daily"
            }
        ];
    }

    getDurationOptions = () => {
        const {t} = this.props;
        return [
            {
                name: t("deviceRows.siloses.siloRow.lastXdays", {count: 30}),
                value: this.dates[0]
            },
            {
                name: t("deviceRows.siloses.siloRow.lastWeek"),
                value: this.dates[1]
            },
            {
                name: t("deviceRows.siloses.siloRow.lastXdays", {count: 90}),
                value: this.dates[2]
            }
        ];
    }

    isRangeDate = () => ["duration"].includes(this.state.chart);
    isSingleDate = () => ["daily"].includes(this.state.chart);

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {chart, date, duration} = this.state;
        if ((chart !== prevState.chart) || (date !== prevState.date) || (duration !== prevState.duration)) {
            this.checkIfHaveInAggregatedData();
        }
    }

    render() {
        const {device, index} = this.props;
        const {chart, date, duration} = this.state;
        const options = this.getOptions();
        const durationOptions = this.getDurationOptions();
        return (
            <div className="device-charts">
                <Col xs={12} className={"mt-2"}>
                    <Row className={"device-chart-select"}>
                        <Col xs={12} xl={8} className={"chart-type mb-2"}>
                            <Select value={chart} options={options} onChange={this.onChartChange} clearButton={false}/>
                        </Col>
                        <Col xs={12} xl={4} className={"chart-date mb-2"}>
                            {
                                this.isSingleDate() &&
                                <>
                                    <Button icon={<i className="fas fa-chevron-left"/>}
                                            onClick={this.onLowerDateClick}/>
                                    <Input type="date" value={this.state.date.format("YYYY-MM-DD")}
                                           onChange={this.onDateChange}/>
                                    <Button icon={<i className="fas fa-chevron-right"/>} onClick={this.onAddDateClick}/>
                                </>
                            }
                            {
                                this.isRangeDate() &&
                                <>
                                    <Select clearButton={false} options={durationOptions} value={duration}
                                            onChange={this.onDurationChange}/>
                                </>
                            }

                        </Col>
                    </Row>
                </Col>
                <div className="device-chart">
                    {
                        chart === "daily" &&
                        <>
                            <div className="chart-main-container">
                                <ScaleChart device={device} index={index}
                                            date={date} showExcelButton/>
                            </div>
                        </>
                    }
                    {
                        chart === "duration" &&
                        <>
                            <div className="chart-main-container">
                                <ScaleDurationChart index={index} device={device}
                                                    {...duration} showExcelButton/>
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }

}

SiloCharts.propTypes = {
    device: PropTypes.object.isRequired,
    id: PropTypes.string,
    placements: PropTypes.array,
    name: PropTypes.string
};

SiloCharts = withRoles(props => ({
    roles: [Roles._DEVICE_SILO],
    devPlcmnts: extractDevPlcmnts(props.device)
}))(SiloCharts);

SiloCharts = connect(makeMapStateToProps)(SiloCharts);

export default withTranslation()(SiloCharts);
