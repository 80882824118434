import React, {Component} from 'react';
import {AutoSizer, Grid, WindowScroller} from "react-virtualized";
import DispenserSimple from "./DispenserSimple";
import {getFeedingByBoxes, isSelected} from "../../../../selectors/feedingSelector";
import {connect} from "react-redux";
import {getHeaderTabsHeight, getRemToPx} from "../../../../utils/DOMUtils";
import SimpleGridHeader from "../mini-components/SimpleGridHeader";
import {indexToChar} from "../../../../utils/TextUtils";
import "../../../basics/list/_list.scss";
import SimpleActions from "./SimpleActions";
import _ from "lodash";
import {Row} from "react-bootstrap";
import InfoBox from "../../../basics/info-box/InfoBox";
import {withTranslation} from "react-i18next";
import {isMobile} from "../../../../utils/MobileUtils";

const _width = 10;//rem
const _height = 6;

class SimpleView extends Component {

    snapScroll = _.debounce(({scrollLeft, scrollTop}) => {
        try {
            const width = this.gridComponent.current.props.columnWidth;
            const tmp = scrollLeft % width;
            if (tmp !== 0 && ((tmp < (width / 3)) || (tmp > (2 * width) / 3))) {
                this.gridComponent.current.scrollToCell({columnIndex: Math.round(scrollLeft / width), rowIndex: 0})
            }
        } catch (e) {
        }
    }, 100)

    constructor(props) {
        super(props);
        this.headerComponent = React.createRef();
        this.gridComponent = React.createRef();
        // this.checkIfSelected = _.memoize(this.checkIfSelected, (...args) => JSON.stringify(args));
    }

    cellRenderer = ({columnIndex, key, rowIndex, style, parent}) => {
        const {data, chamber, unit, isLoading, scheduleMap, curveMap, forageMap} = this.props;
        const lastColumn = (columnIndex + 1) === parent.props.columnCount;
        const lastRow = (rowIndex + 1) === parent.props.rowCount;
        const className = [(rowIndex % 2 === (columnIndex % 2)) ? "odd" : "even", lastRow ? "border-bottom-0" : "", lastColumn ? "border-right-0" : ""].filter(o => !!o).join(" ");
        return (
            <DispenserSimple
                location={chamber}
                scheduleMap={scheduleMap}
                curveMap={curveMap}
                forageMap={forageMap}
                selected={isSelected(_.get(data, `[${columnIndex}][${rowIndex}]`))}
                key={key}
                isLoading={isLoading}
                unit={unit}
                className={className}
                data={data[columnIndex][rowIndex]}
                style={style}
            />
        );
    };

    headerRenderer = ({headerIndex, style, columnCount}) => {
        const swap = !((_.get(this.props, "chamber.StandsOrder", 0) >> 1) & 0b01);
        return (
            <div key={headerIndex} style={style}>
                {indexToChar(swap ? headerIndex : columnCount + (~headerIndex))}
            </div>
        );
    };

    /**
     * metoda odpowiada za syncowanie headerow z gridem oraz snapowanie
     * @param scrollLeft
     * @param scrollTop
     */
    onScroll = ({scrollLeft, scrollTop}) => {
        const {mobile} = this.props;
        try {
            this.headerComponent.current.scrollLeft = scrollLeft;
        } catch (e) {
        }
        if (mobile) {
            this.snapScroll({scrollTop, scrollLeft});
        }
    };

    getWidth = (windowWidth) => {
        const {mobile} = this.props;
        return mobile ? windowWidth < 500 ? windowWidth / 2 : getRemToPx(_width) : getRemToPx(_width);
    };

    render() {
        const {sectorType, chamber, t, chamber: {StandsInRow = 0, IndividualFeeding, StandsOrder = 0} = {}, data, isLoading, isOpen, toggleOpen} = this.props;
        const top = getHeaderTabsHeight();
        if (!IndividualFeeding) {
            return <InfoBox
                boxColor={"info"}>{t("deviceRows.feeding.dispenserChamber.simpleModeNotAvailableForGroupFeeding")}</InfoBox>
        }
        const mobile = isMobile();
        return (
            <WindowScroller>
                {({height, scrollTop, registerChild}) =>
                    <Row className={"position-relative background-inherit"}>
                        <AutoSizer className={"w-100 background-inherit"} disableHeight
                                   defaultWidth={window.innerWidth}>
                            {({width}) => (
                                <>
                                    <div style={{top}} className={`pt-1rem ${!mobile ? 'sticky-item' : ''}`}>
                                        <div className="justify-content-center" onClick={toggleOpen}>
                                            <h5 className="chamber-menu justify-content-center"
                                                id={`chamber_${chamber.CID}`}>
                                                <span>
                                                {!!isOpen && mobile && chamber.CName}
                                                {mobile && <i className={`ml-1 mr-1 fas fa-arrow-${isOpen ? "up" : "down"}`}/>}
                                            </span>
                                            </h5>
                                        </div>
                                        <SimpleActions sectorType={sectorType} chamber={chamber}/>
                                        <SimpleGridHeader
                                            columnWidth={this.getWidth(width)}
                                            columnCount={data.length}
                                            rowHeight={getRemToPx(2)}
                                            headerRenderer={this.headerRenderer}
                                            itemRef={this.headerComponent}
                                            width={width}
                                        />
                                    </div>
                                    <div ref={registerChild}>
                                        <Grid
                                            ref={this.gridComponent}
                                            cellRenderer={this.cellRenderer}
                                            columnCount={data.length}
                                            columnWidth={this.getWidth(width)}
                                            data={data}
                                            isLoading={isLoading}
                                            scrollToAlignment={"start"}
                                            autoHeight
                                            StandsOrder={StandsOrder}
                                            onScroll={this.onScroll}
                                            height={height}
                                            scrollTop={scrollTop}
                                            className={"fetura-list striped"}
                                            rowCount={StandsInRow}
                                            rowHeight={getRemToPx(_height)}
                                            width={width}
                                        />
                                    </div>
                                </>
                            )
                            }
                        </AutoSizer>
                    </Row>

                }
            </WindowScroller>

        );
    }
}

SimpleView = connect((state, props) => ({
    data: getFeedingByBoxes(state, {chamber: props.chamber, CID: props.chamber.CID}),
}))(SimpleView);

export default withTranslation()(SimpleView);
