import React from "react";
import PropTypes from "prop-types"
import {calculateVentilation} from "../../utils/DevicesUtils";
import Chart from "../basics/chart/Chart";
import {withTranslation} from "react-i18next";

export class ClimateCurveChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.calculateData(props)
        }
    }

    calculateData(props) {
        const {stages} = props;
        let array = [];
        if (stages.length === 8) {
            // pierwsze dwa etapy trzeba odjac
            for (let i = 1; i >= 0; i--) {
                let stage = stages[i];
                // console.log(stage);
                if (i === 0) {
                    let before = array[0];
                    array.push({
                        days: before.days - stage.Days,
                        expectedTemperature: stage.ExpectedTemperature,
                        ventilationMin: calculateVentilation(stage.Ventilation[0]),
                        ventilationMax: calculateVentilation(stage.Ventilation[1])
                    })
                } else {
                    array.push({
                        days: -stage.Days,
                        expectedTemperature: stage.ExpectedTemperature,
                        ventilationMin: calculateVentilation(stage.Ventilation[0]),
                        ventilationMax: calculateVentilation(stage.Ventilation[1])
                    })
                }
            }
            array.reverse();
            for (let i = 2; i < 8; i++) {
                let stage = stages[i];
                if (i === 2) {
                    array.push({
                        days: 0,
                        expectedTemperature: stage.ExpectedTemperature,
                        ventilationMin: calculateVentilation(stage.Ventilation[0]),
                        ventilationMax: calculateVentilation(stage.Ventilation[1])
                    })
                } else {
                    let stageBefore = stages[i - 1];
                    let calculatedStageBefore = array[i - 1];
                    array.push({
                        days: stageBefore.Days + calculatedStageBefore.days,
                        expectedTemperature: stage.ExpectedTemperature,
                        ventilationMin: calculateVentilation(stage.Ventilation[0]),
                        ventilationMax: calculateVentilation(stage.Ventilation[1])
                    })
                }
            }
            let lastStage = stages[7];
            let lastData = array[array.length - 1];
            array.push({
                days: lastData.days + lastStage.Days,
                expectedTemperature: lastStage.ExpectedTemperature,
                ventilationMin: calculateVentilation(lastStage.Ventilation[0]),
                ventilationMax: calculateVentilation(lastStage.Ventilation[1])
            })
        }
        return array;
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: this.calculateData(nextProps)
        })
    }

    render() {
        const {t} = this.props;
        const chartDef = [
            {
                color: "green",
                dataKey: "expectedTemperature",
                type: "stepAfter",
                name: t("requestedTemperature"),
                unit: "°C"
            },
            {
                color: "orange",
                dataKey: "ventilationMin",
                type: "stepAfter",
                name: t("minimumVentilation"),
                unit: "%"
            },
            {
                color: "blue",
                dataKey: "ventilationMax",
                type: "stepAfter",
                name: t("maximumVentilation"),
                unit: "%"
            }
        ];
        return (
            <div className="climate-curve-chart">
                <Chart dataDef={chartDef} data={this.state.data} Xaxis={{
                    dataKey: "days",
                    name: t("day")
                }} Yaxis={{
                    name: t("value")
                }}/>
            </div>
        );
    }

}

ClimateCurveChart.propTypes = {
    stages: PropTypes.array.isRequired
};

export default withTranslation()(ClimateCurveChart);