import React, {Component} from "react";
import moment from "moment"
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash"
import NotFound from "../NotFound";
import Loading from "../loading/LoadingComponent";
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import Chart from "../basics/chart/Chart";
import LabeledSlider from "../basics/slider/labeled-slider/LabeledSlider";
import CompareInsertionsTooltip from "./CompareInsertionsTooltip";
import Worker from "../../workers/charts/gainChart.worker"
import {withTranslation} from "react-i18next";

@connect((store) => {
    return {
        cageData: store.aggregatedData.data,
    };
})
export class GainChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            sliderValue: null,
            timeout: null
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props.t("chamber"), nextProps.t("chamber"))) return true;
        return !_.isEqual(this.state, nextState);
    }

    async componentDidMount() {
        let worker = new Worker();
        let cageData = this.props.cageData.get(this.props.cage.DevID);
        worker.postMessage({
            cageData,
            settlement: this.props.settlement,
            shadowInsertion: this.props.applyShadow ? this.props.shadowInsertion : null,
            sliderValue: this.state.sliderValue
        });
        worker.onmessage = event => {
            this.setState({
                data: event.data.gain,
                sliderValue: event.data.closestIndex || this.state.sliderValue,
                loading: false
            })
        };
        this.setState({
            worker
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        let cageData = nextProps.cageData.get(nextProps.cage.DevID);
        this.state.worker.postMessage({
            cageData,
            settlement: nextProps.settlement,
            shadowInsertion: nextProps.applyShadow ? nextProps.shadowInsertion : null,
            sliderValue: this.state.sliderValue
        })
    }

    componentWillUnmount() {
        if (this.state.worker) {
            this.state.worker.terminate();
        }
    }

    formatDate = value => {
        return moment(value, "DD.MM.YYYY").toDate();
    };

    weightValueConverter = value => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, fixed: 1, rawValue: true});
    };

    onSliderChange = value => {
        clearTimeout(this.state.timeout);
        let timeout = setTimeout(() => {
            let cageData = this.props.cageData.get(this.props.cage.DevID);
            this.state.worker.postMessage({
                cageData,
                settlement: this.props.settlement,
                shadowInsertion: this.props.applyShadow ? this.props.shadowInsertion : null,
                sliderValue: value
            })
        }, 100);
        this.setState({
            sliderValue: value,
            timeout
        });
    }

    render() {
        //console.log("Props",this.props);
        const {loading, sliderValue, data} = this.state;
        const {applyShadow} = this.props;
        let chartDef = [
            {
                color: "green",
                dataKey: "all",
                name: this.props.t("all"),
                unit: getUnit("weight", UnitTypes.MEDIUM),
                valueConverter: this.weightValueConverter
            },
            {
                color: "blue",
                dataKey: "left",
                name: this.props.t("left"),
                unit: getUnit("weight", UnitTypes.MEDIUM),
                valueConverter: this.weightValueConverter,
                defaultOff: true
            },
            {
                color: "orange",
                dataKey: "middle",
                name: this.props.t("middle"),
                unit: getUnit("weight", UnitTypes.MEDIUM),
                valueConverter: this.weightValueConverter,
                defaultOff: true
            },
            {
                color: "pink",
                dataKey: "right",
                name: this.props.t("right"),
                unit: getUnit("weight", UnitTypes.MEDIUM),
                valueConverter: this.weightValueConverter,
                defaultOff: true
            }
        ];
        if (applyShadow) {
            chartDef = [
                ...chartDef,
                {
                    color: "green",
                    dataKey: "allShadow",
                    name: this.props.t("all"),
                    unit: getUnit("weight", UnitTypes.MEDIUM),
                    valueConverter: this.weightValueConverter,
                    strokeOpacity: 0.4
                },
                {
                    color: "blue",
                    dataKey: "leftShadow",
                    name: this.props.t("left"),
                    unit: getUnit("weight", UnitTypes.MEDIUM),
                    valueConverter: this.weightValueConverter,
                    strokeOpacity: 0.4,
                    defaultOff: true
                },
                {
                    color: "orange",
                    dataKey: "middleShadow",
                    name: this.props.t("middle"),
                    unit: getUnit("weight", UnitTypes.MEDIUM),
                    valueConverter: this.weightValueConverter,
                    strokeOpacity: 0.4,
                    defaultOff: true
                },
                {
                    color: "pink",
                    dataKey: "rightShadow",
                    name: this.props.t("right"),
                    unit: getUnit("weight", UnitTypes.MEDIUM),
                    valueConverter: this.weightValueConverter,
                    strokeOpacity: 0.4,
                    defaultOff: true
                }
            ]
        }
        return (
            <>
                <Loading isLoading={loading}/>
                {
                    data.length === 0 && <NotFound/>
                }
                {
                    data.length > 0 &&
                    <>
                        {
                            applyShadow &&
                            <LabeledSlider label={"Porównanie"} valueFormatter={value => data[value].name}
                                           value={sliderValue || 0} onChange={this.onSliderChange}
                                           max={data.length - 1}/>
                        }
                        <Chart dataDef={chartDef} data={data} Yaxis={{
                            name: this.props.t("weight")
                        }} Xaxis={{
                            name: this.props.t("date"),
                            dataKey: "name"
                        }} saveAsExcell={"gain"} hasShadows={applyShadow} tooltipContent={applyShadow ? <CompareInsertionsTooltip/> : null}/>
                    </>
                }
            </>
        )
    }
}

GainChart.propTypes = {
    cage: PropTypes.object,
    settlement: PropTypes.shape({
        DtaStartTime: PropTypes.number.isRequired,
        DtaEndTime: PropTypes.number,
    }),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    applyShadow: PropTypes.bool, // nalozenie cienia poprzedniego rzutu
    shadowInsertion: PropTypes.number
};

GainChart.defaultProps = {
    colors: {
        all: "#359d3c",
        left: "#4a9bd4",
        right: "#f44b4b",
        middle: "#ff9419",
        inactive: "#999"
    },
    connectNulls: false,
    brushHeight: 36,
    height: "100%",
    showExcelButton: false,
    excelPortalContainerID: "chart-controls"
};

export default withTranslation()(GainChart);
