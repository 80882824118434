import React, {Component} from 'react';
import {connect} from 'react-redux';
import Chart from "../basics/chart/Chart";
import Loading from "../loading/LoadingComponent";
import NotFound from "../NotFound";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types"
import PigletScale from "../../beans/devices/PigletScale";
import {isEqual} from "lodash"
import Worker from "../../workers/charts/composedPigletScale.worker";
import {checkIfUserIsService} from "../../utils/NewRolesUtils";
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import moment from "moment";

function mapStateToProps(state) {
    return {
        aggregatedData: state.aggregatedData.data
    };
}

class ComposedPigletScaleChart extends Component {

    state = {
        loading: true,
        data: [],
        isService: checkIfUserIsService()
    }
    worker = new Worker();

    constructor(props) {
        super(props);

        this.worker.onmessage = event => {
            this.setState({
                data: event.data.data,
                loading: false
            })
        };
    }

    componentDidMount() {
        this.sendDataToWorker();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.aggregatedData, prevProps.aggregatedData) || !isEqual(this.props.settlement, prevProps.settlement)) {
            this.sendDataToWorker();
        }
    }

    componentWillUnmount() {
        this.worker.terminate();
    }

    sendDataToWorker() {
        const {aggregatedData, pigletScale, settlement} = this.props;
        const {isService} = this.state;
        let data = aggregatedData.get(pigletScale.DevID) || [];
        this.worker.postMessage({
            data,
            settlement,
            isService
        });
        this.setState({
            loading: true
        })
    }

    weightValueFormatter = value => {
        if (value === null) return null;
        return convertWeightUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            rawValue: true
        })
    }

    tickFormatter = value => {
        return moment(value).format("DD.MM.YYYY");
    };

    render() {
        const {loading, data, isService} = this.state;
        const {t} = this.props;
        let dataDef = [
            {
                color: "green",
                dataKey: "hist",
                name: t("chamber.chart.hist"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM),
                chartType: "Area"
            },
            {
                color: "orange",
                dataKey: "halfUp",
                name: t("chamber.chart.halfUp"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM),
                chartType: "Area"
            },
            {
                color: "red",
                dataKey: "median",
                name: t("chamber.chart.median"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM),
                chartType: "Area"
            },
            {
                color: "pink",
                dataKey: "normal",
                name: t("avg"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM),
                chartType: "Area"
            },
            {
                color: "blue",
                dataKey: "peaks",
                name: t("chamber.chart.peaks"),
                opacity: 0.2,
                valueConverter: this.weightValueFormatter,
                unit: getUnit("weight", UnitTypes.MEDIUM),
                chartType: "Area"
            }
        ]
        if (isService) {
            dataDef = [
                ...dataDef,
                {
                    color: "green",
                    dataKey: "histAmount",
                    name: t("chamber.chart.histAmount"),
                    opacity: 0.4,
                    chartType: "Bar",
                    defaultOff: true,
                    strokeOpacity: .2,
                    yAxisId: "right"
                },
                {
                    color: "orange",
                    dataKey: "halfUpAmount",
                    name: t("chamber.chart.halfUpAmount"),
                    opacity: 0.4,
                    chartType: "Bar",
                    defaultOff: true,
                    strokeOpacity: .2,
                    yAxisId: "right"
                },
                {
                    color: "red",
                    dataKey: "medianAmount",
                    name: t("chamber.chart.medianAmount"),
                    opacity: 0.4,
                    chartType: "Bar",
                    defaultOff: true,
                    strokeOpacity: .2,
                    yAxisId: "right"
                },
                {
                    color: "pink",
                    dataKey: "normalAmount",
                    name: t("chamber.chart.normalAmount"),
                    opacity: 0.4,
                    chartType: "Bar",
                    defaultOff: true,
                    strokeOpacity: .2,
                    yAxisId: "right"
                },
                {
                    color: "blue",
                    dataKey: "peaksAmount",
                    name: t("chamber.chart.peaksAmount"),
                    opacity: 0.4,
                    chartType: "Bar",
                    defaultOff: true,
                    strokeOpacity: .2,
                    yAxisId: "right"
                }
            ]
        }
        return (
            <React.Fragment>
                <Loading isLoading={loading}/>
                {
                    data.length === 0 &&
                    <NotFound/>
                }
                {
                    data.length > 0 &&
                    <>
                        <Chart dataDef={dataDef} data={data}
                               Yaxis={{
                                   name: t("weight")
                               }}
                               Xaxis={{
                                   name: t("time"),
                                   dataKey: "name",
                                   formatter: this.tickFormatter,
                               }}
                               saveAsExcell={"composedPigletScale"}
                               tooltipLabelFormatter={this.tickFormatter}
                               type={isService ? "Composed" : "Area"}
                               secondYaxis={isService ? {
                                   name: t("amount")
                               } : null}
                        />
                    </>
                }
            </React.Fragment>
        );
    }
}

ComposedPigletScaleChart.propTypes = {
    settlement: PropTypes.shape({
        DtaStartTime: PropTypes.number.isRequired,
        DtaEndTime: PropTypes.number,
    }).isRequired,
    pigletScale: PropTypes.instanceOf(PigletScale).isRequired,
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(ComposedPigletScaleChart);
